import React, { useEffect, useState } from 'react'
import { useAuth, shallowEqual } from "../../AuthContext"
import { Alert, Col, Row } from "react-bootstrap"
import { getAllOfficerProfiles } from "../../Firebase/Firestore-Query"


export default function Officers() {
    const [officers, setOfficers] = useState([])

    useEffect(() => {
        async function loadOfficers() {
            const officers = await getAllOfficerProfiles()
            setOfficers(officers)
        }

        loadOfficers()
    }, [])

    return (
        <div className="mx-auto text-center" style={{maxWidth: "800px"}}>
            <h1>Officers</h1>
            <p>Meet the officers of the Grandview Future Business Leaders of America chapter!</p>
            
            <hr />

            <div>
                {officers.map((officer) => {
                    console.log(officer)
                    return (
                        <div style={{marginBottom: "20px"}} key={officer.Name}>
                            <Row>
                                <Col>
                                    <div style={{marginBottom: "20px", textAlign: "left"}}>
                                        {officer.Name ? <h2>{officer.Name}</h2> : null}
                                        {officer.About ? <p>{officer.About}</p> : null}
                                        {officer.Role ? <p><b>Role:</b> {officer.Role}</p> : null}
                                        {officer.Email ? <p><b>Email:</b> {officer.Email}</p> : null}
                                        {officer.Previous_Events[0] ? <p><b>Previous Events:</b></p> : null}
                                        <ul>
                                        {officer.Previous_Events.map((event) => {
                                            return (
                                                <li>{event}</li>
                                            )
                                        })}
                                        </ul>
                                    </div>
                                </Col>
                                <Col>
                                    {officer.Profile_Image_Link ? <img src={officer.Profile_Image_Link} className="officer-image" style={{width: "300px", height: "300px", objectFit: "cover"}} /> : null}
                                </Col>
                            </Row>
                        
                        </div>
                    )
                })}
            </div>
        </div>
    )
    
}