import React, { useState, useEffect } from 'react'
import { getAllEvents } from '../Firebase/Firestore-Query'
import { getAllUsers, updateUser, getUser } from '../Firebase/Users-CRUD'
import { useAuth } from '../AuthContext'
import { Alert } from 'react-bootstrap'
import { firestore } from '../Firebase/Firebase-Config'
import { doc, setDoc } from "firebase/firestore"


export default function RegisterEvent({ setOpenModal, checkUserFirestore }) {
    const { userProfile, mutateUserProfile, currentUser } = useAuth()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({show: false})
    const [listOfEvents, setListOfEvents] = useState([])
    const [listOfMembers, setListOfMembers] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [listOfTeamMembers, setListOfTeamMembers] = useState([])

    useEffect(() => { 
        async function loadEvents () {
            const temp = await getAllEvents()
            const tempUsers = await getAllUsers()
            console.log(userProfile)
            console.log(tempUsers)
            let newTempUsers = []
            for (let i = 0; i < tempUsers.length; i++) {
                if (userProfile.UUID !== tempUsers[i].UUID) {
                    newTempUsers.push(tempUsers[i])
                }
            }
            setListOfMembers(newTempUsers)
            setListOfEvents(temp)
        }


        loadEvents()
    }, [])


    const handleEventSelect = () => {
        const eventName = document.getElementById("event-list-one").value
        listOfEvents.map((ev) => {
            if (ev.Name === eventName){
                console.log(ev)
                setSelectedEvent(ev)
                let tempArr = []
                for(let i = 0; i < ev.Max_Team_Members-1; i++){
                    tempArr.push(null)
                }
                setListOfTeamMembers(tempArr)
            }
        })
    }

    const handleEventRegisterSubmit = async () => {
        setLoading(true)
        /**
         * This is event one to post
         */
        let objToPost = {
            Name: document.getElementById("event-list-one").value
        }

        let teamMembers = []
        
        for (let i = 0; i < listOfTeamMembers.length; i++){
            const name = document.getElementById(`team-member-select-${i+1}`).value
            if (name !== "None"){
                let selectedMem
                listOfMembers.forEach((member) => {
                    if (member.UUID === name) {
                        selectedMem = member.General_Registration.Name
                    }
                })
                teamMembers.push(selectedMem)
            }
        }
        if (teamMembers.length > 0){
            objToPost.Team_Members = teamMembers
        }
        let teamCaptain
        if (selectedEvent.Can_Have_Team){
            if (document.getElementById("team-member-original").checked){
                teamCaptain = userProfile.General_Registration.Name
            } else {
                for (let i = 0; i <listOfMembers.length-1; i++){
                    if (document.getElementById(`team-member-${i}`).checked){
                        teamCaptain = teamMembers[i]
                        break
                    }
                }
            }
            if (!teamCaptain){
                setLoading(false)
                setMessage({show:true, message: "You must select a team captain as part of this event"})
                return
            }
            objToPost.TeamCaptain = teamCaptain
        } 
        
        const objToSend = {
            Districts_Registration: {
                Event_One: objToPost
            }
        }
        console.log(objToSend)
        try {
            
            const user = await updateUser(userProfile.UUID, objToSend)
            /**
             * This means that they have team members and we have to update the team members
             */
            if(objToSend.Districts_Registration.Event_One.Team_Members && objToSend.Districts_Registration.Event_One.Team_Members.length > 0){
                for (let i = 0; i < objToSend.Districts_Registration.Event_One.Team_Members.length; i++){
                    let newTeamMembers = []
                    objToSend.Districts_Registration.Event_One.Team_Members.forEach((member) => {
                        if (member !== objToSend.Districts_Registration.Event_One.Team_Members[i]){
                            newTeamMembers.push(member);
                        }
                    })
                    newTeamMembers.push(userProfile.General_Registration.Name)
                    let teamObjToPost = {
                        Districts_Registration: {
                            Event_One: {
                                Name: objToSend.Districts_Registration.Event_One.Name,
                                Team_Members: newTeamMembers
                            }
                        }
                    }
                    if (teamCaptain){
                        teamObjToPost.Districts_Registration.Event_One.TeamCaptain = teamCaptain
                    }
                    /**
                     * Now i have to update each user to have the matching districts registration
                     */
                    let uuid;
                    listOfMembers.forEach((member) => {
                        console.log(listOfMembers)
                        if (member?.General_Registration && member.General_Registration.Name === objToSend.Districts_Registration.Event_One.Team_Members[i]){
                            uuid = member.UUID
                            if (member.Districts_Registration){
                                const evName = member.Districts_Registration.Event_One.Name
                                let firstEvent;
                                listOfEvents.forEach(event => {
                                    if (event.Name === evName){
                                        firstEvent = event
                                        return
                                    }
                                })
                                if (firstEvent.isTest){
                                    teamObjToPost = {
                                        Districts_Registration: {
                                            ...member.Districts_Registration,
                                            Event_Two: {
                                                Name: objToSend.Districts_Registration.Event_One.Name,
                                                Team_Members: newTeamMembers
                                            }
                                        }
                                    }

                                    if (teamCaptain){
                                        teamObjToPost.Districts_Registration.Event_Two.TeamCaptain = teamCaptain
                                    }
                                }
                                
                            }
                        }
                    })

                    await updateUser(uuid, teamObjToPost)

                }

            }

            const auditRef = doc(firestore, `Audit Log/EVENT REGISTRATION- ${Date.now()}`)
            const tmsp = Date();
            const responsibleUser = await getUser(currentUser.uid)
            teamMembers.push(responsibleUser.General_Registration.Name)
            const obj = {
                Type: "Event Registration",
                Data: {
                    Event: document.getElementById("event-list-one").value,
                    TeamMembers: teamMembers,
                },
                ActionBy: responsibleUser.General_Registration.Name,
                Timestamp: tmsp
            }   
            await setDoc(auditRef, obj)
            
            checkUserFirestore()
            setLoading(false)
            setMessage({show:true, message: "Event Registered"})
            setOpenModal(false)

        } catch (error) {
            console.log(error)
        }

    }
    

    return (
        <div class="modal-background">
            { loading && <Alert variant="warning">Loading</Alert> }
            { message.show && <Alert variant="warning">Event 1 Registered</Alert> }
            <div class="register-modal-container">
                <h3 class="mt-2 mb-2">Register for your first event</h3>
                <div class="event-select w-50">
                  <label class="m-2">Select Your Event</label>
                  <select class="form-select mb-3" name="event-list" id="event-list-one" onChange={handleEventSelect}>
                      <option>Select Option</option>
                      {
                          listOfEvents.map((event) => {
                              if (!event.isFull){
                                  return <option value={event.Name}>{event.Name}</option>
                              }
                          })
                      }
                  </select>
                </div>
                {
                  selectedEvent && selectedEvent.Can_Have_Team ? 
                  <>
                      <p>
                          This event is teamed and can have a maximum of {selectedEvent.Max_Team_Members} team members. <br></br>
                          **If you do not see your intended team members, they have either not completed their permission forms, submitted their pending payment(s), or there may be a delay within payment tracking.** 
                          
                      </p>
                      {
                          listOfTeamMembers.map((member, i) => {
                              return (
                                  <div class="w-50">
                                      <label class="mb-2 ">Team Member #{i+1}</label>
                                      <select class="form-select mb-3" name="team-mate-select" id={`team-member-select-${i+1}`}>
                                          
                                          <option value="None">None</option>
                                          {
                                              listOfMembers.map((member) => {
                                                let isElegible = true

                                                Object.keys(member).forEach(key => {
                                                    if (member[key]?.DistrictsTag && !member[key].Paid){
                                                        isElegible = false;
                                                    }
                                                })

                                                if (isElegible){
                                                    for (let i = 0; i < member.Forms.length; i++){
                                                        if (!member.Forms[i].Completed){
                                                            isElegible = false;
                                                            break
                                                        }
                                                    }
                                                }

                                                  if (member.General_Registration && isElegible){
                                                      if (!member.Districts_Registration){
                                                          return <option value={member.UUID}>{member.General_Registration.Name} ({member.General_Registration.Grade})</option>
                                                        } else if (!member.Districts_Registration.Event_One.Team_Members){
                                                            if (!member.Districts_Registration.Event_Two){
                                                                return <option value={member.UUID}>{member.General_Registration.Name} ({member.General_Registration.Grade})</option>
                                                            }
                                                        }
                                                  }
                                              })
                                          }
                                      </select>
                                  </div>
                              )
                              
                          })
                      }
                      <div class="team-captain mt-4">
                        {
                            selectedEvent.Can_Have_Team ? 
                            <>
                                
                                <label class="fst-bold"><strong>Select Team Captain</strong> </label><br></br>
                                <label class="fst-italic mt-2 mb-2">This position is responsible for submitting PREJUDGED EVENTS through the online portal</label>
                                <div>
                                    <input class="form-check-input" type="radio" name={`team-member`} id={`team-member-original`} />
                                    <label class="form-check-label ms-2" for={`team-member-original`}>I am the Team Captain</label>
                                </div>

                                {
                                    listOfTeamMembers.map((member, index) => {
                                       
                                        return (
                                            <div>
                                                <input class="form-check-input" type="radio" name={`team-member`} id={`team-member-${index}`} />
                                                <label class="form-check-label ms-2" for={`team-member-${index}`}>Team Member #{index+1}</label>
                                            </div>
                                        )
                                    })
                                }

                            </> : <></>
                        }
                      </div>
                  </>
                  :
                  <></>
                }
                <button class="btn btn-secondary mt-3" onClick={() => {setOpenModal(false)}}>Cancel</button>
                <button class="btn btn-primary ms-3 mt-3"onClick={handleEventRegisterSubmit}>Submit</button>
            </div>
        </div>
      )

}
