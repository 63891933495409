import React, { useState } from 'react'
import { updateUser, getUser } from '../../Firebase/Users-CRUD'
import {Alert, Button} from 'react-bootstrap'
import { firestore } from '../../Firebase/Firebase-Config'
import { useAuth } from '../../AuthContext'
import { doc, setDoc } from "firebase/firestore"

export default function PaymentViewColumn({ data, uuid, paymentName, i }) {
    
    const [message, setMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const { currentUser } = useAuth()

    const auditLog = async (type) => {

        const auditRef = doc(firestore, `Audit Log/PAYMENT UPDATE- ${Date.now()}`)
        const tmsp = Date();
        const responsibleUser = await getUser(currentUser.uid)
        const actionDoneTo = await getUser(uuid)
        const obj = {
            Type: type,
            Data: paymentName,
            ActionBy: responsibleUser.General_Registration.Name,
            ActionRecipient: actionDoneTo.General_Registration.Name,
            Timestamp: tmsp
        }   
        await setDoc(auditRef, obj)

    }


    const handleFullPayment = async () => {
        setLoading(true)
        await updateUser(uuid, {[paymentName]: {
            ...data,
            Paid: true,
            Amount: data.Amount,
            TotalPaid: data.Amount,
            Current_Payment: data.Amount
        }})


        await auditLog("Full Payment")

        setLoading(false)
        setMessage(true)        
    }
    
    const handlePartialPayment = async () => {
        setLoading(true)
        //console.log(document.getElementById(`partial-payment-${i}`).value)
        await updateUser(uuid, {[paymentName]: {
            ...data,
            Paid: false,
            Amount: data.Amount,
            Current_Payment: document.getElementById(`partial-payment-${i}`).value
        }})

        await auditLog("Partial Payment")

        setLoading(false)
        setMessage(true)        
    }

    const handlePaymentReset = async () => {
        setLoading(true)

        await updateUser(uuid, {[paymentName]: {
            ...data,
            Paid: false,
            Amount: data.Amount,
            Current_Payment: 0
        }})

        await auditLog("Reset Payment")

        setLoading(false)
        setMessage(true) 
    }
    
  return (
    <div class="">

        <ul>
            {
                Object.keys(data).map(key => {
                    if (key !== "Paid"){
                        return (
                            <li>
                                {key}: {data[key]}
                            </li>
                        )
                    } else {
                        if (data[key]){
                            return (
                                <>
                                
                                <li class="bg-success text-white fw-bold text-center">
                                    PAID IN FULL
                                </li>
                                <button class="btn btn-danger mt-2" onClick={handlePaymentReset}>Reset Payment</button>
                                { loading && <Alert variant="warning">Loading</Alert> }
                                { message && <Alert variant="success">Updated</Alert> }
                                </>
                            )
                        } else {
                            return (
                                <div class="w-50">
                                    <button class="btn btn-success mt-2 mb-1" onClick={handleFullPayment}>Fully Paid</button><br></br>
                                    <div class="partial">
                                        <input type="text" class="form-control" id={`partial-payment-${i}`} />
                                        <button class="btn btn-primary mt-2" onClick={handlePartialPayment}>Partially Paid</button>
                                        <button class="btn btn-danger mt-2" onClick={handlePaymentReset}>Reset Payment</button>
                                    </div>

                                    { loading && <Alert variant="warning">Loading</Alert> }
                                    { message && <Alert variant="success">Updated</Alert> }
                                </div>
                            )
                        }
                    }
                })
            }
        </ul>
    </div>
  )
}
