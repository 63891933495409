import React, { useState, useEffect } from 'react'
import { setEventToMax, deleteEvent } from '../Firebase/Firestore-Query'

export default function EventDisplay({ eventData }) {
    const [isFull, setIsFull] = useState(false)

    useEffect(() =>{
        setIsFull(eventData.isFull)
    }, [])

    const handleMaxEvent = async () => {

        const event = await setEventToMax(eventData, true)

        setIsFull(event.isFull)
    }
    const handleReopenEvent = async () => {

        const event = await setEventToMax(eventData, false)

        setIsFull(event.isFull)
    }
    const handleDeleteEvent = async () => {
            
            await deleteEvent(eventData.Name)
    
            setIsFull(true)
        }

    /**
     * If the event is a test --> display test data block
     */
    if (eventData.isTest){
        return (
            <div class="col-md-3 col-10 border border-secondary border-5 rounded p-3 m-3 text-center">
                <h4 class="text-primary fw-bold mb-3">{eventData.Name}</h4>
                <h6>{eventData.Event_Type}</h6>
                {
                    !isFull ? <button class="btn btn-danger mt-2" onClick={handleMaxEvent}>Disable Further Registration</button> : 
                    <>
                        <label class="fst-italic">Event Registration Closed</label>
                        <button class="btn btn-primary mt-2" onClick={handleReopenEvent}>Reopen Event</button>
                    </>
                }
                <button class="btn btn-danger mt-2" onClick={handleDeleteEvent}>Delete Event</button>
            </div>
        )
    } else {
        return (
            <div class="col-md-3 col-10 border border-secondary border-5 rounded px-3 py-4 m-3 text-center">
                <h4 class="text-danger fw-bold mb-3">{eventData.Name}</h4>
                <h6>{eventData.Event_Type}</h6>
                {
                    eventData.Can_Have_Team && <><h6>Maximum of {eventData.Max_Team_Members} Team Members</h6></>
                }
                {
                    eventData.isPrejudged && <h6>PREJUDGED EVENT</h6>
                }
                {
                    !isFull ? <button class="btn btn-danger mt-2" onClick={handleMaxEvent}>Disable Further Registration</button> : 
                    <>
                        <label class="fst-italic">Event Registration Closed</label>
                        <button class="btn btn-primary mt-2" onClick={handleReopenEvent}>Reopen Event</button>
                    </>
                }
                <button class="btn btn-danger mt-2" onClick={handleDeleteEvent}>Delete Event</button>
            </div>
        )
    }
}
