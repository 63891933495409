import React, { useState, useEffect }  from 'react'
import { useNavigate } from "react-router-dom"
import { uploadTask } from '../../Firebase/Firestore-Query'
import { storage } from "../../Firebase/Firebase-Config"
import { ref, uploadBytes } from "firebase/storage"
import { Alert } from 'react-bootstrap'
import { assignPayment, deletePayment } from '../../Firebase/Firestore-Query'
import { getAllUsers } from '../../Firebase/Users-CRUD'


export default function ManagePayments() {
  const [message, setMessage] = useState({show: false})
  const [listOfPayments, setListOfPayments] = useState([])
  const [balance, setBalance] = useState(0)
  const [date, setDate] = useState(null)

  const handleAssignToAllMembers = async () => {
    
    let hasTags = false

    const obj = {
      Payment_Name: document.getElementById("name").value,
      toPut:{
        Amount: `$${document.getElementById("amount").value}`,
        Paid: false, 
        Current_Payment: 0
      }
    }

    if (document.getElementById("dlc-reg-tag").checked){
      hasTags = true
      obj.toPut.DistrictsTag = true
    } else if (document.getElementById("slc-reg-tag").checked){
      hasTags = true
      obj.toPut.StateTag = true
    } else if (document.getElementById("nlc-reg-tag").checked){
      hasTags = true
      obj.toPut.NationalsTag = true
    } 

    await assignPayment(obj)
    setListOfPayments([...listOfPayments, document.getElementById("name").value])
    setMessage({show: true, message: "Assigned to all students"})
  }
  
  useEffect(() => {
    async function loadList() {

      const members = await getAllUsers()
      let total = 0
      members.forEach((member) => {
        let list = []
        Object.keys(member).forEach((key) => {
          if (typeof member[key] === 'object' && member[key].Amount){
            
            list.push(`${key} - ${member[key].Amount}`)
            if (member[key].Paid){
              let stringAmt = member[key].Amount
              stringAmt = stringAmt.substring(1)
              console.log(stringAmt)
              const numVal = parseFloat(stringAmt)
              total+=numVal
            }
          }

        })
        console.log(list)
        setListOfPayments(list)
        return
      })
      const tempDate = new Date()
      setDate(tempDate.toLocaleDateString())

      setBalance(total)

    }
    loadList()
  }, [])

  const handlePaymentDelete = async () => {
    let name = document.getElementById("payment-list").value
    name = name.substring(0, name.indexOf("-")-1)
    await deletePayment(name)
    setMessage({show: true, message: "Deleted Payment"})
  }




  return (
    <div class="create-task-container">
      <h2 class = "fw-bold text-center mb-5">MANAGE PAYMENTS</h2>

      <div class="chapter-balance bg-light rounded mx-3 my-5 p-3">
        <h3 class= "text-center mb-5">CHAPTER BALANCE</h3>
        <p class="fst-italic">Chapter balance is calculated based on how many members have submitted thier payments</p>
        <h4>${balance} as of {date}</h4>
      </div>

      <div class="current-payments bg-light rounded mx-3 my-5 p-3 ">
        <h3 class= "text-center mb-5">CURRENT PAYMENTS</h3>
        {
          listOfPayments.map((payment) => {
            return (
            <ul>
             <li> { payment } </li>
            </ul>
            )
          })
        }
      </div>
      <div class="create-payment bg-light rounded mx-3 my-5 p-3 container">

        <h3 class="mb-5 row justify-content-center">CREATE NEW PAYMENT</h3>
        {
          message.show ? <Alert variant="success">{message.message}</Alert> : <></>
        }
        <div class="row m-3 p-3">
          <div class="row mb-5 justify-content-center"> 
            <label class="form-label fw-bold text-center">Payment Name: </label>
            <input class="form-control w-50 text-center col-12" placeholder="Payment Name" type="text" id="name" required /><br></br>
          </div>
          
          <div class="row mb-5 justify-content-center"> 
            <label class="form-label fw-bold text-center">Payment Amount: </label>
            <input class="form-control w-50 text-center col-12" placeholder="Payment Amount" type="text" id="amount" required /><br></br>
          </div>
          <div class="payment-tags form-check">
            <div class="text-center fs-4 fw-bolder">Payment Flags</div>
            <input class="form-check-input ms-3 " type="checkbox" id="dlc-reg-tag" name="dlc-reg-tag"/>
            <label class="form-check-label ms-2" for="dlc-reg-tag">DLC Event Registration</label><br></br>

            <input class="form-check-input ms-3" type="checkbox" id="slc-reg-tag" name="slc-reg-tag"/>
            <label class="form-check-label ms-2" for="slc-reg-tag">SLC Event Registration</label><br></br>

            <input class="form-check-input ms-3" type="checkbox" id="nlc-reg-tag" name="nlc-reg-tag"/>
            <label class="form-check-label ms-2" for="nlc-reg-tag">NLC Event Registration</label><br></br>
          </div>
          <div class="row justify-content-center mt-4">
            <button class="btn btn-primary my-3 p-2 col-6" onClick={ handleAssignToAllMembers } >Assign to All Members</button>
          </div>
        </div>
      </div>

      
      <div class="payment-delete bg-light rounded mx-3 my-5 p-3 container">
        <h3 class="row text-center mb-5 justify-content-center">DELETE PAYMENT</h3>
        <div class="row select mb-5 justify-content-center"> 
          <label class="mb-2 col-12 fw-bold text-center">Select Payment to Delete:</label>
          <select class="form-select w-50 mb-5 text-center" id="payment-list">
            {
              listOfPayments.map((payment) => {
                return (
                  <option value={`${payment}`}>{payment}</option>
                )
              })
            }
          </select>
          <div class="row justify-content-center">
            <button class="btn btn-danger col-6" onClick={handlePaymentDelete}>Delete from All Members</button>
          </div>
        </div>
      </div>
    </div>
  )
}
