import React from 'react'

export default function PermissionFormView({ data }) {
  return (
    <ul>
        {
            data.map((form, i) => {
                if (form.Completed){
                    return <li>{ form.Name }: UPLOADED</li>
                } else {
                    return <li>{ form.Name }: NOT COMPLETE</li>
                }
            })
        }
    </ul>
  )
}
