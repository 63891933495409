import React from 'react'

export default function TeamedEventReport({ data }) {
  return (
    <div>
      {
        data.map((event) => {
            if (event.TeamsCompeting.length > 0) {
                return (
                    <>
                        <h3>{event.EventName}</h3>
                        {
                            event.TeamsCompeting.map((team, i) => {
                                return <>
                                  <h4>Team #{i+1}</h4>
                                  <label>Team Captain: {team.Team_Captain}</label><br></br>
                                  <ul>
                                    {
                                      team.Team_Members.map(member => {
                                        return <><li>{member}</li></>
                                      })
                                    }
                                  </ul>
                                </>
                            })
                        }
                    </>
                )
            }
        })
      }
    </div>
  )
}
