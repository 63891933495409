import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { updateUser, getAllUsers } from '../../Firebase/Users-CRUD'
import { getAllEvents } from '../../Firebase/Firestore-Query'

export default function State_Rooming({ user }) {
    
    const [loading, setLoading] = useState({message:"", show:false})
    const [members, setMemebers] = useState([])
    const navigate = useNavigate()
    
    useEffect(() => {
        const loadStatemembers = async () => {
            const memberList = await getAllUsers()

            let temp = []

            memberList.map(member => {
                let completed = true;
                if (member && member.Forms) {
                    for (let form in member.Forms) {
                        if (!member.Forms[form].Completed){ 
                            completed = false
                        }
                    }
                }
                //if (member.State_Registration && member.State_Registration.CommitmentForm && member.State_Registration.CommitmentForm.Status === "Complete"){
                if (member.State_Registration && completed){
                    temp.push(member)
                }
            })

            setMemebers(temp)
        }
        loadStatemembers()
    }, [])
    
    const handleSubmit = async () => {
        let roommates = []
        for (let i = 0; i < 3; i++){
            if (document.getElementById(`roommate-${i+1}`).value === "N/A"){
                setLoading({show:true, message:"Must select ALL roommate options", type: "danger"})
                return
            } else {
                roommates.push(document.getElementById(`roommate-${i+1}`).value)
            }
        }

        if (document.getElementById(`gender`).value === null){
            setLoading({show:true, message:"Please enter your gender", type: "danger"})
            return
        }


        
        try {
            await updateUser(user.UUID, {State_Registration: {...user.State_Registration, Rooming: {Requests: roommates, TimeStamp: Date(), Gender: document.getElementById(`gender`).value}}})
            setLoading({show:true, message:"Submitted", type: "success"})
            navigate('/')
        } catch (error) {
            setLoading({show:true, message:error, type: "danger"})   
        }
    }






  return (
    <div class="general-slc-reg bg-light rounded p-3 mb-4 mt-4">
        <h4 class="gen-reg-slc-title text-center">Rooming Requests</h4>
        { loading.show && <Alert variant={`${loading.type}`}>{loading.message}</Alert> }
        <p class="fst-italic">Note: These are REQUESTS. All requests are subject to the advisor(s)' approval</p>
        <label>What do you identify as?</label>
        <input class="form-control w-50 mt-1" type="text" name="gender" id="gender" placeholder="Ex. Male/Female/Transgender/Non-Binary/Prefer Not to Say"/>
        <div class="select-roommates mt-3">
            <div class="roommate-1">
                <select class="form-select w-50 mt-3" name="roomate-1" id="roommate-1">
                    <option value={"N/A"}>Select Roommate 1</option>
                    <option value="None">None</option>
                    {
                        members.map((option) => {
                            return <option value={option.General_Registration.Name}>{option.General_Registration.Name}</option>
                        })
                    }
                </select>
            </div>
            <div class="roommate-2">
                <select class="form-select w-50 mt-3" name="roomate-1" id="roommate-2">
                    <option value={"N/A"}>Select Roommate 2</option>
                    <option value="None">None</option>
                    {
                        members.map((option) => {
                            return <option value={option.General_Registration.Name}>{option.General_Registration.Name}</option>
                        })
                    }
                </select>
            </div>
            <div class="roommate-3">
                <select class="form-select w-50 mt-3" name="roomate-1" id="roommate-3">
                    <option value={"N/A"}>Select Roommate 3</option>
                    <option value="None">None</option>
                    {
                        members.map((option) => {
                            return <option value={option.General_Registration.Name}>{option.General_Registration.Name}</option>
                        })
                    }
                </select>
            </div>
        </div>
        <div class="btn-submit">
            <button type="button" class="btn btn-primary mt-4" onClick={handleSubmit}>Submit</button>
        </div>

    </div>
  )
}
