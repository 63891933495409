import {collection, doc, getDoc, setDoc, updateDoc, addDoc, getDocs, deleteDoc, query, where, FieldPath, onSnapshot, deleteField } from "firebase/firestore"
import { getStorage, ref, getDownloadURL, listAll, getMetadata, deleteObject } from "firebase/storage"
import { firestore, storage } from "./Firebase-Config"
import { getAllUsers, updateUser, getUser, cleanup } from "./Users-CRUD"

import { currentUser } from "../App"

import JSZip from "jszip"
import { saveAs } from 'file-saver'

export const uploadTask = async (task) => {
    const newDocReference = doc(firestore, `Tasks/${task.Title}`)

    await setDoc(newDocReference, {status: "unreleased", ...task})
}

export const getAllTasks = async () => {
    let ret = []
    const collectionRef = collection(firestore, "Tasks")
    const snapshot = await getDocs(collectionRef)
    snapshot.forEach((doc) => {
        ret.push(doc.data())
    })
    return ret
}

export const changeTaskStatus = async (task) => {
    const docRef = doc(firestore, `Tasks/${task.Title}`)
    await updateDoc(docRef, {
        status:"Assigned" 
    })
}

export const getTask = async (name) => {
    const docRef = doc(firestore, `Tasks/${name}`)
    const snapshot = await getDoc(docRef)

    if(snapshot.exists()) {
        return snapshot.data()
    }
}

export const getAccessCode = async (name) => {
    const docRef = doc(firestore, "Miscellaneous/Access Key")
    const snapshot = await getDoc(docRef)

    if(snapshot.exists()){
        return snapshot.data()
    }
}
export const getStateRegistration = async () => {
    const docRef = doc(firestore, "Miscellaneous/State Registration")
    const snapshot = await getDoc(docRef)

    if(snapshot.exists()){
        return snapshot.data()
    }
}


export const updateAccessCode = async (updatedCode, user, previous) => {
    const docRef = doc(firestore, "Miscellaneous/Access Key")
    
    await updateDoc(docRef, {
        Access_Code: updatedCode
    })


}
export const updateSLCRegStatus = async (updatedCode) => {
    const docRef = doc(firestore, "Miscellaneous/State Registration")
    
    await updateDoc(docRef, {
        Enabled: updatedCode
    })
}


export const getAllEvents = async () => {
    let ret=[]
    const collectionRef = collection(firestore, "Events")
    const snapshot = await getDocs(collectionRef)
    snapshot.forEach((snap) => {
        ret.push(snap.data())
    })
    return ret
}

export const uploadNewEvent = async (objectToPost) => {
    const newDocRef = doc(firestore, `Events/${objectToPost.Name}`)

    await setDoc(newDocRef, objectToPost)

}

export const getOneEvent  = async (name) => {
    const eventDocRef = doc(firestore, `Events/${name}`)
    const snapshot = await getDoc(eventDocRef)
    if(snapshot.exists()) {
        const eventData = snapshot.data()
        return eventData
    }
}

export const postPermissionForms = async (form) => {
    form.Completed = false
    const user = await getUser("Standard")

    await updateUser("Standard", {Forms: [...user.Forms, form ]})

    const userList = await getAllUsers()

    userList.forEach(async (user) => {
        let newFormList = [...user.Forms, form]
        
        if (user.UUID !=="Standard"){
            await updateUser(user.UUID, {Forms: newFormList})
        }
        
    })

}

export const assignPayment = async (data) => {
    const userList = await getAllUsers()
    console.log(data)
    userList.forEach(async (user) => {
        let obj = {
            ...user,
            [data.Payment_Name]: data
        }
        await updateUser(user.UUID, { [data.Payment_Name]: data.toPut })
    })

    await updateUser("Standard", {[data.Payment_Name]: data.toPut})

}

export const deletePayment = async (name) => {
    const userList = await getAllUsers()

    userList.map(async (user) => {
        console.log(name)
        let object = user;
        delete object[name]
        console.log(object)

        const docRef = doc(firestore, `users/${user.UUID}`)

        await setDoc(docRef, {...object})
    })
  
}

export const deleteForm = async (formName) => {
    
    const user = await getUser("Standard")
    console.log(user)
    
    let index
    user.Forms.map((form, i) => {
        if (form.Name === formName){
            index = i
            return
        }
    })

    let temp = user.Forms
    temp.splice(index, 1)

    await updateUser("Standard", {Forms: [...temp]})


    
}

export const getAllForms = async () => {

    const user = await getUser("Standard")
    return user.Forms

    
}

export const deleteAllForms = async () => {
    const userList = await getAllUsers()

    for (let i = 0; i < userList.length; i++) {
        const newObj = {
            Forms: []
        }
        await updateUser(userList[i].UUID, newObj)
    }
}

export const resetDLCRegistration = async (memberData) => {
    if(memberData.Districts_Registration.Event_One.Team_Members){
        await resetTeamedEvent(memberData, memberData.Districts_Registration.Event_One.Team_Members)
        return
    } else if (memberData.Districts_Registration && memberData.Districts_Registration.Event_Two && memberData.Districts_Registration.Event_Two.Team_Members){
        await resetTeamedEvent(memberData, memberData.Districts_Registration.Event_Two.Team_Members)
        return
    }

    console.log("both single events")
    const docRef = doc(firestore, `users/${memberData.UUID}`)
    await updateDoc(docRef, {
        Districts_Registration: deleteField()
    })

    return    
}

const resetTeamedEvent = async (memberData, teamMembers) => {
    const memberDocRef = doc(firestore, `users/${memberData.UUID}`)
    const allMembers = await getAllUsers()

    await updateDoc(memberDocRef, {
        Districts_Registration: deleteField()
    })

    teamMembers.map(teamMember => {
        allMembers.map(async member => {
            if (member.General_Registration && member.General_Registration.Name === teamMember){
                const tempDocRef = doc(firestore, `users/${member.UUID}`)
                await updateDoc(tempDocRef, {
                    Districts_Registration: deleteField()
                })
            }
        })
    })
}

export const getAllLogs = async () => {
    let ret=[]
    const collectionRef = collection(firestore, "Audit Log")
    const snapshot = await getDocs(collectionRef)
    snapshot.forEach((snap) => {
        ret.push(snap.data())
    })
    return ret
}

export const setEventToMax = async (event, howToChange) => {


    let docRef

    const nameSplit = event.Name.split("")

    if (nameSplit[nameSplit.length - 1] === " "){
        docRef =  doc(firestore, `Events/${event.Name.substring(0, event.Name.length)}`)
    } else {
        docRef =  doc(firestore, `Events/${event.Name}`)
    }

    await updateDoc(docRef, { isFull: howToChange })

    const eventSnapshot = await getDoc(docRef)

    const eventNew = eventSnapshot.data()

    return eventNew
}


export const generateDLCReport = async () => {

    const userList = await getAllUsers()
    const dbEvents = await getAllEvents()


    /**
     * Validate users
     */
    let users = []
    let incompleteUsers = []
    userList.forEach(user => {
        if (user.Districts_Registration && user.Districts_Registration.Event_One){
            users.push(user)
        } else {
            incompleteUsers.push(user)
        }
    })




    /**
     * Organize events based on teamed/individual
     */
    let individualEvents = []
    let teamedEvents = []
    dbEvents.map(event => {
        if (event.Can_Have_Team){
            const objToAdd = {
                EventName: event.Name,
                TeamsCompeting: []
            }
            teamedEvents.push(objToAdd)
        } else {
            const objToAdd = {
                EventName: event.Name,
                Competitors: []
            }
            individualEvents.push(objToAdd)
        }
    })

    /**
     * Compile individual events
     */
    users.map(user => {
        /**
         * First event #1
         */
        /**
         * Check if event 1 is individual
         */
        individualEvents.map((event, i) => {
            if (event.EventName === user.Districts_Registration.Event_One.Name){
                individualEvents[i].Competitors = [...individualEvents[i].Competitors, user.General_Registration.Name]
                return
            }
        })
        
        /**
         * Second Event #2
         */
        /**
         * Check if Event 2 is individual
         */
        if (user.Districts_Registration.Event_Two && user.Districts_Registration.Event_Two !== "None"){
            individualEvents.map((event, i) => {
                if (event.EventName === user.Districts_Registration.Event_Two.Name){
                    individualEvents[i].Competitors = [...individualEvents[i].Competitors, user.General_Registration.Name]
                    return
                }
            })
        }
    })




    /**
     * Compile Teamed Events
     */
    users.map((user, i) => {
        /**
         * First Event #1
         */
        /**
         * Check if event #1 is Teamed
         */
        if (user.Districts_Registration.Event_One.TeamCaptain){

            teamedEvents.map((event, i) => {
                if (event.EventName === user.Districts_Registration.Event_One.Name){
                    
                    let alreadyIn = false
                    teamedEvents[i].TeamsCompeting.map((team, i) => {
                        
                        if (team.Team_Members.includes(user.General_Registration.Name)){
                            alreadyIn = true
                            return
                        }
                    })
                    if (!alreadyIn){
                        let objToAdd = {
                            Team_Captain: user.Districts_Registration.Event_One.TeamCaptain
                        }
                        if (user.Districts_Registration.Event_One.Team_Members){
                            objToAdd.Team_Members = [...user.Districts_Registration.Event_One.Team_Members, user.General_Registration.Name]
                        } else {
                            objToAdd.Team_Members = [user.General_Registration.Name]
                        }
    
                        teamedEvents[i].TeamsCompeting = [...teamedEvents[i].TeamsCompeting, objToAdd]
                    }
                }
            })
        }

        /**
         * Second #2 Event
         */
        /**
         * Check if event #2 is teamed
         */
         if (user.Districts_Registration.Event_Two && user.Districts_Registration.Event_Two.TeamCaptain){

            teamedEvents.map((event, i) => {
                if (event.EventName === user.Districts_Registration.Event_Two.Name){
                    
                    let alreadyIn = false
                    teamedEvents[i].TeamsCompeting.map((team, i) => {
                        
                        if (team.Team_Members.includes(user.General_Registration.Name)){
                            alreadyIn = true
                            return
                        }
                    })
                    if (!alreadyIn){
                        let objToAdd = {
                            Team_Captain: user.Districts_Registration.Event_Two.TeamCaptain
                        }
                        if (user.Districts_Registration.Event_Two.Team_Members){
                            objToAdd.Team_Members = [...user.Districts_Registration.Event_Two.Team_Members, user.General_Registration.Name]
                        } else {
                            objToAdd.Team_Members = [user.General_Registration.Name]
                        }
    
                        teamedEvents[i].TeamsCompeting = [...teamedEvents[i].TeamsCompeting, objToAdd]
                    }
                }
            })
        }
    })

    const ret = {
        IndividualEvents: individualEvents,
        TeamedEvents: teamedEvents,
        IncompleteUsers: incompleteUsers
    }
    return ret;
}


export const downloadPermissionForm = async (folderRefName) => {
    const jszip = new JSZip();

    const folderRef = ref(storage, folderRefName)

    const folder = await listAll(folderRef);
    const promises = folder.items.map(async (item) => {
        //getting fileData
        const file = await getMetadata(item);
        //fileref to get the name of the file and the download url
        const fileRef = ref(storage, item.fullPath);

        const fileBlob = await getDownloadURL(fileRef).then( async (url) => {
            console.log(url)
            return fetch(url).then((response) => response.blob());
        });

        console.log(fileBlob);
        const ending = fileBlob.type.substring(fileBlob.type.indexOf("/") + 1)
        console.log(ending)

        jszip.file(`${file.name}.${ending}`, fileBlob);

    }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());

    await promises;

    const blob = await jszip.generateAsync({ type: 'blob', mimeType:"application.pdf" });

    saveAs(blob, `Grandview FBLA - ${folderRefName}.zip`);
    
}

export const downloadRatingSheets = async (name, eventName) => {
    const jszip = new JSZip();
    
    const folderRef = ref(storage, "DLC Rating Sheets")
    const files = await listAll(folderRef)
    let download = false

    console.log(name)
    console.log(eventName)
    console.log(eventName.split(""))

    const promises = files.items.map( async (item) => {

        const file = await getMetadata(item)

        const nameInList = file.name.split("-")

        console.log(nameInList)
        
        if ((nameInList[0] === eventName || nameInList[0] === `${eventName} `) && (nameInList[1] === name || nameInList[1] === ` TC: ${name}`)){
            console.log("match")    
            const fileRef = ref(storage, item.fullPath);

            const fileBlob = await getDownloadURL(fileRef).then( async (url) => {
                return fetch(url).then((response) => response.blob());
            });

            jszip.file(`${file.name}.pdf`, fileBlob);
            download = true
        }
    }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());

    await promises;
    
    if (download){

        const blob = await jszip.generateAsync({ type: 'blob' });
        
        saveAs(blob, `${eventName} DLC Rating Sheets`);
    }

}

export const deleteFilesDLCRatingSheets = async () => {
    const folderRef = ref(storage, "DLC Rating Sheets")
    const files = await listAll(folderRef)

    files.items.forEach(async (file) => {

        const newRef = ref(storage, `DLC Rating Sheets/${file.name}`)

        await deleteObject(newRef)
    })


    const userList = await getAllUsers()
    
    //await cleanup()

    userList.forEach(async user =>  {
        try {
            
            let newUser = user
            

            if (newUser.Districts_Registration && newUser.Districts_Registration.Event_One.HasRatingSheets){
                delete newUser.Districts_Registration.Event_One.HasRatingSheets
                await updateUser(newUser.UUID, newUser)
            }

            if (newUser.Districts_Registration && newUser.Districts_Registration.Event_Two && newUser.Districts_Registration.Event_Two.HasRatingSheets){
                delete newUser.Districts_Registration.Event_Two.HasRatingSheets
                await updateUser(newUser.UUID, newUser)
            }


        } catch (error) {
            console.log(user.General_Registration.Name)
        }
    })
    return
}




export const assignDLCRatingSheets = async (name, userList, eventName) => {
    let memberToPost

    userList.map((user) => {
        if (user.General_Registration && user.General_Registration.Name === name){
            memberToPost = user
            return
        }
    })

    if (memberToPost.Districts_Registration.Event_One.Name === eventName){
        memberToPost.Districts_Registration.Event_One.HasRatingSheets = true
        await updateUser(memberToPost.UUID, memberToPost)
    } else if (memberToPost.Districts_Registration.Event_Two && memberToPost.Districts_Registration.Event_Two.Name){
        memberToPost.Districts_Registration.Event_Two.HasRatingSheets = true
        await updateUser(memberToPost.UUID, memberToPost)
    }
}


/**
 * export const uploadTask = async (task) => {
    const newDocReference = doc(firestore, `Tasks/${task.Title}`)

    await setDoc(newDocReference, {status: "unreleased", ...task})
}
 * @param {*} email 
 * @param {*} password 
 */

export const addPassword = async (email, password) => {
    console.log("invoked");
    const newDocReference = doc(firestore, `Member Passwords/${email}`)
    await setDoc(newDocReference, { Email: email, Password: password })
    
}

export const addOfficerProfile = async (officerName, officerEmail, role, about, previousEvents, profileImageLink) => {
    const newDocReference = doc(firestore, `Officer Profiles/${officerName}`)
    await setDoc(newDocReference, { Name: officerName, Email: officerEmail, Role: role, About: about, Previous_Events: previousEvents, Profile_Image_Link: profileImageLink })
}

export const getAllOfficerProfiles = async () => {
    let ret = []
    const collectionRef = collection(firestore, "Officer Profiles")
    const snapshot = await getDocs(collectionRef)
    snapshot.forEach((doc) => {
        ret.push(doc.data())
    })
    return ret
}

export const deleteOfficerProfile = async (name) => {
    const docRef = doc(firestore, `Officer Profiles/${name}`)
    await deleteDoc(docRef)
}



export const addResourceSectionToDatabase = async (eventName) => {
    // add a map to the event in the event database with the key being the name of the resource section and the value being an array of the resources
    const newDocReference = doc(firestore, `Events/${eventName}`)
    if (await resourcesSectionExists(eventName)) {
        console.log("already exists")
        return
    }
    await setDoc(newDocReference, { Resources: {} }, { merge: true })
    console.log("added")
}

export const resourcesSectionExists = async (eventName) => {
    console.log("invoked")
    const docRef = doc(firestore, `Events/${eventName}`)
    const snapshot = await getDoc(docRef)
    if (snapshot.exists()) {
        const eventData = snapshot.data()
        if (eventData.Resources && Object.keys(eventData.Resources).length > 0) {
            return true
        } else {
            return false
        }
    }
}

export const addResourceToEvent = async (eventName, resourceName, resourceLink) => {
    const docRef = doc(firestore, `Events/${eventName}`)
    const snapshot = await getDoc(docRef)
    if (snapshot.exists()) {
        const eventData = snapshot.data()
        const newResources = eventData.Resources
        newResources[resourceName] = resourceLink
        await updateDoc(docRef, {
            Resources: newResources
        })
    }
}

export const clearResources = async (eventName) => {
    const docRef = doc(firestore, `Events/${eventName}`)
    await updateDoc(docRef, {
        Resources: deleteField()
    })
}

export const setCELink = async (eventName, link) => {
    const docRef = doc(firestore, `Events/${eventName}`)
    await updateDoc(docRef, {
        CE_Link: link
    })
}

export const deleteEvent = async (eventName) => {
    const docRef = doc(firestore, `Events/${eventName}`)
    await deleteDoc(docRef)
}

