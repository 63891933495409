import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { updateUser } from '../../Firebase/Users-CRUD'

export default function SLC_General_State_Items({ user }) {

    const [loading, setLoading] = useState({message:"", show:false})
    const navigate = useNavigate()

    const day1_lunch1 = "Chicken Alfredo (penne pasta, creamy alfredo, chopped chicken, peas)"
    const day1_lunch2 = "Vegetarian Farfalle (bowtie pasta, marinara, zucchini, mushrooms, spinach)"
    
    const day2_lunch1 = "Beef Lasagna"
    const day2_lunch2 = "Vegetable Lasagna"
    
    const entertainment1 = "Hypnotist ($5.00 Extra)"
    const entertainment2 = "Comedy Game Show ($5.00 Extra)" 

    const handleSubmit = async () => {
        setLoading({show:true, message:"Loading", type: "warning"})
        let addedCharge = 0
        let objToLoad = {State_Registration: {
                General_SLC_Registration: {},
                ...user.State_Registration
            }
        }

        document.getElementsByName("wed-lunch").forEach((Element) => {
            if (Element.checked){
                objToLoad.State_Registration.General_SLC_Registration.WednesdayLunch = Element.id
            }
        })
        document.getElementsByName("thurs-lunch").forEach((Element) => {
            if (Element.checked){
                objToLoad.State_Registration.General_SLC_Registration.ThursdayLunch = Element.id
            }
        })
        
        if(document.getElementById(`${entertainment1}`).checked){
            objToLoad.State_Registration.General_SLC_Registration.Hypnotist = true
            addedCharge+=5
        }
        if(document.getElementById(`${entertainment2}`).checked){
            objToLoad.State_Registration.General_SLC_Registration.Comedy_Night = true
            addedCharge+=5
        }
        
        
        objToLoad.State_Registration.General_SLC_Registration.AddedCharge = addedCharge
        objToLoad.State_Registration.General_SLC_Registration.TimeStamp = Date()
        
        if (document.getElementById(`code-of-conduct`).checked){
            objToLoad.State_Registration.General_SLC_Registration.Code_Of_Conduct = true
        } else {
            setLoading({show:true, message:"You must agree to the Code of Conduct", type: "danger"})
            return
        }
        try {
            console.log(objToLoad)
            await updateUser(user.UUID, objToLoad)

            setLoading({show:true, message:"Submitted", type: "success"})
            navigate("/")
        } catch (error) {
            setLoading({show:true, message:error, type: "danger"})
        }
    }



 /**
     * Components we need for Registration
     * 
     * 1. General Items
     *  ---> Guest Passes for Closing Ceremony
     *  ---> Thursday lunch
     *  ---> Thursday Night Entertainment Options
     *  ---> Wednesday Lunch
     * 
     * 2. Rooming
     *  ---> Roommates x 3
     *  ---> Gender Identity
     * 
     * 3. Events
     *  ---> Event name
     *  ---> Partners/Team members
     * 
     * 
     * <div class="meals-register bg-light rounded p-3 mb-4"> 
              <h4 class="events-header text-center">DLC MEALS</h4>
              <p>No special food requests. Your dietary restrictions will be accomodated for.</p>
              <p><strong>Your dietary restrictions: {userProfile && userProfile.General_Registration ? <>{userProfile.General_Registration.Dietary_Restrictions}</> : <></>}</strong></p>
            </div>
     */




  return (
    <div class="general-slc-reg bg-light rounded p-3 mb-4">
        <h4 class="gen-reg-slc-title text-center">Food, Entertainment, and Guest Passes</h4>
        { loading.show && <Alert variant={`${loading.type}`}>{loading.message}</Alert> }
        <div class="wed-lunch mt-4 ms-3">
            <h5>Monday Lunch Options</h5>
            <div>
                <input class="form-check-input" type="radio" name={`wed-lunch`} id={`${day1_lunch1}`} />
                <label class="form-check-label ms-2" for={`${day1_lunch1}`}>{ day1_lunch1 }</label>
            </div>
            <div>
                <input class="form-check-input" type="radio" name={`wed-lunch`} id={`${day1_lunch2}`} />
                <label class="form-check-label ms-2" for={`${day1_lunch2}`}>{ day1_lunch2 }</label>
            </div>
        </div>
        <div class="thurs-lunch mt-4 ms-3">
            <h5>Tuesday Lunch Options</h5>
            <div>
                <input class="form-check-input" type="radio" name={`thurs-lunch`} id={`${day2_lunch1}`} />
                <label class="form-check-label ms-2" for={`${day2_lunch1}`}>{ day2_lunch1 }</label>
            </div>
            <div>
                <input class="form-check-input" type="radio" name={`thurs-lunch`} id={`${day2_lunch2}`} />
                <label class="form-check-label ms-2" for={`${day2_lunch2}`}>{ day2_lunch2 }</label>
            </div>
        </div>
        <div class="entertainment mt-4 ms-3">
            <h5>Entertainment Options</h5>
            <div>
                <input class="form-check-input" type="checkbox" name={`entertainment`} id={`${entertainment1}`} />
                <label class="form-check-label ms-2" for={`${entertainment1}`}>{ entertainment1 }</label>
            </div>
            <div>
                <input class="form-check-input" type="checkbox" name={`entertainment`} id={`${entertainment2}`} />
                <label class="form-check-label ms-2" for={`${entertainment2}`}>{ entertainment2 }</label>
            </div>
        </div>

        <div class="code-of-conduct mt-4 ms-3">
            <h5>Code of Conduct</h5>
            <p class="fst-italic">Read the attached <a href='https://drive.google.com/file/d/1kdl4lZQjm8X-2PGNEPvRQ3Idw_qTfyoF/view'>Colorado FBLA Code of Conduct</a>. By checking below you agree to abide by all Colorado FBLA and Cherry Creek School District Policies.</p>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="code-of-conduct" />
                <label class="form-check-label ms-2" for="code-of-conduct">
                    I agree to the Code of Conduct
                </label>
            </div>

        </div>

        <div class="btn-submit">
            <button type="button" class="btn btn-primary ms-3 mt-4" onClick={handleSubmit}>Submit</button>
        </div>

    </div>
  )
}
