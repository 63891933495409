import React, { useEffect, useReducer, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { updateUser, getUser } from "../../Firebase/Users-CRUD"
import { getAllEvents } from '../../Firebase/Firestore-Query'
import { connectStorageEmulator } from 'firebase/storage'
import { useNavigate } from 'react-router-dom'


export default function SLC_Commitment({ user }) {

    const [loading, setLoading] = useState({message:"", show:false})
    const [showQuailifiedEventSelect, setShowQuailifiedEventSelect] = useState(false)
    const [showBumpedEventSelect, setShowBumpedEventSelect] = useState(false)
    const [showStateOnlyEventSelect, setShowStateOnlyEventSelect] = useState(false)
    const [eventOptions, setEventOptions] = useState([])
    const [events, setEvents] = useState([])
    const navigate = useNavigate()

    useEffect(() => { 
        async function loadEvents () {
            const temp = await getAllEvents()
            console.log(temp)
            setEvents(temp)
        }
        loadEvents()
    }, [])

   
    if (user && (!user.Districts_Registration || !user.Districts_Registration.Event_One.Place)){
        return (
            <div>
              Not Available Yet
            </div>
        )
    }
    
    
    
    
    
    const committmentSubmit = async () => {
        setLoading({ message:"Loading", show:true, type:"warning"})
        
        if(document.getElementById("SIGNATURE").value.length === 0){
            setLoading({ message:"Please Enter your Digital Signature", show:true, type:"warning"})
            return null
        }
        
        try {
            
            if (document.getElementById("QUAL_COMMIT").checked){
                await updateUser(user.UUID, { State_Registration : { Commitment: {Choice: "QUAL_COMMIT", SignedAt: Date(), Event_Name: document.getElementById("QualifiedEvents").value} } })
            } else if (document.getElementById("NOT_QUAL_COMMIT").checked){
                await updateUser(user.UUID, { State_Registration : { Commitment:{Choice: "COMMIT_ON_BUMP_UP", SignedAt: Date(), Event_Name: document.getElementById("BumpedEvents").value} } })
                
            } else if (document.getElementById("STATE_EVENT_COMMIT").checked){
                await updateUser(user.UUID, { State_Registration : { Commitment: { Choice: "STATE_EVENT_COMMIT", SignedAt: Date(), Event_Name: document.getElementById("StateEvents").value}  } })
                
            } else {
                await updateUser(user.UUID, { State_Registration : { Commitment: { Choice: "NOT_COMMIT", SignedAt: Date()} } })
            }
        } catch (error) {
            setLoading({ message:"An error ocurred", show:true, type:"danger"})
            console.log(error)
            return null
            
        }
        
        
        setLoading({ message:"Submitted", show:true, type:"success" })
        navigate("/")
    }
    
    const selectQualifiedCommit = () => {
        setShowBumpedEventSelect(false)
        setShowStateOnlyEventSelect(false)
        
        let includeEventOne = false
        const eventOneName = user.Districts_Registration.Event_One.Name
        let place
        if (user.Districts_Registration.Event_One.Place && user.Districts_Registration.Event_One.Place !== "Unplaced"){
            place = parseInt(user.Districts_Registration.Event_One.Place)
        }

        events.map((event) => {
            if (event.Name === eventOneName && event.isTest){
                if (place < 6){
                    includeEventOne = true
                }
            } else if (event.Name === eventOneName){
                if (place < 5){
                    includeEventOne = true
                }
            }
        })
        
        let includeEventTwo = false
        if (user.Districts_Registration.Event_Two){
            const eventTwoName = user.Districts_Registration.Event_Two.Name
            let place2
            if (user.Districts_Registration.Event_Two.Place && user.Districts_Registration.Event_Two.Place !== "Unplaced"){
                place2 = parseInt(user.Districts_Registration.Event_Two.Place)
                
            }
        //test or other
            events.map((event) => {
                if (event.Name === eventTwoName && event.isTest){
                    if (place2 < 6){
                        includeEventTwo = true
                    }
                } else if (event.Name === eventTwoName){
                    if (place2 < 5){
                        includeEventTwo = true
                    }
                }
            })
        }
        let tempList = ["Select"]
        if (includeEventOne){
            tempList.push(user.Districts_Registration.Event_One.Name)
        }
        
        if(includeEventTwo){
            tempList.push(user.Districts_Registration.Event_Two.Name)
        }
        setEventOptions(tempList)
        
        
        setShowQuailifiedEventSelect(true)
    }
    const selectBumpedUpCommit = () => {
        setShowStateOnlyEventSelect(false)
        setShowQuailifiedEventSelect(false)
        let tempList = ["Select", `${user.Districts_Registration.Event_One.Name}`]
        
        if (user.Districts_Registration.Event_Two){
            tempList.push(user.Districts_Registration.Event_Two.Name)
        }

        setEventOptions(tempList)

        setShowBumpedEventSelect(true)

    }

    const selectStateEvent = () => {
        setShowBumpedEventSelect(false)
        setShowQuailifiedEventSelect(false)
        setEventOptions(["Select", 
                        "American Enterprise Project", 
                        "Community Service Project", 
                        "Local Chapter Annual Business Report", 
                        "Partnership with Business Project", 
                        "Who's Who in FBLA",
                        "BAA - Advocate (BAA-3)",
                        "BAA - Capstone (BAA-4)",
                        "BAA - Contributer (BAA-1)",
                        "BAA - Leader (BAA-2)",
                        "Peak 1",
                        "Peak 2",
                        "Peak 3",
                        "Peak 4",
                        "Peak 5",
                        "Voting Delegate",
                        ])
        setShowStateOnlyEventSelect(true)
    }
    
    const notAttendingState = () => {
        setShowBumpedEventSelect(false)
        setShowQuailifiedEventSelect(false)
        setShowStateOnlyEventSelect(false)
        
    }

    
  return (
      <div>
        {
            user && !user.State_Registration ? 
            <>
                <div class="committment choice mb-3">
                    <h4> Please mark your committment preference for State</h4>

                    { loading.show && <Alert variant={`${loading.type}`}>{loading.message}</Alert> }

                    <div class="mt-4">
                        <input class="form-check-input" type="radio" name={`COMMIT`} id="QUAL_COMMIT" onClick={selectQualifiedCommit}/>
                        <label class="form-check-label ms-2" for={`QUAL_COMMIT`}>I qualified for an event AND I will attend the SLC</label>

                        {
                            showQuailifiedEventSelect ? 
                            <div class="ms-3">
                                <h4 class="mt-2">Select your Qualified State Event</h4>
                                <select class="form-select w-50 mt-3" name="events" id="QualifiedEvents">
                                {
                                    eventOptions.map((option) => {
                                        return <option value={option}>{option}</option>
                                    })
                                }
                                </select>
                            </div>
                            :
                            <></>
                        }

                    </div>
                    <div class="mt-3">
                        <input class="form-check-input" type="radio" name={`COMMIT`} id={`NOT_QUAL_COMMIT`} onClick={selectBumpedUpCommit}/>
                        <label class="form-check-label ms-2" for={`NOT_QUAL_COMMIT`}>I did not qualify, but I will attend state IF bumped up</label>

                        {
                            showBumpedEventSelect ? 
                            <div class="ms-3">
                                <h4 class="mt-2">Select your desired State Event if bumped up</h4>
                                <select class="form-select w-50 mt-3" name="events" id="BumpedEvents">
                                {
                                    eventOptions.map((option) => {
                                        return <option value={option}>{option}</option>
                                    })
                                }
                                </select>
                            </div>
                            :
                            <></>
                        }

                    </div>
                    <div class="mt-3">
                        <input class="form-check-input" type="radio" name={`COMMIT`} id={`STATE_EVENT_COMMIT`} onClick = {selectStateEvent}/>
                        <label class="form-check-label ms-2" for={`STATE_EVENT_COMMIT`}>I will attend State for a state-only/BAA event</label>

                        {
                            showStateOnlyEventSelect ? 
                            <div class="ms-3">
                                <h4 class="mt-2">Select your State Only Event</h4>
                                <select class="form-select w-50 mt-3" name="events" id="StateEvents">
                                {
                                    eventOptions.map((option) => {
                                        return <option value={option}>{option}</option>
                                    })
                                }
                                </select>
                            </div>
                            :
                            <></>
                        }

                    </div>
                    <div class="mt-3">
                        <input class="form-check-input" type="radio" name={`COMMIT`} id={`NOT_ATTENDING`} onClick={notAttendingState}/>
                        <label class="form-check-label ms-2" for={`NOT_ATTENDING`}>I will not be attending state</label>
                    </div>
                    <div>
                        <input class="form-control w-25 mt-4" type="text" name="SIGNATURE" id="SIGNATURE" placeholder="Digital Signature"/>
                    </div>
                    <button class="btn btn-primary  mt-3" onClick={committmentSubmit}>Submit</button>
                </div>
            </>
            :
            <div>
                <p class="fw-bold">{user.State_Registration.Commitment.Choice}</p>
                <p>Event: {user.State_Registration.Commitment.Event_Name}</p>
                <p class="fst-italic">Signed by {user.General_Registration.Name} on {user.State_Registration.Commitment.SignedAt}</p>
            </div>
        }
    </div>
  )
}
