import React from 'react'
import { useAuth, shallowEqual } from "../../AuthContext"
import { getUser } from "../../Firebase/Users-CRUD"

export default function Home() {
 
  return (
    <div>
        <h2>Hello</h2>
    </div>
  )
}