import React from 'react'
import { Link, useNavigate, Outlet } from "react-router-dom"
import { useAuth } from "../AuthContext"

import "./Navbar.css"

export default function OfficerSubNavbar() {
  const { logout, userProfile } = useAuth()
  const navigate = useNavigate()
  const handleLogout = async () => {
    await logout()
    navigate("/login")
  }
  return (  
    <div class="officer-subnav-container container">
      <div class="navbar-container mb-5 row ">
        <Link class="btn btn-dark col-sm-4 col-md py-2" to="manageMembers">Manage Members</Link>
        <Link class="btn btn-dark col-sm-4 col-md py-2" to="reporting">Chapter Reports</Link>
        <Link class="btn btn-dark col-sm-4 col-md py-2" to="managePayments">Manage Payments</Link>
        <Link class="btn btn-dark col-sm-4 col-md py-2" to="managePermissionForms">Manage Permission Forms</Link>
        <Link class="btn btn-dark col-sm-4 col-md py-2" to="manageEvents">Manage Events</Link>
        <Link class="btn btn-dark col-sm-4 col-md py-2" to="auditLog">Audit Log</Link>
        <Link class="btn btn-dark col-sm-4 col-md py-2" to="officerProfiles">Officer Profiles</Link>
        <Link class="btn btn-dark col-sm-4 col-md py-2" to="userFormUpload">User Form Uploads</Link>
      </div>
      <div class="page-container">
        <Outlet />
      </div>
    </div>
  )
}
