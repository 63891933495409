import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from '../AuthContext'
import { Link, useNavigate } from "react-router-dom"

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const navigate = useNavigate()

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const { login } = useAuth()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            navigate("/home/conference")
        } catch (error) {
            setError(error.code)
        }
        setLoading(false)

    }
  return (
    <>
        <Card>
            <Card.Body>
                <div class="container text-center">
                    <div class="row my-5 bg-light rounded p-5">
                        <div class="col gap-4">
                            <div class="fs-5">Grandview High School</div>
                            <div class="fs-4 fw-bold mb-5">FUTURE BUSINESS LEADERS OF AMERICA</div>
                            <div class="fs-4 fw-bold mb-4">ACCOUNT LOGIN</div>
                        </div>
                    
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <div class="row mb-3 px-4">
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                            </div>
                            
                            <div class="row mb-3 px-4">
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} required />
                                </Form.Group>
                            </div>
                        
                            <div class="row my-4 px-4 justify-content-center">
                                <div class="col-6">
                                    <Button disabled={loading} className="w-100 mt-2" type="submit">Log In</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
               
            </Card.Body>
        </Card>
        <div class="fw-bold text-center my-2">
            Forgot password? <Link to="/forgotPassword">Forgot Password</Link><br></br>
            Need an account? <Link to="/register">Register</Link>
        </div>
    </>
  )
}
