import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { AiOutlineLoading } from "react-icons/ai"
import { generateDLCReport, getAllEvents } from '../../Firebase/Firestore-Query'
import { getAllUsers } from '../../Firebase/Users-CRUD'
import { ExportToCsv } from 'export-to-csv'

import IndividualEventReport from "../../Components/IndividualEventReport"
import TeamedEventReport from "../../Components/TeamedEventReport"

export default function Reports() {
    const [users, setUsers] = useState([])
    
    //states for showing necessary tables
    const [showMembership, setShowMembership] = useState(false)

    const [showPayment, setShowPayment] = useState(false)
    const [paymentReportColumn, setPaymentReportColumn] = useState([])
    const [totalPaid, setTotalPaid] = useState([0])

    const [showForms, setShowForms] = useState(false)
    const [formNames, setFormNames] = useState([])
    const [usersWithoutForms, setUsersWithoutForms] = useState([])

    const [showDLCEvents, setShowDLCShowEvents] = useState(false)
    const [individualEvents, setIndividualEvents] = useState([])
    const [teamedEvents, setTeamedEvents] = useState([])
    const [incompleteUsers, setIncompleteUsers] = useState([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function loadUsers() {
            const list = await getAllUsers()
            setUsers(list)
        }
        loadUsers()
    }, [])


    const membershipReport = async () => {
        setShowMembership(true)
    }

    const downloadMembership = () => {
        let columns = ["Name", "Grade", "Email", "Phone Number", "T-Shirt Size", "Dietary Restrictions"]


        const options = {
            fieldSeparator: ',',
            filename: "GHS FBLA Membership Report",
            showLabels:true,
            headers:columns
        }

        let userList = []

        users.forEach(user => {
            if (user.General_Registration){
                let obj = {
                    Name: user.General_Registration.Name,
                    Grade: user.General_Registration.Grade,
                    Email: user.Email,
                    ["Phone Number"]: user.General_Registration.Phone_Number,
                    ["T-Shirt Size"]: user.General_Registration.T_Shirt_Size,
                    ["Dietary Restrictions"]: user.General_Registration.Dietary_Restrictions,
                }
                userList.push(obj)
            }
        })
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(userList)
    }

    const paymentReport = async () => {
        if (!users[0]){
            return
        }
        
        let columnNames = []

        Object.keys(users[0]).forEach((key) => {
            if (users[0][key].Amount){
                columnNames.push(key)
            }
        })

        let tempPayments = [0]
        setPaymentReportColumn(columnNames)

        columnNames.map((column, i) => {
            let total = 0
            users.forEach(user => {
                if (user[column].Paid){
                    total++
                }
            })
            tempPayments[i] = total
        })

        setTotalPaid(tempPayments)
        setShowPayment(true)
    }

    const downloadPayment = () => {
        let columnNames = []

        Object.keys(users[0]).forEach((key) => {
            if (users[0][key].Amount){
                columnNames.push(key)
            }
        })

        const options = {
            fieldSeparator: ',',
            filename: "GHS FBLA Payment Report",
            showLabels:true,
            headers:["Name", ...columnNames, "Email", "Phone Number"]
        }

        let reportList = []
        users.forEach((user) => {
            if (user.General_Registration){
                let obj = {
                    Name: user.General_Registration.Name,
                }
                columnNames.forEach((column) => {
                    if (user[column].Paid){
                        obj[column] = true
                    } else {
                        obj[column] = false
                    }
                })
                obj["Email"] = user.Email
                obj["Phone Number"] = user.General_Registration.Phone_Number
                reportList.push(obj)
            }
        })
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(reportList)
    }

    const formReport = () => {
        let names = []
        if (users[0] && users[0].General_Registration){
            users[0].Forms.map((form) => {
                names.push(form.Name)
            })
            console.log(names)
            setFormNames(names)
        }
        pendingForms()
        setShowForms(true)
        
    }
    const downloadForms = () => {
        let names = []
        if (users[0] && users[0].General_Registration){
            users[0].Forms.map((form) => {
                names.push(form.Name)
            })
            setFormNames(names)
        }

        const options = {
            fieldSeparator: ',',
            filename: "GHS FBLA Permission Form Report",
            showLabels:true,
            useKeysAsHeaders:true
        }

        let userList = []

        users.forEach(user => {
            if (user.General_Registration){
                let obj = {
                    Name: user.General_Registration.Name,
                }
                formNames.map((form, i) => {
                    if (user.Forms[i].Completed){
                        obj[form] = "Completed"
                    } else {
                        obj[form] = "INCOMPLETE"
                    }
                })
                userList.push(obj)
            }
        })

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(userList)
    }
    const getTeamNumber = (event, teamedEvents, userName) => {
        let number;
        teamedEvents.map((teamedEvent) => {
            if (teamedEvent.EventName === event.Name){
                teamedEvent.TeamsCompeting.map((team, i) => {
                    if (team.Team_Members.includes(userName)){
                        number = i+1
                    }
                })

            }
        })
        return number
    }
    const downloadDLCEventReport = async () => {
        let memberList = []
        const listOfEvents = await getAllEvents()
        const { IndividualEvents, TeamedEvents, IncompleteUsers } = await generateDLCReport()
        console.log(IndividualEvents)
        console.log(TeamedEvents)
        console.log(TeamedEvents)
        users.map(user => {
            if (user.General_Registration && user.Districts_Registration){
                let objToAdd = {
                    School: "Grandview High School",
                    Name: user.General_Registration.Name,
                    Grade: user.General_Registration.Grade,
                    "Competitive Event 1": user.Districts_Registration.Event_One.Name
                }

                //type of event 1 -- switch statement
                let eventType;
                listOfEvents.map((event) => {
                    if (event.Name === user.Districts_Registration.Event_One.Name){
                        eventType = event.Event_Type
                        return
                    }
                })
                
                switch (eventType) {
                    case "Objective Test":
                        objToAdd["Objective Test Selection"] = "Yes"
                        break;
                    case "Production Test Event":
                        objToAdd["Production Events Selection"] = "Yes"
                        break;
                    case "Presentation":
                        objToAdd["Presentation Event Selection"] = "Yes"
                        break;
                    case "Case Study":
                        objToAdd["Case Study Selection"] = "Yes"
                        break;
                    default:
                        break;
                }

                const number = getTeamNumber(user.Districts_Registration.Event_One, TeamedEvents, user.General_Registration.Name)
                
                if (number){
                    objToAdd["Team # - Event 1"] = number
                }
                
                
                //if event 2
                if (user.Districts_Registration.Event_Two && user.Districts_Registration.Event_Two !== "None"){
                    objToAdd["Competitive Event 2"] = user.Districts_Registration.Event_Two.Name
                } else {
                    objToAdd["Competitive Event 2"] = ""
                }

                //type of event 2 - switch statement
                if (objToAdd["Competitive Event 2"] !== ""){
                    let eventType2;
                    listOfEvents.map((event) => {
                        if (event.Name === user.Districts_Registration.Event_Two.Name){
                            eventType2 = event.Event_Type
                            return
                        }
                    })
                    
                    
                    switch (eventType2) {
                        
                        case "Objective Test":
                            objToAdd["Objective Test Selection"] = "Yes"
                            break;
                        case "Production Test Event":
                            objToAdd["Production Events Selection"] = "Yes"
                            break;
                        case "Presentation":
                            objToAdd["Presentation Event Selection"] = "Yes"
                            break;
                        case "Case Study":
                            objToAdd["Case Study Selection"] = "Yes"
                            break;
                        default:
                            break;
                    }
                    if (!objToAdd["Team #"]){
                        const number2 = getTeamNumber(user.Districts_Registration.Event_Two, TeamedEvents, user.General_Registration.Name)
                        
                        if (number2){
                            objToAdd["Team # - Event 2"] = number2
                        }
                    }
                }
                if (!objToAdd["Objective Test Selection"]){
                    objToAdd["Objective Test Selection"] = ""
                }
                if (!objToAdd["Production Events Selection"]){
                    objToAdd["Production Events Selection"] = ""
                }
                if (!objToAdd["Presentation Event Selection"]){
                    objToAdd["Presentation Event Selection"] = ""
                }
                if (!objToAdd["Case Study Selection"]){
                    objToAdd["Case Study Selection"] = ""
                }
                if (!objToAdd["Team # - Event 1"]){
                    objToAdd["Team # - Event 1"] = ""
                }
                if (!objToAdd["Team # - Event 2"]){
                    objToAdd["Team # - Event 2"] = ""
                }
                memberList.push(objToAdd)
            }
        })

        const options = {
            fieldSeparator: ',',
            filename: "GHS FBLA DLC Events Report",
            showLabels:true,
            useKeysAsHeaders:true
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(memberList)

    }

    const downloadDLCEventReportByEvent = async () => {
        let eventList = []
        const cols = {
            "Event Name": "Event Name",
            "Member 1": "",
            "Member 2": "",
            "Member 3": "",
            "Member 4": "",
            "Member 5": "",
            "Member 6": "",
            "Member 7": "",
            "Member 8": "",
            "Member 9": "",
            "Member 10": "",
            "Team 1": "",
            "Team 2": "",
            "Team 3": "",
            "Team 4": "",
            "Team 5": "",
            "Team 6": ""
        };
        eventList.push(cols);
        const { IndividualEvents, TeamedEvents, IncompleteUsers } =
            await generateDLCReport();
        for (let i = 0; i < IndividualEvents.length; i++) {
            if(IndividualEvents[i].Competitors.length === 0) continue;
            let objToAdd = {
                "Event Name": IndividualEvents[i].EventName,
            };
            for (let j=0; j<10; j++) {
                objToAdd[`Member ${j+1}`] = "";
            }
            for (let j=0; j<6; j++) {
                objToAdd[`Team ${j+1}`] = "";
            }
            if (IndividualEvents[i].Competitors && IndividualEvents[i].Competitors.length > 0) {
                for (let j = 0; j < IndividualEvents[i].Competitors.length; j++) {
                    objToAdd[`Member ${j + 1}`] = IndividualEvents[i].Competitors[j];
                }
            }
            eventList.push(objToAdd);
        }
        for (let i = 0; i < TeamedEvents.length; i++){
            if(TeamedEvents[i].TeamsCompeting.length === 0) continue;
            let objToAdd = {
                "Event Name": TeamedEvents[i].EventName,
            }
            for (let j=0; j<6; j++) {
                objToAdd[`Team ${j+1}`] = "";
            }
            for (let j=0; j<10; j++) {
                objToAdd[`Member ${j+1}`] = "";
            }
            if (TeamedEvents[i].TeamsCompeting && TeamedEvents[i].TeamsCompeting.length > 0) {
                for (let j = 0; j < TeamedEvents[i].TeamsCompeting.length; j++){
                    objToAdd[`Team ${j+1}`] = TeamedEvents[i].TeamsCompeting[j].Team_Members.join(", ")
                }
            }
            eventList.push(objToAdd)
        }
        const options = {
            fieldSeparator: ',',
            filename: "GHS FBLA DLC Events Report",
            showLabels:true,
            useKeysAsHeaders:true
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(eventList)
    }


    const dlcEventReport = async () => {
        setLoading(true)
        const { IndividualEvents, TeamedEvents, IncompleteUsers } = await generateDLCReport()
        console.log(IndividualEvents)
        console.log(TeamedEvents)
        console.log(IncompleteUsers)
        setIndividualEvents(IndividualEvents)
        setTeamedEvents(TeamedEvents)
        setIncompleteUsers(IncompleteUsers)
        setShowDLCShowEvents(true)
        setLoading(false)

    }

    const pendingForms = () => {
        let pending = []
        users.map(user=> {
            let allFormsComplete = true;
            user.Forms.map((form) => {
                if (!form.Completed){
                    allFormsComplete = false;
                }
            })
            if (!allFormsComplete){
                pending.push(user)
            }
        })
        console.log(pending)
        setUsersWithoutForms(pending)
        
    }

  return (
    <div class="">
        <div class="membership bg-light rounded p-3 mx-3 my-5 container">
            <h3 class= "text-center mt-2 mb-5 row justify-content-center">MEMBERSHIP REPORTS</h3>
            <div class="buttons row justify-content-center">
                <button onClick={membershipReport} class="btn btn-primary m-2 p-2 col-6 justify-content-center text-center">Generate Membership Report</button>
                <button onClick={downloadMembership} class="btn btn-primary m-2 p-2 col-6 justify-content-center text-center">Download Membership Report</button>
            </div>
            <div class="membership-table">
                {
                    showMembership ? 
                    <>
                        <button onClick={()=> setShowMembership(false)}  class="btn btn-danger m-2 p-2">Close Report</button>
                       <table class="table mt-3">
                            <tr>
                                <th class="d-md-table-cell d-none">Name</th>
                                <th class="d-md-table-cell d-none">Freshman</th>
                                <th class="d-md-table-cell d-none">Email</th>
                                <th class="d-md-table-cell d-none">T-Shirt Size</th>
                                <th class="d-md-table-cell d-none">Dietary Restrictions</th>
                                <th class="d-md-table-cell d-none">Phone Number</th>
                            </tr>
                            <tbody>
                                {
                                    users.map((element, i) => {
                                        if (element.General_Registration){
                                            return (
                                                <tr class="">
                                                    <td>{element.General_Registration.Name}</td>
                                                    <td>{element.General_Registration.Grade}</td>
                                                    <td>{element.Email}</td>
                                                    <td>{element.General_Registration.T_Shirt_Size}</td>
                                                    <td>{element.General_Registration.Dietary_Restrictions}</td>
                                                    <td>{element.General_Registration.Phone_Number}</td>
                                                </tr>
                                            )
                                        }
                                    })
                                }
                            </tbody>
                        </table> 
                    </> : <></>
                }
            </div>
        </div>

        <div class="payment-report bg-light rounded p-3 mx-3 my-5 container">
            <h3 class= "text-center mt-2 mb-5 row justify-content-center">PAYMENT REPORTS</h3>
            <div class="buttons row justify-content-center">
                <button onClick={paymentReport} class="btn btn-primary m-2 p-2 col-6 justify-content-center text-center">Generate Payment Report</button>
                <button onClick={downloadPayment} class="btn btn-primary m-2 p-2 col-6 justify-content-center text-center">Download Payment Report</button>
            </div>

            <div class="payment-table">
                {
                    showPayment ? 
                    <>

                        <button onClick={()=> setShowPayment(false)}  class="btn btn-danger m-2 p-2">Close Report</button>
                        <div class="totals m-2">
                            <h2>Totals</h2>
                            {
                                paymentReportColumn.map((column, i) => {
                                    return <>
                                        <h3 class="m-3">{column}</h3>
                                        <h4 class="m-3">Total Paid: {totalPaid[i]} / {users.length}</h4>
                                    </>
                                })
                            }
                        </div>
                        {
                            paymentReportColumn.map((tableName) => {
                                return (
                                    <div>
                                        <h3 class="text-center">{tableName} - Paid Members</h3>
                                        <table class="table mt-3">
                                            <tr>
                                                <th>Name</th>
                                                <th>{tableName}</th>
                                                <th>Amount</th>
                                            </tr>
                                            <tbody>
                                                {
                                                    
                                                    users.map((user) => {
                                                        if (user.General_Registration && user[tableName].Paid){
                                                            return <>
                                                                <tr>
                                                                    <td>{user.General_Registration.Name}</td>
                                                                    <td>PAID</td>
                                                                    <td>{user[tableName].Amount}</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <h3 class="text-center">{tableName} - UNPAID Members</h3>
                                        <table class="table mt-3">
                                            <tr>
                                                <th>Name</th>
                                                <th>{tableName}</th>
                                                
                                            </tr>
                                            <tbody>
                                                {
                                                    users.map(user => {
                                                        if (user.General_Registration && !user[tableName].Paid){
                                                            return <>
                                                                <tr>
                                                                    <td>{user.General_Registration.Name}</td>
                                                                    <td>NOT PAID</td>
                                                                    <td>{user.Email}</td>
                                                                    <td>{user.General_Registration.Phone_Number}</td>
                                                                    
                                                                </tr>
                                                            </>
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }).reverse()
                        }
                    </> : <></>
                }
            </div>
        </div>
        <div class="dlc-event-report bg-light rounded p-3 mx-3 my-5 container">
            <h3 class= "text-center mt-2 mb-5 row justify-content-center">DLC EVENTS REPORTS</h3>
            <div class="loading container">
                { loading && <Alert variant="warning">Loading</Alert> }
            </div>
            <div class="buttons row justify-content-center">
                <button onClick={dlcEventReport} class="btn btn-primary m-2 p-2 col-6 justify-content-center text-center">Generate DLC Events Report</button>
                <button onClick={downloadDLCEventReport} class="btn btn-primary m-2 p-2 col-6 justify-content-center text-center">Downlad DLC Events Report by Member</button>
                <button onClick={downloadDLCEventReportByEvent} class="btn btn-primary m-2 p-2 col-6 justify-content-center text-center">Download DLC Events Report By Event</button>
            </div>
            {
                showDLCEvents ?
                <>
                    <div class="show-report">
                        <button onClick={()=> setShowDLCShowEvents(false)}  class="btn btn-danger m-2 p-2">Close Report</button>
                        <div class="incomplete mt-3">
                            <h4>Incomplete Registration</h4>
                            <ol>
                                {
                                    incompleteUsers.map((user) => {
                                        if (user.General_Registration && user.Email !== "NULL"){
                                            return (
                                                <li>{user.General_Registration.Name}</li>
                                            )
                                        } else {
                                            return (
                                                <li>{user.Email} ({user.UUID}) </li>
                                            )
                                        }
                                    })
                                }
                            </ol>
                        </div>
                        <div class="event-table">
                            <h2 class="text-center">Individual Events</h2>
                            <IndividualEventReport data={individualEvents} />
                            <h2 class="text-center">Teamed Events</h2>
                            <TeamedEventReport data={teamedEvents} />
                        </div>
                    </div>
                </> : <></>
            }
        </div>

        <div class="forms-report bg-light rounded p-3 mx-3 my-5 container">
            <h3 class= "text-center mt-2 mb-5 row justify-content-center">PERMISSION FORMS REPORTS</h3>
            <div class="buttons row justify-content-center">
                <button onClick={formReport} class="btn btn-primary m-2 p-2 col-6 justify-content-center text-center">Generate Permission Forms Report</button>
                <button onClick={downloadForms} class="btn btn-primary m-2 p-2 col-6 justify-content-center text-center">Download Permission Forms Report</button>
            </div>
            <div class="membership-table">
                {
                    showForms ? 
                    <>
                        <div class="incomplete m-2">
                            <button onClick={() => setShowForms(false)}  class="btn btn-danger m-2 p-2">Close Report</button>
                            <h4 class="m-3">Pending Form(s)</h4>
                            <ol>
                                {
                                    usersWithoutForms.map(user => {
                                        if (user.General_Registration){
                                            return (
                                                <li>{user.General_Registration.Name}</li>
                                            )
                                        } else {
                                            return (
                                                <li>{user.Email} ({user.UUID})</li>
                                            )
                                        }
                                    })
                                }
                            </ol>
                        </div>
                        <h2 class="m-4 text-center">Matrix View</h2>
                       <table class="table mt-3">
                            <tr>
                                <th class="d-md-table-cell d-none">Name</th>
                                {
                                    formNames.map(name => {
                                        return <th class="d-md-table-cell d-none">{name}</th>
                                    })
                                }
                            </tr>
                            <tbody>

                            {
                                users.map((element, i) => {
                                    if (element.General_Registration){
                                        return <tr>
                                            <td>{element.General_Registration.Name}</td>
                                            {
                                                formNames.map((form, i) => {
                                                    if (element.Forms[i].Completed){
                                                        return <td>Completed</td>
                                                    } else {
                                                        return <td>INCOMPLETE</td>
                                                    }
                                                })
                                            }
                                        </tr>
                                    }
                                })
                            }
                                                        </tbody>
                        </table> 
                    </> : <></>
                }
            </div>
        </div>
    </div>
  )
}
