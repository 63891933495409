import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { getAllUsers, updateUser, getUser, resetSLCGeneral } from '../../../../Firebase/Users-CRUD'

export default function UserAuth_Level() {

    const [members, setMembers] = useState([])
    const [loadingState, setLoadingState] = useState({show: false})
    const [statePayments, setStatePayments] = useState([])

    useEffect(() => {

        async function loadUsers() {

            const users = await getAllUsers()

            let temp = []

            users.map((user) => {
                temp.push(user)
            })
            setMembers(temp)

        }
        loadUsers()
    }, [])

    const handleAuthLevelChange = async (uuid) => {
        console.log(uuid)
        setLoadingState({show: true, variant: "warning", message: "Loading"})
        const newLevel = document.getElementById(`auth-level-${uuid}`).value
        await updateUser(uuid, { authLevel: newLevel })
        setLoadingState({show: true, variant: "success", message: `Changed Auth to ${newLevel}`})
        

    }


  return (
    <div>
      <h3 class="text-center">Grandview FBLA Auth Level Settings</h3>
      { loadingState.show && <Alert variant={`${loadingState.variant}`} >{loadingState.message}</Alert>}
      <table class="table">
        <thead>
            <tr>
                <th class="text-center" scope="col">Name</th>
                <th class="text-center" scope="col">Current Auth Level</th>
                <th  scope="col">Change Auth Level</th>
                <th class="text-center" scope="col">Reset</th>
            </tr>
        </thead>
        <tbody>
            {
                members.map((member, i) => {
                    if (member.General_Registration && member.authLevel == "member"){
                        return <tr>
                            <td class="text-center">{member.General_Registration.Name}</td>
                            <td class="text-center">{member.authLevel}</td>
                            <td class="text-center">
                                
                                <select class="form-select w-50" id={`auth-level-${member.UUID}`}>
                                    <option value="member">Member</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </td>
                            <td class="text-center"> 
                                <button class="btn btn-warning mt-2" onClick={() => handleAuthLevelChange(member.UUID)}>Change Permission</button>
                            </td>
                            
                        </tr>
                    } else if (member.General_Registration && member.authLevel == "admin"){
                        return <tr>
                            <td class="text-center">{member.General_Registration.Name}</td>
                            <td class="text-center">{member.authLevel}</td>
                            <td class="text-center">
                                
                                <select class="form-select w-50" id={`auth-level-${member.uuid}`}>
                                    <option value="admin">Admin</option>
                                    <option value="member">Member</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <button class="btn btn-warning mt-2" onClick={() => handleAuthLevelChange(member.UUID)}>Change Permission</button>
                            </td>
                            
                        </tr>

                    }
                })
            }
            
            
        </tbody>
      </table>
    </div>

  )
}
