import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { updateUser } from '../../Firebase/Users-CRUD'
export default function AuthLevelColumn( { currentLevel, i, uuid } ) {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({show: false})
    
    const handleAuthLevelChange = async () => {
        setLoading(true)
        const newLevel = document.getElementById(`auth-level-${i}`).value
        await updateUser(uuid, { authLevel: newLevel })
        setLoading(false)
        setMessage({show: true, message: `Updated to ${newLevel}`})

    }

    if (currentLevel === "member"){
        return (
          <div class="">
            { loading && <Alert variant="warning">Loading</Alert> }
            { message.show && <Alert variant="success">{message.message}</Alert> }
            <select class="form-select w-50" id={`auth-level-${i}`} >
              <option value="member" selected>Member</option>
              <option value="admin">Admin</option>
            </select>
            <button class="btn btn-warning mt-2" onClick={handleAuthLevelChange}>Change Permission</button>
          </div>
        )
    } else {
        return (
            <div class="w-50">
                { loading && <Alert variant="warning">Loading</Alert> }
                { message.show && <Alert variant="success">{message.message}</Alert> }
                <select class="form-select" id={`auth-level-${i}`} >
                  <option value="member">Member</option>
                  <option value="admin" selected>Admin</option>
                </select>
                <button class="btn btn-warning mt-2" onClick={handleAuthLevelChange}>Change Permission</button>
            </div>
        )
    }
}
