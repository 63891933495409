import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { getAllUsers, updateUser, getUser, resetSLCGeneral } from '../../../../Firebase/Users-CRUD'

export default function MasterList() {
    
    const [members, setMembers] = useState([])
    const [loadingState, setLoadingState] = useState({show: false})
    const [statePayments, setStatePayments] = useState([])

    useEffect(() => {
        async function loadUsers() {
            const users = await getAllUsers()

            let temp = []
            let tempPayments = []

            users.map((user) => {
                if (user.State_Registration && (user.State_Registration.Commitment.Choice !== "NOT_COMMIT" && user.State_Registration.Commitment.Choice !== "NOT_BUMPED_UP")){
                    temp.push(user)
                }
            })

            Object.keys(temp[0]).forEach((key) => {
                if (temp[0][key].StateTag){
                    tempPayments.push(key)
                }
            })

            temp.sort((a, b) => (a.UUID > b.UUID) ? 1 : -1)
            setStatePayments(tempPayments)
            setMembers(temp)
        }
        loadUsers()
    }, [])

    const handleReset = async (uuid) => {

        setLoadingState({show: true, message: "Loading", variant: "warning"})

        try {     
            await resetSLCGeneral(uuid)
            const users = await getAllUsers()
            setMembers(users)
    
            setLoadingState({show: true, message: "Updated", variant: "success"})
        } catch (error) {
            setLoadingState({show: true, message: `${error}`, variant: "danger"})
        }

    }


  return (
    <div>
      <h3 class="text-center">Grandview State Roster</h3>
      <p class="fst-italic">The following members have indicated they WILL ATTEND STATE. Either QUAL_COMMIT, COMMIT_ON_BUMP_UP, BUMPED_UP, or STATE_ONLY_EVENT members show up</p>
      { loadingState.show && <Alert variant={`${loadingState.variant}`} >{loadingState.message}</Alert>}
      <table class="table">
        <thead>
            <tr>
                <th class="text-center" scope="col">Name</th>
                <th class="text-center" scope="col">Event</th>
                <th class="text-center" scope="col">Forms</th>
                <th class="text-center" scope="col">Items</th>
                <th class="text-center" scope="col">Rooming</th>
                {
                    statePayments.map((payment) => {
                        return <th class="text-center" scope="col">{payment}</th>
                    })
                }
            </tr>
        </thead>
        <tbody>
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration){
                        return <tr>
                            <td class="text-center fw-bold align-middle">{member.General_Registration.Name}</td>
                            {
                                member.State_Registration.SLC_Event ? 
                                    <td class="">
                                        {member.State_Registration.SLC_Event.Event_Name}
                                        
                                        {
                                            member.State_Registration.SLC_Event.Team_Members ?
                                            <ol>
                                            {
                                                member.State_Registration.SLC_Event.Team_Members.map((member) => {
                                                    if(member.length > 0){
                                                        return <li>{member}</li>
                                                    }
                                                })
                                            }
                                            </ol> : <></>
                                        }
                                    </td> : <td class="text-center align-middle">INCOMPLETE</td>
                            }
                            {
                                member.State_Registration.CommitmentForm ? 
                                    <td class="text-center align-middle">
                                        Complete
                                    </td> : <td class="text-center align-middle">INCOMPLETE</td>
                            }
                            {
                                member.State_Registration.General_SLC_Registration ? 
                                    <td>
                                        <ul>
                                            <li>Wednesday Lunch: {member.State_Registration.General_SLC_Registration.WednesdayLunch}</li>
                                            <li>Thursday Lunch: {member.State_Registration.General_SLC_Registration.ThursdayLunch}</li>
                                            <li>Guest Passes: {member.State_Registration.General_SLC_Registration.Guest_Pass}</li>
                                            {member.State_Registration.General_SLC_Registration.Hypnotist && <li>Hypnotist</li>}
                                            {member.State_Registration.General_SLC_Registration.Trivia_Night && <li>Trivia Night</li>}
                                            <li>Additional Charge: ${member.State_Registration.General_SLC_Registration.AddedCharge}</li>
                                        </ul>
                                    </td> : <td class="text-center align-middle">INCOMPLETE</td>
                            }
                            {
                                member.State_Registration.Rooming ? 
                                    <td>
                                        <ol>
                                            {
                                                member.State_Registration.Rooming.Requests.map((roommate) => {
                                                    return <li>{roommate}</li>
                                                })
                                            }
                                        </ol>
                                    </td> : <td class="text-center align-middle">INCOMPLETE</td>
                            }
                            {
                                statePayments.map((payment) => {
                                    if (member[payment].Paid){
                                        return <>
                                            <td class="bg-success text-white fw-bold text-center align-middle">Paid - {member[payment].TotalPaid}</td>
                                        </>
                                    } else if (!member[payment].Paid){
                                        return <td class="bg-danger text-white fw-bold text-center align-middle">NOT Paid</td>
                                    }
                                })
                            }
                        </tr>
                    }
                })
            }
            
        </tbody>
      </table>
    </div>
  )
}
