import React from 'react'
import Table from "react-bootstrap/Table"

export default function IndividualEventReport({ data }) {
  return (
    <div>
      {
        // data.map((event) => {
        //     if (event.Competitors.length > 0) {
        //         return (
        //             <>
        //                 <h3>{event.EventName}</h3>
        //                 <ol>
        //                   {
        //                       event.Competitors.map(competitor => {
        //                           return <li>{competitor}</li>
        //                       })
        //                   }
        //                 </ol>
        //             </>
        //         )
        //     }
        // })

      }
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Names</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map(event => {
              if (event.Competitors.length > 0){
                return (
                  <>
                    <tr >
                      <td  class="text-center align-middle fw-bold fs-4" rowspan={`${event.Competitors.length}`}>{event.EventName} (Total: {event.Competitors.length})</td>
                      <td>{event.Competitors[0]}</td>
                    </tr>
                    {
                      event.Competitors.map((competitor, i) => {
                        if (i !== 0){

                          return (
                            <tr>
                                <td>{competitor}</td>
                            </tr>
                          )
                        }
                      })
                    }
                  </>
                )
              }
            })
          }
          
                    
                    
        </tbody>
      </Table>
    </div>
  )
}
