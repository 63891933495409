import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { getAccessCode, updateAccessCode, generateDLCReport, getStateRegistration } from '../../Firebase/Firestore-Query'
import { getAllUsers, deleteUser } from '../../Firebase/Users-CRUD'
import { useAuth } from "../../AuthContext"
import { getUser } from '../../Firebase/Users-CRUD'
import { Link, useNavigate, Outlet } from "react-router-dom"
import { updateSLCRegStatus } from '../../Firebase/Firestore-Query'



export default function Admin() {
  const [code, setCode] = useState(null)
  const [message, setMessage] = useState({ show:false })
  const [user, setUser] = useState(null)
  const [userList, setUserList] = useState([])
  const { currentUser } = useAuth()
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    async function loadKey() {
      const key = await getAccessCode()
      setCode(key.Access_Code)

      const us = await getUser(currentUser.uid)
      setUser(us)

      const list = await getAllUsers()
      setUserList(list)

      const sr = await getStateRegistration()
      setIsEnabled(sr)


    }

    loadKey()
  }, [])

  const handleDeleteMembers = async () => {
    setMessage({ show:false })
    let unwanted = []
    let unwantedNames = []
    for (let i = 0; i <userList.length; i++){
      if (document.getElementById(`member-${i}`).checked){
        unwanted.push(document.getElementById(`member-${i}`).value)
      }
    }
    unwanted.map(uuid => {
      userList.map((user) => {
        if (user.UUID === uuid){
          if (user.General_Registration){
            unwantedNames.push(user.General_Registration.Name)
          } else {
            unwantedNames.push(user.Email)
          }
        }
      })
    })

    if (unwanted.length === 0){
      setMessage({ show:true, variant:"danger", message:"Must select at least one member to be deleted"})
      return
    }
    
    await deleteUser(unwanted, unwantedNames, currentUser)
    
    const list = await getAllUsers()
    setUserList(list)
    setMessage({ show:true, variant:"success", message:"Member(s) successfully deleted from system"})

  }


  const handleChangeCode = async () => {
    const newCode = document.getElementById("new-access-code").value


    if (!newCode){
      setMessage({ show:true, variant:"danger", message:"Must input new code" })
      return
    } if (newCode === code){
      setMessage({ show:true, variant:"danger", message:"New code must be different from original", deleteMembers:true })
      return
    }
    
    
    await updateAccessCode(newCode, user.General_Registration.Name, code )
    setCode(newCode)
    setMessage({ show:true, variant:"success", message:"Successfully Updated" })
  }
  
  const handleToggleSLCReg = async () => {
    setMessage({ show:true, variant:"warning", message:"Loading" })
    
    if (document.getElementById("turnOnSLC").checked){
      await updateSLCRegStatus(true)
      setIsEnabled(true)
      setMessage({ show:true, variant:"success", message:"Updated" })
    } else {
      await updateSLCRegStatus(false)
      setIsEnabled(false)
      setMessage({ show:true, variant:"success", message:"Updated" })
    }
  }

  return (
    <div class="admin m-3">
      {  message.show ? <Alert variant={message.variant}>{message.message}</Alert> : <></>}
      <div class="general-chapter-control bg-light rounded m-3 p-3">
        <h2 class="text-center">General Chapter Management</h2>
        <div class="links">
          <Link class="btn btn-primary ms-2" to="view-gen-roster">View Roster</Link>
          <Link class="btn btn-primary ms-2" to="auth-level-change">User Auth Level</Link>
          <Link class="btn btn-primary ms-2" to="general-permission-forms">Permission Forms</Link>
          <Link class="btn btn-primary ms-2" to="general-dues-status">Chapter Dues</Link>
          <Link class="btn btn-primary ms-2" to="uuids">UUID Table</Link>
        </div>
      </div>

      <div class="dlc-control bg-light rounded m-3 p-3">
        <h2 class="text-center">DLC Admin</h2>
        <div class="links">
          <Link class="btn btn-dark" to="upload-dlc-rating-sheets">Upload Rating Sheets</Link>
          <Link class="btn btn-dark ms-2" to="input-dlc-event-placement">Input Event Placement</Link>
          <Link class="btn btn-dark ms-2" to="reset-member-dlc-registration">Reset Member Districts Registration</Link>
        </div>
      </div>

      <div class="slc-control bg-light rounded m-3 p-3">
        <h2 class="text-center">SLC Admin</h2>
        <p class="fst-italic ms-2">This will activate General, Event, and Rooming registration for State</p>
        <div class="form-check form-switch ms-2 mb-4">
          {isEnabled.Enabled ? <input class="form-check-input" type="checkbox" id="turnOnSLC" onClick={() => handleToggleSLCReg()} checked/> : <input class="form-check-input" type="checkbox" id="turnOnSLC" onClick={() => handleToggleSLCReg()}/>}
          <label class="form-check-label" for="turnOn">SLC Rgistration Active</label>
        </div>
        <div class="links">
          <Link class="btn btn-secondary ms-2" to="master-list">Master List</Link>
          <Link class="btn btn-secondary ms-2" to="view-committment-responses">View State Committment Responses</Link>
          <Link class="btn btn-secondary ms-2" to="state-permission-forms">State Permission Forms</Link>
          <Link class="btn btn-secondary ms-2" to="slc-gen-reg">General Registration Items</Link>
          <Link class="btn btn-secondary ms-2" to="rooming">Rooming</Link>
        </div>
      </div>
      <div class="sub-page-container bg-light rounded m-3 p-3">
        <Outlet />
      </div>
      {/* <div class="nlc-control bg-light rounded m-3 p-3">
        <h2 class="text-center">NLC Admin</h2>
        <p class="fst-italic">Not live yet</p>
      </div> */}
      <div class="access-code-reset bg-light rounded m-3 p-3">
        <h3 class="text-center">Manage Access Code</h3>
        <div class="current-code">
          <h4>Current Code</h4>
          {
            code ? <p>{ code }</p> : <>Loading...</>
          }
        </div>
        <div class="change">
          <h4>Change Registration Code</h4>
          <input class="form-control w-50 mt-3" id="new-access-code" placeholder="New Access Code" required/>
          <button onClick={handleChangeCode} class="btn btn-primary mt-3">Update</button>
        </div>
      </div>
      <div class="delete-users bg-light rounded m-3 p-3">

        <h3 class="text-center">Delete Members From System</h3>
        <button class="btn btn-danger m-3" onClick={handleDeleteMembers}>Delete Selected Member(s)</button>
        <div class="member-list overflow-auto" >
          {
            userList.map((user, i) => {
              if (user.General_Registration){
                return(
                  <div class="member m-2">
                    <input class="form-check-input ms-3" type="checkbox" name={`member-${i}`} id={`member-${i}`} value={user.UUID} />
                    <label class="form-check-label ms-2" for={`member-${i}`}>{user.General_Registration.Name}</label>
                  </div>
                )
              } else {
                return(
                  <div class="member m-2">
                    <input class="form-check-input ms-3" name={`member-${i}`} type="checkbox" id={`member-${i}`} value={user.UUID} />
                    <label class="form-check-label ms-2" for={`member-${i}`}>{user.Email}</label>
                  </div>
                )
              }
            })
          }
        </div>
       
      </div>

      <div class="explain m-5">
        <p class="text-center fw-bold">Future Page Functions</p>
        <p class="text-center">Purge members from the system</p>
        <p class="text-center">Delete fields from their database file</p>
        <p class="text-center">Reset member passwords</p>
      </div>
    </div>
  )
}
