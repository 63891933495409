import React, { useState } from 'react'
import { updateUser, getUser } from '../../../../Firebase/Users-CRUD'
import { Alert } from "react-bootstrap"

export default function InputEventPlacement({ data }) {
    const [member, setMember] = useState(data)
    const [message, setMessage] = useState({show:false})



    const handleEventOnePlacement = async () => {
        setMessage({
            show: true,
            variant:"warning",
            message: "Loading"
        })
        const place = document.getElementById(`placement-1-${member.UUID}`).value
        if (place === ""){
            setMessage({
                show: true,
                variant:"danger",
                message: "Please Select a Place"
             })
            return
        }
        await updateUser(member.UUID, {"Districts_Registration.Event_One.Place": place})
        setMessage({
            show: true,
            variant:"success", 
            message:"Updated"
        })

        const temp = await getUser(member.UUID)
        setMember(temp)
    }
    const handleEventTwoPlacement = async () => {
        setMessage({
            show: true,
            variant:"warning",
            message: "Loading"
        })
        const place = document.getElementById(`placement-2-${member.UUID}`).value
        if (place === ""){
            setMessage({
                show: true,
                variant:"danger",
                message: "Please Select a Place"
             })
            return
        }
        await updateUser(member.UUID, {"Districts_Registration.Event_Two.Place": place})
        setMessage({
            show: true,
            variant:"success", 
            message:"Updated"
        })

        const temp = await getUser(member.UUID)
        setMember(temp)
    }
    
    const handleOneUnplaced = async () => {
        setMessage({
            show: true,
            variant:"warning",
            message: "Loading"
        })
        await updateUser(member.UUID, {"Districts_Registration.Event_One.Place": "Unplaced"})
        setMessage({
            show: true,
            variant:"success", 
            message:"Updated"
        })
    
        const temp = await getUser(member.UUID)
        setMember(temp)
    }
    const handleTwoUnplaced = async () => {
        setMessage({
            show: true,
            variant:"warning",
            message: "Loading"
        })
        await updateUser(member.UUID, {"Districts_Registration.Event_Two.Place": "Unplaced"})
        setMessage({
            show: true,
            variant:"success", 
            message:"Updated"
        })
    
        const temp = await getUser(member.UUID)
        setMember(temp)
    }

  return (
    <div class="container border border-2 rounded mt-3">
        <div class="row justify-content-md-center">
            <div class="col-4 align-self-center">
                <p>{ member.General_Registration ? <>{member.General_Registration.Name}</> : <>{member.Email}</>}</p>
            </div>

            {
                member.Districts_Registration ?  
                    <div class="col">
                        <div class="row">
                            <div class="col-4 align-self-center">
                                <h5>{ member.Districts_Registration.Event_One.Name }</h5>
                                {
                                    member.Districts_Registration.Event_One.Team_Members ? 
                                        <div>
                                            <label>Team Members</label>
                                            <ol>
                                                {
                                                    member.Districts_Registration.Event_One.Team_Members.map((teammate) =>{
                                                        return <li>{ teammate }</li>
                                                    })
                                                }
                                            </ol>
                                        </div>:<></>
                                }
                            </div>
                            <div class="col align-self-center">
                                {
                                    member.Districts_Registration.Event_One.Place ? 
                                    <div class="mt-3 ms-4">
                                        <h5 >Place: {member.Districts_Registration.Event_One.Place}</h5>
                                        <input type="text" class="form-control" placeholder="1, 2, 3, 4" id={`placement-1-${member.UUID}`} />
                                        <button class="btn btn-outline-secondary" onClick={handleEventOnePlacement} type="button" id="button-addon1">Update Placement</button>
                                    </div> : 
                                    <div class="row">
                                        <div class="col input-group m-3">
                                            <label class="m-3">Place: </label>
                                            <input type="text" class="form-control" placeholder="1, 2, 3, 4" id={`placement-1-${member.UUID}`} />
                                            <button class="btn btn-outline-secondary" onClick={handleEventOnePlacement} type="button" id="button-addon1">Submit</button>

                                        </div>
                                        <div class="col align-self-center">
                                            <label class="">OR</label>
                                            <button class="btn btn-danger ms-3" onClick={handleOneUnplaced}>Unplaced</button>
                                            
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            member.Districts_Registration.Event_Two && member.Districts_Registration.Event_Two.Name  ? 
                                <div class="row mt-2">
                                    <div class="col-4 align-self-center">
                                        <h5>{ member.Districts_Registration.Event_Two.Name }</h5>
                                        {
                                            member.Districts_Registration.Event_Two.Team_Members ? 
                                                <div>
                                                    <label>Team Members</label>
                                                    <ol>
                                                        {
                                                            member.Districts_Registration.Event_Two.Team_Members.map((teammate) =>{
                                                                return <li>{ teammate }</li>
                                                            })
                                                        }
                                                    </ol>
                                                </div>:<></>
                                        }
                                    </div>
                                    <div class="col align-self-center">
                                        {
                                            member.Districts_Registration.Event_Two.Place ? 
                                            <div class="mt-3 ms-4">
                                                <h5 >Place: {member.Districts_Registration.Event_Two.Place}</h5>
                                                <input type="text" class="form-control" placeholder="1, 2, 3, 4" id={`placement-2-${member.UUID}`} />
                                                <button class="btn btn-outline-secondary" onClick={handleEventTwoPlacement} type="button" id="button-addon1">Update Placement</button>
                                            </div>: 
                                            <div class="row">
                                                <div class="col input-group m-3">
                                                    <label class="m-3">Place: </label>
                                                    <input type="text" class="form-control" placeholder="1, 2, 3, 4" id={`placement-2-${member.UUID}`} />
                                                    <button class="btn btn-outline-secondary" onClick={handleEventTwoPlacement} type="button" id="button-addon1">Submit</button>
        
                                                </div>
                                                <div class="col align-self-center">
                                                    <label class="">OR</label>
                                                    <button class="btn btn-danger ms-3" onClick={handleTwoUnplaced}>Unplaced</button>
                                                    
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                : 
                                <></>
                        }
                    </div>
                    : 
                    <div class="col align-self-center">DLC REGISTRATION NOT COMPLETE</div>
            }
            
            { message.show && <Alert variant={message.variant}>{ message.message }</Alert> }
        </div>
    </div>
  )
}
