import React, { useState } from 'react'
import { resetDLCRegistration } from '../../Firebase/Firestore-Query'
import { getUser } from '../../Firebase/Users-CRUD'

export default function DistrictsEventsView({ data, member }) {
    const [reset, setReset] = useState(false);
    const [registrationData, setRegistrationData] = useState(data);

    const handleResetRegistration = () => {
        if (window.confirm("Are you sure you want to reset the registration? This will reset all teamate's registration as well.")) {
            resetDLCRegistration(member);
            window.alert("Registration reset successfully! Changes will not appear until refreshed.");
            setReset(!reset);
            getUser(member.UUID).then((user) => {
                setRegistrationData(user.DLC_Registration);
            })
        }
    }
    if (!registrationData) {
        return (
            <>
                <p>Not Begun</p>
            </>
        )
    } else if (!registrationData.Event_Two){
        return (
            <>
                <div>
                    <label>Event #1: {registrationData.Event_One.Name} </label>
                    <ul>
                        {
                            registrationData.Event_One?.Team_Members ? 
                            <>
                                <label>Team Members: </label>
                                {
                                    registrationData.Event_One.Team_Members.map(member => {
                                        return <li>{ member }</li>
                                    })
                                }
                            </> : <></>
                        }
                    </ul>
                    { registrationData.Event_One?.TeamCaptain && <label>Team Captain: {registrationData.Event_One.TeamCaptain}</label> }
                </div>
                <button class="btn btn-danger mt-2" onClick={handleResetRegistration}>Reset Registration</button>
            </>
        )
    } else if (registrationData.Event_One) {
        return (
            <>
                <div>
                    <label>Event #1: {registrationData.Event_One.Name} </label>
                    <ul>
                        {
                            registrationData.Event_One?.Team_Members ? 
                            <>
                                <label>Team Members: </label>
                                {
                                    registrationData.Event_One.Team_Members.map(member => {
                                        return <li>{ member }</li>
                                    })
                                }
                            </> : <></>
                        }
                    </ul>
                    { registrationData.Event_One?.TeamCaptain && <label>Team Captain: {registrationData.Event_One.TeamCaptain}</label> }
                </div>
                <div>
                    <label>Event #2: {registrationData.Event_Two?.Name ? <>{registrationData.Event_Two.Name}</> : <>None</>} </label>
                    <ul>
                        {
                            registrationData.Event_Two?.Team_Members ? 
                            <>
                                <label>Team Members: </label>
                                {
                                    registrationData.Event_Two.Team_Members.map(member => {
                                        return <li>{ member }</li>
                                    })
                                }
                            </> : <></>
                        }
                    </ul>
                    { registrationData.Event_Two?.TeamCaptain && <label>Team Captain: {registrationData.Event_Two.TeamCaptain}</label> }
                </div>
                <button class="btn btn-danger mt-2" onClick={handleResetRegistration}>Reset Registration</button>
            </>
        )
    } else {
        return (
            <>
                <p>error</p>
            </>
        )
    }
}
