import React, { useState, useEffect } from 'react'
import { getAllUsers } from "../../Firebase/Users-CRUD"
import Multiselect from "multiselect-react-dropdown"
import GeneralRegistrationColumn from '../../Components/Member Record Components/GeneralRegistrationColumn'
import PaymentViewColumn from '../../Components/Member Record Components/PaymentViewColumn'
import AuthLevelColumn from '../../Components/Member Record Components/AuthLevelColumn'
import PermissionFormView from '../../Components/Member Record Components/PermissionFormView'
import DistrictsEventsView from '../../Components/Member Record Components/DistrictsEventsView'

export default function ManageMembers() {
  const [options, setOptions] = useState([])
  const [members, setMembers] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [chapterStats, setChaptersStats] = useState({})
 

  useEffect(() => {
    async function loadUsers(){
      const existingFeilds = ["General_Registration"]
      let tempOptions = []
      const users = await getAllUsers()
      for(let i = 0; i < users.length; i++){
        Object.keys(users[i]).forEach(key => {
          if (!existingFeilds.includes(key) && !tempOptions.includes(key)){
            tempOptions.push(key)
          }
        })
      }
      let newList = []
      for (let i = 0; i < tempOptions.length; i++){

        const objToAdd = {
          name: tempOptions[i],
          id: i
        }
        newList.push(objToAdd)
      }

      calculateStats(users)

      setOptions(newList)
      setMembers(users)
    }
    loadUsers()
  },[])


  const calculateStats = (members) => {
    let objToPost = {}
    objToPost.Total = members.length

    objToPost.totalOff = 0;

    objToPost.totalFreshman = 0;
    objToPost.totalSophomores = 0;
    objToPost.totalJuniors = 0;
    objToPost.totalSeniors = 0;



    

    members.forEach((member, i) => {
      if (member.authLevel === "admin"){
        objToPost.totalOff++
      } 

      if (member.General_Registration){
        switch (member.General_Registration.Grade) {
          case "Freshman":
            objToPost.totalFreshman++
            break;
          case "Sophomore":
            objToPost.totalSophomores++
            break;
          case "Junior":
            objToPost.totalJuniors++
            break;
          case "Senior":
            objToPost.totalSeniors++
            break;
          default:
            break;
        }
      }
    })
    console.log(chapterStats)
    setChaptersStats(objToPost)
  }

  console.log(selectedData)

  return (
    <>
    <h2 class = "fw-bold text-center mb-4">MANAGE MEMBERS</h2>
    <div class="chapter-stats m-3 bg-light p-3 rounded rounded-3">
      <h3 class="fw-bold text-center mb-4">Chapter Statistics</h3>
      <div class="total">
        <h4 class="mb-2">Total Members: {chapterStats.Total} </h4>
        <p>Total Officers: {chapterStats.totalOff} </p>
        <label>Breakdown by Grade</label>
        <ul>
          <li>Freshman: {chapterStats.totalFreshman} </li>
          <li>Sohpomores: {chapterStats.totalSophomores} </li>
          <li>Juniors: {chapterStats.totalJuniors} </li>
          <li>Seniors: {chapterStats.totalSeniors} </li>
        </ul>
      </div>
    </div>
    <div class="m-3 bg-light p-3 rounded rounded-3">
      <div class="fs-4 fw-bold text-center">Select Data to View</div>
      <div class="mb-5">
        <Multiselect
          options={options}
          onSelect={(selectedList) => setSelectedData([...selectedList])}
          onRemove={(selectedList) => setSelectedData([...selectedList])}
          displayValue="name"
          />
      </div>
      
      


      <table class="table container">
        <tr>
            <th class="d-md-table-cell d-none">Name</th>
            <th class="d-md-table-cell d-none">General Registration</th>
            {
              selectedData.map((column, i) => {
                return (
                  <th class="d-md-table-cell d-none">{column.name}</th>
                )
              })
            }  
        </tr>
        <tbody>
        {
          members.map((user, index) => {
            if (user.Email === "NULL") return <></>
            else if (user.General_Registration){
              return <tr class="d-md-table-row">
                <td class="d-table-row d-md-table-cell fw-bold fs-5">{user.General_Registration.Name}</td>
                <td class="d-table-row d-md-none fw-bold">General Registration:</td>
                <td class="d-table-row d-md-table-cell"><GeneralRegistrationColumn data={user.General_Registration} Email={user.Email}/> </td>
                
                {
                  selectedData.map((column, i) => {
                    if (column.name === "authLevel"){
                      return (
                        <td class="d-table-row d-md-table-cell"> 
                          <AuthLevelColumn currentLevel={ user[column.name] } i={index} uuid={user.UUID} />
                        </td>
                      )
                    } else if (typeof user[column.name] === "string"){
                        return (
                          <td class="d-table-row d-md-table-cell">{user[column.name]}</td>
                        )
                    } else if (user[column.name]?.Amount){
                        return (
                          <td class="d-table-row d-md-table-cell"><PaymentViewColumn data={user[column.name]}  uuid={user.UUID} i={index} paymentName={column.name}/></td>
                        )
                    } else if (column.name === 'Forms'){
                      if (user.Forms.length === 0){
                        return  (
                          <td class="d-table-row d-md-table-cell text-left">
                            No Active Forms
                          </td>
                        )
                      } else {
                        return (
                          <td class="d-table-row d-md-table-cell">
                            <PermissionFormView data={user.Forms} />
                          </td>
                        )
                      }
                    } else if (column.name === "Districts_Registration"){
                      if (user.Districts_Registration){
                        return (
                          <td class="d-table-row d-md-table-cell">
                            <DistrictsEventsView data={user.Districts_Registration} member={user} />
                          </td>
                        )
                      } else {
                        return (
                          <td class="d-table-row d-md-table-cell text-left">
                            Not Begun
                          </td>
                        )
                      }
                    }
                  })
                }
              </tr>
            }
            else {
              return (
                <tr>
                  <td>{user.Email} </td>
                  <td>INCOMPLETE</td>
                </tr>
              )
            }
          })
        }
        </tbody>
      </table>
    </div>
    </>
  )
}
