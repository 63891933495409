import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { getAllUsers, updateUser, resetState } from '../../../../Firebase/Users-CRUD'

export default function ViewSLCCommittment() {

    const [members, setMembers] = useState([])
    const [fullCommit, setFullCommit] = useState(0)
    const [bumpUp, setBumpUp] = useState(0)
    const [stateEvents, setStateEvents] = useState(0)
    const [notCommit, setNotCommit] = useState(0)
    const [incomplete, setIncomplete] = useState(0)
    const [notBumped, setNotBumped] = useState(0)
    const [bumped, setBumped] = useState(0)

    const [loadingState, setLoadingState] = useState({show: false})

    useEffect(() => {
        async function loadUsers() {
            const users = await getAllUsers()
            setMembers(users)

            let fullCommit = 0
            let bumpUp = 0
            let stateEvents = 0
            let notCommit = 0
            let incomplete = 0

            let notBumped = 0
            let bumped = 0
    
            users.forEach(member =>  {
                if (member.State_Registration){
                    switch (member.State_Registration.Commitment.Choice){
                        case "QUAL_COMMIT":
                            fullCommit++
                            break
                        
                        case "COMMIT_ON_BUMP_UP":
                            bumpUp++
                            break
                        
                        case "STATE_EVENT_COMMIT":
                            stateEvents++
                            break
                        
                        case "NOT_COMMIT":
                            notCommit++
                            break
                        
                        case "BUMPED_UP":
                            bumped++
                            break
                        
                        case "NOT_BUMPED_UP":
                            notBumped++
                            break
                        
                        
                    }
                } else {
                    incomplete++
                } 
            })
    
            setFullCommit(fullCommit)
            setBumpUp(bumpUp)
            setStateEvents(stateEvents)
            setNotCommit(notCommit)
            setIncomplete(incomplete)
            setNotBumped(notBumped)
            setBumped(bumped)
        }

        loadUsers()
    }, [])

    const closeStateRegistration = async () => {
        setLoadingState({show: true, message: "Loading", variant: "warning"})
        
        try {     
            members.map( async (member) => {
                if (!member.State_Registration){
                    await updateUser(member.UUID, { State_Registration : { Commitment: { Choice: "NOT_COMMIT", SignedAt: `[REGISTRATION CLOSED. AUTOMATIC NOT_COMMIT @ ${Date()}]`} } })
                }
            })
    
            const users = await getAllUsers()
            setMembers(users)
    
            setLoadingState({show: true, message: "Updated", variant: "success"})
        } catch (error) {
            setLoadingState({show: true, message: `${error}`, variant: "danger"})
        }
    }
    
    const resetStateCommitment = async (uuid) => {
        setLoadingState({show: true, message: "Loading", variant: "warning"})
        try {     
            await resetState(uuid)

            const users = await getAllUsers()
            setMembers(users)

            //console.log(uuid)
    
            setLoadingState({show: true, message: "Updated", variant: "success"})
        } catch (error) {
            setLoadingState({show: true, message: `${error}`, variant: "danger"})
        }
        
    }
    const notBumpedUp = async (uuid) => {
        setLoadingState({show: true, message: "Loading", variant: "warning"})
        try {     
            await updateUser(uuid, {State_Registration: {Commitment: {Choice: "NOT_BUMPED_UP", SignedAt: `Signed By Admin @ ${Date()}`}}})

            const users = await getAllUsers()
            setMembers(users)

            //console.log(uuid)
    
            setLoadingState({show: true, message: "Updated", variant: "success"})
        } catch (error) {
            setLoadingState({show: true, message: `${error}`, variant: "danger"})
        }
        
    }

    const bumpedUp = async (uuid) => {
        setLoadingState({show: true, message: "Loading", variant: "warning"})
        if (document.getElementById(`event-select-bump-up-${uuid}`).value === "none"){
            window.alert("Select an event for Bump Ups")
            return
        }
        try {     
            await updateUser(uuid, {State_Registration: {Commitment: {Choice: "BUMPED_UP", Event_Name: `${document.getElementById(`event-select-bump-up-${uuid}`).value}`, SignedAt: `Signed By Admin @ ${Date()}`}}})

            const users = await getAllUsers()
            setMembers(users)

            //console.log(uuid)
    
            setLoadingState({show: true, message: "Updated", variant: "success"})
        } catch (error) {
            setLoadingState({show: true, message: `${error}`, variant: "danger"})
        }
        
    }
    
    return (
    <div>
      <h3 class="text-center">SLC Committment Responses</h3>
      <div class="stats ms-3">
        <h4 class="mb-3">Overall Numbers</h4>
        <p class="fst-italic">Qualified & Committed Members: {fullCommit} </p>
        <p class="fst-italic">Committed on Bump Up Members: {bumpUp} </p>
        <p class="fst-italic">State Events Members: {stateEvents} </p>
        <p class="fst-italic">Memebers Bumped Up: {bumped} </p>
        <p class="fst-italic">Members Failed to Bump Up: {notBumped} </p>
        <p class="fst-italic">Not Committed Members: {notCommit} </p>
        <p class="fst-italic">Members Pending Response: {incomplete} </p>
      </div>

      <div class="button-class m-3 mt-4 mb-4">
        <button class="btn btn-danger" onClick={closeStateRegistration}>Close State Registration for Incomplete Members</button>
        <p class="fst-italic mt-3">This action will make all "incomplete" members NOT_COMMIT for State</p>
      </div>
      <div class="alert-box ms-3">
        { loadingState.show && <Alert variant={`${loadingState.variant}`} >{loadingState.message}</Alert>}
      </div>
      <table class="table">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">SLC Form Response</th>
                <th scope="col">SLC Event</th>
                <th scope="col">Timestamp</th>
                <th scope="col">Options</th>
            </tr>
        </thead>
        <tbody>
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && member.State_Registration.Commitment.Choice === "QUAL_COMMIT"){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>{member.State_Registration.Commitment.Choice}</td>
                            <td>{member.State_Registration.Commitment.Event_Name}</td>
                            <td>{member.State_Registration.Commitment.SignedAt}</td>
                            <td>
                                <button class="btn btn-danger" onClick={() => resetStateCommitment(member.UUID)}>Reset State Registration</button>
                            </td>
                        </tr>                         
                    }
                })
            }
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && member.State_Registration.Commitment.Choice === "COMMIT_ON_BUMP_UP"){
                        
                            return <tr>
                                <td>{member.General_Registration.Name}</td>
                                <td>{member.State_Registration.Commitment.Choice}</td>
                                <td>{member.State_Registration.Commitment.Event_Name}</td>
                                <td>{member.State_Registration.Commitment.SignedAt}</td>
                                <td>
                                    <div class="button-1">
                                        <button class="btn btn-danger" onClick={() => resetStateCommitment(member.UUID)}>Reset State Registration</button>
                                    </div>
                                    <div class="button-2">
                                        <button class="btn btn-secondary mt-2" onClick={() => notBumpedUp(member.UUID)}>NOT BUMPED UP</button>
                                    </div>

                                    <div class="bump-up-options">
                                        <button class="btn btn-primary mt-2" onClick={() => bumpedUp(member.UUID)}>BUMPED UP</button>
                                        <select class="form-select mt-3" name="events" id={`event-select-bump-up-${member.UUID}`}>
                                            <option value="none">Select Event</option>
                                            {
                                                member.Districts_Registration && member.Districts_Registration.Event_One.Name ?  <option value={`${member.Districts_Registration.Event_One.Name}`}>{member.Districts_Registration.Event_One.Name}</option> : <></>
                                            }
                                            {
                                                member.Districts_Registration && member.Districts_Registration.Event_Two ?  <option value={`${member.Districts_Registration.Event_Two.Name}`}>{member.Districts_Registration.Event_Two.Name}</option> : <></>
                                            }
                                        </select>
                                    </div>
                                </td>
                            </tr>
                    } 
                })
            }
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && member.State_Registration.Commitment.Choice === "STATE_EVENT_COMMIT"){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>{member.State_Registration.Commitment.Choice}</td>
                            <td>{member.State_Registration.Commitment.Event_Name}</td>
                            <td>{member.State_Registration.Commitment.SignedAt}</td>
                            <td>
                                <button class="btn btn-danger" onClick={() => resetStateCommitment(member.UUID)}>Reset State Registration</button>
                            </td>
                        </tr>                         
                    }
                })
            }
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && member.State_Registration.Commitment.Choice === "BUMPED_UP"){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>{member.State_Registration.Commitment.Choice}</td>
                            <td>{member.State_Registration.Commitment.Event_Name}</td>
                            <td>{member.State_Registration.Commitment.SignedAt}</td>
                            <td>
                                <button class="btn btn-danger" onClick={() => resetStateCommitment(member.UUID)}>Reset State Registration</button>
                            </td>
                        </tr>                         
                    }
                })
            }
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && member.State_Registration.Commitment.Choice === "NOT_BUMPED_UP"){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>{member.State_Registration.Commitment.Choice}</td>
                            <td>{member.State_Registration.Commitment.Event_Name}</td>
                            <td>{member.State_Registration.Commitment.SignedAt}</td>
                            <td>
                                <button class="btn btn-danger" onClick={() => resetStateCommitment(member.UUID)}>Reset State Registration</button>
                            </td>
                        </tr>                         
                    }
                })
            }
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && member.State_Registration.Commitment.Choice === "NOT_COMMIT"){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>{member.State_Registration.Commitment.Choice}</td>
                            <td>{member.State_Registration.Commitment.Event_Name}</td>
                            <td>{member.State_Registration.Commitment.SignedAt}</td>
                            <td>
                                <button class="btn btn-danger" onClick={() => resetStateCommitment(member.UUID)}>Reset State Registration</button>
                            </td>
                        </tr>                         
                    }
                })
            }
            {
                members.map(member => {
                    if (member.General_Registration && !member.State_Registration){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>INCOMPLETE</td>
                            <td>N/A</td>
                            <td>N/A</td>
                        </tr>                         
                    }
                })
            }
        </tbody>
      </table>
    </div>
  )
}
