import React, { useState, useEffect } from 'react'
import { getAllUsers } from '../../../../Firebase/Users-CRUD'
import ResetDLCRegistrationMember from "../Components/ResetDLCRegistrationMember"

export default function ResetDLCRegistration() {


    const [members, setMembers] = useState([])


    useEffect(() => {
        async function loadMembers() {
            const temp = await getAllUsers()
            setMembers(temp)
        }
        loadMembers()
    },[])
    
 

    return (
        <div class='page-container mt-3'>
            <h3 class="text-center">Reset Districts Registration</h3>
            {
                members.map((member) => {
                    return <ResetDLCRegistrationMember data={member}  />
                })
            }
        </div>
    )
}
