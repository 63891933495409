import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { getAllUsers, updateUser, getUser, resetSLCGeneral } from '../../../../Firebase/Users-CRUD'

export default function View_Roster() {

    const [members, setMembers] = useState([])
    const [loadingState, setLoadingState] = useState({show: false})
    const [statePayments, setStatePayments] = useState([])

    useEffect(() => {

        async function loadUsers() {

            const users = await getAllUsers()

            let temp = []

            users.map((user) => {
                temp.push(user)
            })
            setMembers(temp)

        }
        loadUsers()
    }, [])


  return (
    <div>
      <h3 class="text-center">Grandview FBLA Roster</h3>
      { loadingState.show && <Alert variant={`${loadingState.variant}`} >{loadingState.message}</Alert>}
      <table class="table">
        <thead>
            <tr>
                <th class="text-center" scope="col">Name</th>
                <th class="text-center" scope="col">Grade</th>
                <th class="text-center" scope="col">Email</th>
                <th class="text-center" scope="col">Phone Number</th>
                <th class="text-center" scope="col">T-Shirt Size</th>
                <th class="text-center" scope="col">Reset Registration</th>
            </tr>
        </thead>
        <tbody>
            {
                members.map(member => {
                    if (member.General_Registration)
                    return <tr>
                        <td class="text-center">{member.General_Registration.Name}</td>
                        <td class="text-center">{member.General_Registration.Grade}</td>
                        <td class="text-center">{member.Email}</td>
                        <td class="text-center">{member.General_Registration.Phone_Number}</td>
                        <td class="text-center">{member.General_Registration.T_Shirt_Size}</td>
                        <td class="text-center"><button class="btn btn-danger" onClick={() => null}>Reset</button></td>
                    </tr>
                })
            }
            {
                members.map(member => {
                    if (!member.General_Registration)
                    return <tr>
                        <td class="text-center">GEN-REG INCOMPLETE</td>
                        <td class="text-center">GEN-REG INCOMPLETE</td>
                        <td class="text-center">{member.Email}</td>
                        <td class="text-center">GEN-REG INCOMPLETE</td>
                        <td class="text-center">GEN-REG INCOMPLETE</td>
                        <td class="text-center"><button class="btn btn-danger " onClick={() => null}>Reset</button></td>
                    </tr>
                })
            }
            
        </tbody>
      </table>
    </div>

  )
}
