import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { getAllUsers, updateUser, getUser, resetSLCGeneral } from '../../../../Firebase/Users-CRUD'

export default function SLC_Gen_Reg() {
    
    const [members, setMembers] = useState([])
    const [loadingState, setLoadingState] = useState({show: false})

    useEffect(() => {
        async function loadUsers() {
            const users = await getAllUsers()

            let temp = []

            users.map((user) => {
                if (user.State_Registration && (user.State_Registration.Commitment.Choice !== "NOT_COMMIT" && user.State_Registration.Commitment.Choice !== "NOT_BUMPED_UP")){
                    temp.push(user)
                }
            })
            temp.sort((a, b) => (a.UUID > b.UUID) ? 1 : -1)
            setMembers(temp)
        }
        loadUsers()
    }, [])

    const handleReset = async (uuid) => {

        setLoadingState({show: true, message: "Loading", variant: "warning"})

        try {     
            await resetSLCGeneral(uuid)
            const users = await getAllUsers()
            let temp = []
            users.map((user) => {
                if (user.State_Registration && (user.State_Registration.Commitment.Choice !== "NOT_COMMIT" && user.State_Registration.Commitment.Choice !== "NOT_BUMPED_UP")){
                    temp.push(user)
                }
            })
            setMembers(temp)
                
            setLoadingState({show: true, message: "Updated", variant: "success"})
        } catch (error) {
            setLoadingState({show: true, message: `${error}`, variant: "danger"})
        }

    }


  return (
    <div>
      <h3 class="text-center">General Registration Items</h3>
      { loadingState.show && <Alert variant={`${loadingState.variant}`} >{loadingState.message}</Alert>}
      <table class="table">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Items</th>
                <th scope="col">Reset</th>
                <th scope="col">Timestamp</th>
            </tr>
        </thead>
        <tbody>
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && !member.State_Registration.General_SLC_Registration){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>Incomplete</td>
                            <td>N/A</td>
                            <td>N/A</td>
                        </tr>
                    }
                })
            }
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && member.State_Registration.General_SLC_Registration){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>
                                <ul>
                                    <li>Wednesday Lunch: {member.State_Registration.General_SLC_Registration.WednesdayLunch}</li>
                                    <li>Thursday Lunch: {member.State_Registration.General_SLC_Registration.ThursdayLunch}</li>
                                    <li>Guest Passes: {member.State_Registration.General_SLC_Registration.Guest_Pass}</li>
                                    {member.State_Registration.General_SLC_Registration.Hypnotist && <li>Hypnotist</li>}
                                    {member.State_Registration.General_SLC_Registration.Trivia_Night && <li>Trivia Night</li>}
                                    <li>Additional Charge: ${member.State_Registration.General_SLC_Registration.AddedCharge}</li>
                                </ul>
                            </td>
                            <td>
                                {member.State_Registration.General_SLC_Registration.TimeStamp}
                            </td>
                            <td>
                                <button class="btn btn-danger" onClick={() => handleReset(member.UUID)}>Reset</button>
                            </td>
                        </tr>
                    }
                })
            }
            
        </tbody>
      </table>
    </div>
  )
}
