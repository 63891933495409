import React from 'react'

export default function GeneralRegistrationColumn({ data, Email }) {
  return (
    <ul> 
        {
            Object.keys(data).map(key => {
                if (key !== "Name")
                return (
                    <li>
                        {key}: {data[key]}
                    </li>
                )
            })
            
        }
        <li>
            Email: {Email}
        </li>
    </ul>
  )
}
