import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { resetDLCRegistration } from '../../../../Firebase/Firestore-Query'
import { getUser } from '../../../../Firebase/Users-CRUD'
export default function ResetDLCRegistrationMember({ data, resetMembers }) {
    const [member, setMember] = useState(data)
    const [message, setMessage] = useState({ show:false })


    const handleDLCReset = async () => {
        setMessage({ show:true, variant:"warning", message:"Loading" })
        
        await resetDLCRegistration(member)
        const temp = await getUser(member.UUID)
        setMember(temp)
        setMessage({ show:true, variant:"success", message:"Reset" })
        
    }


  return (
    <div class="container border border-2 rounded mt-3">
        <div class="row justify-content-md-center">
            <div class="col-4 align-self-center">
                <p>{ member.General_Registration ? <>{member.General_Registration.Name}</> : <>{member.Email}</>}</p>
            </div>

            {
                member.Districts_Registration ?  
                    <div class="col-5">
                        <div class="row">
                            <div class="col align-self-center">
                                <h5>{ member.Districts_Registration.Event_One.Name }</h5>
                                {
                                    member.Districts_Registration.Event_One.Team_Members ? 
                                        <div>
                                            <label>Team Members</label>
                                            <ol>
                                                {
                                                    member.Districts_Registration.Event_One.Team_Members.map((teammate) =>{
                                                        return <li>{ teammate }</li>
                                                    })
                                                }
                                            </ol>
                                            <label class="fst-italic">Note: Resetting a teamed event will reset ENTIRE DLC registration for ALL team members</label>
                                        </div>:<></>
                                }
                            </div>
                            
                        </div>
                        {
                            member.Districts_Registration.Event_Two && member.Districts_Registration.Event_Two.Name  ? 
                            <div class="row mt-2">
                                    <div class="col align-self-center">
                                        <h5>{ member.Districts_Registration.Event_Two.Name }</h5>
                                        {
                                            member.Districts_Registration.Event_Two.Team_Members ? 
                                                <div>
                                                    <label>Team Members</label>
                                                    <ol>
                                                        {
                                                            member.Districts_Registration.Event_Two.Team_Members.map((teammate) =>{
                                                                return <li>{ teammate }</li>
                                                            })
                                                        }
                                                    </ol>
                                                    <label class="fst-italic">Note: Resetting a teamed event will reset ENTIRE DLC registration for ALL team members</label>
                                                </div>:<></>
                                        }
                                    </div>
                                    
                                </div>
                                : 
                                <></>
                        }
                    </div>
                    : 
                    <div class="col align-self-center">DLC REGISTRATION NOT COMPLETE</div>
            }
            {
                member.Districts_Registration ? 
                <div class="col align-self-center ">
                    <button class="btn btn-danger mt-3 mb-3" onClick={handleDLCReset}>Reset DLC Registration</button>
                </div>
                :
                <></>
            }
            
            { message.show && <Alert variant={message.variant}>{ message.message }</Alert> }
        </div>
    </div>

  )
}
