import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { getAllUsers, updateUser, getUser } from '../../../../Firebase/Users-CRUD'

export default function StatePermissionForms() {

    const [members, setMembers] = useState([])
    const [loadingState, setLoadingState] = useState({show: false})

    useEffect(() => {
        async function loadUsers() {
            const users = await getAllUsers()

            let temp = []
            
            users.map((user) => {
                if (user.State_Registration && (user.State_Registration.Commitment.Choice !== "NOT_COMMIT" && user.State_Registration.Commitment.Choice !== "NOT_BUMPED_UP")){
                    temp.push(user)
                }
            })
            temp.sort((a, b) => (a.UUID > b.UUID) ? 1 : -1)
            setMembers(temp)
        }
        loadUsers()
    }, [])

    const completeForm = async (uuid) => {
        setLoadingState({show: true, message: "Loading", variant: "warning"})
        
        try {
            const member = await getUser(uuid)
            await updateUser(uuid, {State_Registration: {...member.State_Registration, CommitmentForm: { Status:"Complete", SignedAt: `${Date()} by Admin` }} })
            
            let temp = []
            let users = await getAllUsers()
            users.map((user) => {
                if (user.State_Registration && (user.State_Registration.Commitment.Choice !== "NOT_COMMIT" && user.State_Registration.Commitment.Choice !== "NOT_BUMPED_UP")){
                    temp.push(user)
                }
            })
            
            setMembers(temp)

            //console.log(uuid)
    
            setLoadingState({show: true, message: "Updated", variant: "success"})
        } catch (error) {
            setLoadingState({show: true, message: `${error}`, variant: "danger"})
        }
    }

  return (
    <div>
      <h3 class="text-center">SLC Forms by Member</h3>
      { loadingState.show && <Alert variant={`${loadingState.variant}`} >{loadingState.message}</Alert>}
      <table class="table">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Commitment</th>
                <th scope="col">SLC Paper Form</th>
                <th scope="col">Input</th>
            </tr>
        </thead>
        <tbody>
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && !member.State_Registration.CommitmentForm){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>{member.State_Registration.Commitment.Choice}</td>
                            <td>Not Turned In</td>
                            
                            <td><button class="btn btn-primary" onClick={() => completeForm(member.UUID) }>Form Complete: Verify</button></td>
                        </tr>
                    }
                })
            }
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && member.State_Registration.CommitmentForm && member.State_Registration.CommitmentForm.Status === "Complete"){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>{member.State_Registration.Commitment.Choice}</td>
                            <td>Turned in. Signed off @ {member.State_Registration.CommitmentForm.SignedAt} </td>
                            
                            <td><button class="btn btn-success" disabled onClick={() => completeForm(member.UUID) }>Verified</button></td>
                        </tr>
                    }
                })
            }
        </tbody>
      </table>
    </div>
  )
}
