import React, { useEffect, useState } from 'react'
import { useAuth, shallowEqual } from "../../AuthContext"
import { getUser } from "../../Firebase/Users-CRUD"
import RegisterEvent from "../../Components/RegisterEvent"
import SLC_Registration from './SLC_Registration'
import RegisterSecondEvent from '../../Components/RegisterSecondEvent'
import { Alert } from 'react-bootstrap'
import {  getAllEvents, getOneEvent } from '../../Firebase/Firestore-Query'


export default function EventView() {

    const [ events, setEvents ] = useState([])
    const [ event, setEvent ] = useState(null)
    useEffect(() => { 
        async function loadEvents() {
            const temp_events = await getAllEvents()
            setEvents(temp_events)
        }
        loadEvents()
    }, [])
    


    const handleEventViewSelect = async () => {
        const event_name = document.getElementById("event-select").value
        const temp_event = await getOneEvent(event_name)
        setEvent(temp_event)
    }





    return (
        <div class="text-center m-3">
        
            <h1>Event View</h1>
            <label class="mb-2 col-12 fw-bold text-center">Select Event</label>
            <div class="row select mb-5 justify-content-center"> 
                <select class="form-select w-50 mb-5 text-center" id="event-select" onChange={handleEventViewSelect}>
                {events.map((event, i) => {
                return event ? <option key={i} value={event.Name}>{event.Name}</option> : null
                }) }
                
                </select>
            </div>
            {
                event ?
                <>
                    <div class="row justify-content-center">
                        <h3 class="mb-5">{event ? event.Name : null}</h3>
                        <p class="mb-5">Event Type: {event ? event.Event_Type : null}</p>
                    </div>
                    <div class="row justify-content-center">
                        <h3 class="mb-5">Event Resources</h3>
                        <ul>
                            {console.log(event)}
                            {
                                event && event.Resources && Object.entries(event.Resources).map(([key, value]) => {
                                console.log(value, key, value.Name, value.Link)
                                return <li key={key}><a href='{value}' >{key}</a></li>
                                })
                            }
                        </ul>
                        <h3 class="mb-5">Competitive Event Guide</h3>
                        <script src="https://unpkg.com/@ungap/custom-elements-builtin"></script>
                        <script type="module" src="https://unpkg.com/x-frame-bypass"></script>
                        {event.CE_Link ? <p><a href={event.CE_Link}>View Competive Event Here</a></p> : "" }
                    </div>

                </> : null
            }
            
        </div>
    )
}
