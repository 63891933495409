import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../AuthContext"
import OfficerSubNavbar from "./OfficerSubNavbar"

import "./Navbar.css"

export default function MemberNavbar() {
  const { logout, userProfile } = useAuth()
  const navigate = useNavigate()
  const handleLogout = async () => {
    await logout()
    navigate("/login")
  }
  return (
    <div class="navbar-container container">
      <div class="justify-content-center row">
        <Link class="btn btn-primary col-sm-12 col-md-3 mx-3 my-2 py-2" to="memberProfile">Profile</Link>
        <Link class="btn btn-primary col-sm-12 col-md-3 mx-3 my-2 py-2" to="conference">Conferences</Link>
        <Link class="btn btn-primary col-sm-12 col-md-3 mx-3 my-2 py-2" to="eventView">Events</Link>
        <Link class="btn btn-primary col-sm-12 col-md-3 mx-3 my-2 py-2" to="officersView">Officers</Link>
        <button class="btn btn-secondary col-sm-12 col-md-3 mx-3 my-2 py-2" onClick={handleLogout}>Logout</button> 
        <Link class="btn btn-danger col-sm-12 col-md-3 mx-3 my-2 py-2" to="officers/manageMembers">Manage Chapter</Link>
        {
          userProfile && userProfile.authLevel ==="admin" ? <Link class="btn btn-danger col-sm-12 col-md-3 mx-3 my-2 py-2" to="admin">Admin</Link> : <></>
        }
      </div>
    </div>
  )
}
