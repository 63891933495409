import React, { useState } from 'react'
import { storage } from '../Firebase/Firebase-Config'
import { ref, uploadBytes } from "firebase/storage"
import { Alert } from "react-bootstrap"
import { updateUser } from "../Firebase/Users-CRUD"


export default function PermissionFormUpload({ form, user }) {
    const [file, setFile] = useState()
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false)

    const handleUpload = async () => {
        if (!file){
            const obj = {
                isBad: true,
                msg: "Please upload a file from your system first"
            }
            return setMessage(obj)
        } 
        const fileName = `${user.General_Registration.Name} - ${form.Name}`
        const imageRef = ref(storage, `${form.Name}/${fileName}`)
        
        try {
            setMessage("")
            setLoading(true)
            await uploadBytes(imageRef, file)
            
            let forms = user.Forms
            
            for (let i = 0; i < forms.length; i++){
                if (form.Name === forms[i].Name){
                    forms[i].Completed = true
                    break
                }
            }
    
            await updateUser(user.UUID, {Forms: forms})
            setLoading(false)
            setMessage({
                isBad: false,
                msg: "Uploaded"
            })
        } catch (error) {
            setMessage({
                isBad: true,
                msg: error
            })
        }
    }

  return (
    <div class="permission-form-upload-container">
      <h3 class="permission-form-name">{form.Name}</h3>
      <div class="link mt-2 mb-2">
        <a href={form.Link} target="_blank">Link to PDF of form</a><br></br>
      </div>
      {message && message.isBad && <Alert variant="danger">{message.msg}</Alert>}
      {message && !message.isBad && <Alert variant="success">{message.msg}</Alert>}
      <input id={`${form.Name}-upload`} type="file" onChange={(e) => setFile(e.target.files[0])}/>
      <button class="submit" onClick={handleUpload}>Upload</button>
      {
        loading ? <label>Loading...</label> : <></>
      }
    </div>
  )
}
