import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { updateUser } from '../../Firebase/Users-CRUD'
import { getAllEvents } from '../../Firebase/Firestore-Query'

export default function SLC_General_State_Items({ user }) {
    
    const [loading, setLoading] = useState({message:"", show:false})
    const navigate = useNavigate()
    const [eventHasTeam, setEventHasTeam] = useState({hasTeam: false})
    
    useEffect(() => {
        const checkEventForTeam = async () => {
            const eventList = await getAllEvents()
            
            eventList.forEach((event) => {
                if (event.Name === user.State_Registration.Commitment.Event_Name){
                    if (event.Max_Team_Members){
                        setEventHasTeam({hasTeam:true, maxTeam:[...Array(parseInt(event.Max_Team_Members))]})
                        
                    }
                }
            })
        }
        checkEventForTeam()
    }, [])
    
    const handleSubmit = async () => {
        setLoading({show:true, message:"Loading", type: "warning"})
        if (!eventHasTeam.hasTeam){
            const objToLoad = {
                State_Registration: {
                    SLC_Event : {
                        Event_Name: user.State_Registration.Commitment.Event_Name
                    },
                    ...user.State_Registration
                }
            }
            objToLoad.State_Registration.SLC_Event.TimeStamp = Date()
            try {
                await updateUser(user.UUID, objToLoad)
                setLoading({show:true, message:"Event Submited", type: "success"})
                navigate("/")
            } catch (error) {
                setLoading({show:true, message:error, type: "danger"})
                
            }
        } else {

            let teamMembers = []
            for (let i = 0; i < eventHasTeam.maxTeam.length; i++){
                teamMembers.push(document.getElementById(`teamMember-${i+1}`).value)
            }
            const objToLoad = {
                State_Registration: {
                    SLC_Event : {
                        Event_Name: user.State_Registration.Commitment.Event_Name,
                        Team_Members: teamMembers
                    },
                    ...user.State_Registration
                }
            }
            objToLoad.State_Registration.SLC_Event.TimeStamp = Date()
            try {
                await updateUser(user.UUID, objToLoad)
                setLoading({show:true, message:"Event Submited", type: "success"})
                navigate("/")
            } catch (error) {
                setLoading({show:true, message:error, type: "danger"})
                
            }

        }
    }






  return (
    <div class="general-slc-reg bg-light rounded p-3 mb-4 mt-4">
        <h4 class="gen-reg-slc-title text-center">Competitive Event Registration</h4>
        { loading.show && <Alert variant={`${loading.type}`}>{loading.message}</Alert> }
        <div class="event-title">
            <h5>Your Competitive Event: {user && user.State_Registration.Commitment.Event_Name}</h5>
            {
                eventHasTeam.hasTeam ? 
                <>
                    <p class="fst-italic mt-3">If you do not have team members, enter "None" in the fields below</p>
                    {                        
                        eventHasTeam.maxTeam.map((stuff, i) => {
                            return (
                                <div class="team-input">
                                    <input class="form-control w-25 mt-2" type="text" name={`teamMember-${i+1}`} id={`teamMember-${i+1}`} placeholder={`Team Member ${i+1}`}/>
                                </div>
                            )
                        })
                    }
                </>
                :
                <></>
            }
        </div>
        <div class="btn-submit">
            <button type="button" class="btn btn-primary mt-4" onClick={handleSubmit}>{eventHasTeam.hasTeam? <>Submit</> : <>Confirm</>}</button>
        </div>

    </div>
  )
}
