import React from 'react'
import { useAuth } from "../AuthContext"
export default function PrivateRoute({ children }) {
    const { userProfile } = useAuth()
    if (userProfile && (userProfile.authLevel === "officer" || userProfile.authLevel === "admin")) {
        return children
    } else {
        return <div>No Access!</div>
    }
}
