import React, { useEffect, useState } from 'react'
import { useAuth } from '../AuthContext'
import { getOneEvent, getAllEvents } from '../Firebase/Firestore-Query'
import { updateUser, getAllUsers, getUser } from '../Firebase/Users-CRUD'
import { Alert } from 'react-bootstrap'
import { firestore } from '../Firebase/Firebase-Config'
import { doc, setDoc } from "firebase/firestore"

export default function RegisterSecondEvent({ setOpenModal, checkUserFirestore }) {
    const { userProfile, mutateUserProfile, currentUser } = useAuth()
    const [eventOne, setEventOne] = useState(null)
    const [wantEventTwo, setWantEventTwo] = useState(null)
    const [listOfEvents, setListOfEvents] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [listOfTeamMembers, setListOfTeamMembers] = useState([])
    const [listOfMembers, setListOfMembers] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({show: false})




    useEffect(() => {
        async function getEvent() {
            const name = userProfile.Districts_Registration.Event_One.Name
            const tempEvent = await getOneEvent(name)
            const tempUsers = await getAllUsers()
            let newTempUsers = []
            for (let i = 0; i < tempUsers.length; i++) {
                if (userProfile.UUID !== tempUsers[i].UUID) {
                    newTempUsers.push(tempUsers[i])
                }
            }
            setListOfMembers(newTempUsers)
            setEventOne(tempEvent)
            const temp = await getAllEvents()
            setListOfEvents(temp)



        }   
        getEvent()
    }, [])

    const handleEventTypeSelect = () => {
        console.log("invoked")
        if (document.getElementById("yes-event-select").checked){
            setWantEventTwo({option:true})
        } else if(document.getElementById("no-event-select").checked) {
            setWantEventTwo({option:false})
        }
    }

    const handleNoSecondEvent = async () => {
        const objToPost = {
            Districts_Registration: {
                ...userProfile.Districts_Registration,
                Event_Two: "None"
            }
        }
        await updateUser(userProfile.UUID, objToPost)
        const auditRef = doc(firestore, `Audit Log/EVENT REGISTRATION- ${Date.now()}`)
        const tmsp = Date();
        const responsibleUser = await getUser(currentUser.uid)
        const obj = {
            Type: "Event Registration",
            Data: {
                Event: "None",
                TeamMembers: ["None"],
            },
            ActionBy: responsibleUser.General_Registration.Name,
            Timestamp: tmsp
        }   
        await setDoc(auditRef, obj)
        checkUserFirestore()
        console.log("Success")
    }
    const handleObjectiveTestRegister = async () => {
        setLoading(true)
        const event = document.getElementById('event-list-two').value

        const objToSend = {
            Districts_Registration: {
                ...userProfile.Districts_Registration,
                Event_Two: {
                    Name: event
                }
            }
        }
        await updateUser(userProfile.UUID, objToSend)
        setLoading(false)
        checkUserFirestore()

    }

    const handleRegularEventRegister = async () => {
        setLoading(true)
        const eventChosenName = document.getElementById("event-list-two").value
        let eventChosen;
        listOfEvents.forEach((event) => {
            if (event.Name === eventChosenName){
                eventChosen = event
                return
            }
        })
        
        if (eventChosen.isTest){
            await handleObjectiveTestRegister()
            return
        }

        let teamMembers = []
        
        for (let i = 0; i < listOfTeamMembers.length; i++){
            const name = document.getElementById(`team-member-select-${i+1}`).value
            console.log(name)
            if (name !== "None"){
                let selectedMem
                listOfMembers.forEach((member) => {
                    if (member.UUID === name) {
                        selectedMem = member.General_Registration.Name
                    }
                })
                teamMembers.push(selectedMem)
            }
        }
       
        

        let objToSend = {
            Districts_Registration: {
                ...userProfile.Districts_Registration,
                Event_Two: {
                    Name: eventChosenName
                }
            }
        }

        if (teamMembers.length > 0){
            objToSend.Districts_Registration.Event_Two.Team_Members = teamMembers
        }

        let teamCaptain
        if (selectedEvent.Can_Have_Team){
            if (document.getElementById("team-member-original").checked){
                teamCaptain = userProfile.General_Registration.Name
            } else {
                for (let i = 0; i <listOfMembers.length-1; i++){
                    if (document.getElementById(`team-member-${i}`).checked){
                        teamCaptain = teamMembers[i]
                        break
                    }
                }
            }
            if (!teamCaptain){
                setLoading(false)
                setMessage({show:true, message: "You must select a team captain as part of this event"})
                return
            }
            objToSend.Districts_Registration.Event_Two.TeamCaptain = teamCaptain
        } 

        try {
            const user = await updateUser(userProfile.UUID, objToSend)

            /**
             * Now have to add the event they selected to their team members
             */
            if (objToSend.Districts_Registration.Event_Two.Team_Members){
                for (let i = 0; i < objToSend.Districts_Registration.Event_Two.Team_Members.length; i++){
                    let newTeamMembers = []
                    objToSend.Districts_Registration.Event_Two.Team_Members.forEach((member) => {
                        if (member !== objToSend.Districts_Registration.Event_Two.Team_Members[i]){
                            newTeamMembers.push(member);
                        }
                    })
                    newTeamMembers.push(userProfile.General_Registration.Name)
                    let teamObjToPost = {
                        Districts_Registration: {
                            Event_Two: {
                                Name: objToSend.Districts_Registration.Event_Two.Name,
                                Team_Members: newTeamMembers
                            }
                        }
                    }
                    if (teamCaptain){
                        teamObjToPost.Districts_Registration.Event_Two.TeamCaptain = teamCaptain
                    }
                    /**
                     * Now i have to update each user to have the matching districts registration
                     */
                    let uuid;
                    console.log(listOfMembers)
                    listOfMembers.forEach((member) => {
                        if (member.General_Registration && (member.General_Registration.Name === objToSend.Districts_Registration.Event_Two.Team_Members[i])){
                            uuid = member.UUID
                            if (member.Districts_Registration){
                                const evName = member.Districts_Registration.Event_One.Name
                                let firstEvent;
                                listOfEvents.forEach(event => {
                                    if (event.Name === evName){
                                        firstEvent = event
                                        return
                                    }
                                })
                                if (firstEvent.isTest){
                                    teamObjToPost = {
                                        Districts_Registration: {
                                            ...member.Districts_Registration,
                                            Event_Two: {
                                                Name: objToSend.Districts_Registration.Event_Two.Name,
                                                Team_Members: newTeamMembers
                                            }
                                        }
                                    }
                                    if (teamCaptain){
                                        teamObjToPost.Districts_Registration.Event_Two.TeamCaptain = teamCaptain
                                    }
                                }
                            } else {
                                teamObjToPost = {
                                    Districts_Registration: {
                                        Event_One: {
                                            Name: objToSend.Districts_Registration.Event_Two.Name,
                                            Team_Members: newTeamMembers
                                        }
                                    }
                                }
                                if (teamCaptain){
                                    teamObjToPost.Districts_Registration.Event_One.TeamCaptain = teamCaptain
                                }
                            }
                        }
                    })

                    await updateUser(uuid, teamObjToPost)

                }
            }

            const auditRef = doc(firestore, `Audit Log/EVENT REGISTRATION- ${Date.now()}`)
            const tmsp = Date();
            const responsibleUser = await getUser(currentUser.uid)
            teamMembers.push(responsibleUser.General_Registration.Name)
            const obj = {
                Type: "Event Registration",
                Data: {
                    Event: document.getElementById("event-list-two").value,
                    TeamMembers: teamMembers,
                },
                ActionBy: responsibleUser.General_Registration.Name,
                Timestamp: tmsp
            }   
            await setDoc(auditRef, obj)

            setLoading(false)
            checkUserFirestore()
        } catch (error) {
            console.log(error)
        }

    }

    const handleEventSelect = () => {
        const eventName = document.getElementById("event-list-two").value
        listOfEvents.map((ev) => {
            if (ev.Name === eventName){
                console.log(ev)
                setSelectedEvent(ev)
                let tempArr = []
                for(let i = 0; i < ev.Max_Team_Members-1; i++){
                    tempArr.push(null)
                }
                setListOfTeamMembers(tempArr)
            }
        })
    }
    

    



  return (
    <div class="modal-background">
        <div class="modal-container">
            <div class="form-check mt-3">
                <input type="radio" class="form-check-input" value="event" id="yes-event-select" onClick={handleEventTypeSelect} name="event-option-select" /><label class="form-check-label" for="yes-event-select" >I wish to have a second event</label><br></br>
                <div class="mt-2">
                    <input type="radio" class="form-check-input" value="no-event" id="no-event-select" onClick={handleEventTypeSelect} name="event-option-select"/><label class="form-check-label" for="no-event-select">I DO NOT wish to have a second event</label>
                </div>
                
            </div>
            { loading && <Alert variant="warning">Loading</Alert> }

            {
                wantEventTwo ? 
                <>
                    {
                        wantEventTwo.option ? 

                        <div class="event-select">
                            {
                                eventOne.isTest ? 
                                <>
                                    <label class="fst-italic mt-2 mb-2">You are able to make a live event selection or a non live event selection</label><br></br>
                                    <div class="w-50">

                                        <label class="m-2">Select Your Event</label>
                                        <select class="form-select mb-3" name="event-list" id="event-list-two" onChange={handleEventSelect}>
                                            <option>Select Option</option>
                                            {
                                                listOfEvents.map((event) => {
                                                    if (userProfile.Districts_Registration.Event_One.Name !== event.Name){
                                                        return <option value={event.Name}>{event.Name}</option>
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                    {
                                        selectedEvent && selectedEvent.Can_Have_Team ? 
                                        <>
                                            <p class="fst-italic">
                                                This event is teamed and can have a maximum of {selectedEvent.Max_Team_Members} team members. 
                                            </p>
                                            {
                                                listOfTeamMembers.map((member, i) => {
                                                    return (
                                                        <div class="w-50">
                                                            <label class="mb-2">Team Member #{i+1}</label>
                                                            <select class="form-select mb-3" name="team-mate-select" id={`team-member-select-${i+1}`}>
                                                                
                                                                <option value="None">None</option>
                                                                {
                                                                    
                                                                    listOfMembers.map((member) => {
                                                                        let isElegible = true

                                                                        Object.keys(member).forEach(key => {
                                                                            if (member[key]?.DistrictsTag && !member[key].Paid){
                                                                                isElegible = false;
                                                                            }
                                                                        })

                                                                        if (isElegible){
                                                                            for (let i = 0; i < member.Forms.length; i++){
                                                                                if (!member.Forms[i].Completed){
                                                                                    isElegible = false;
                                                                                    break
                                                                                }
                                                                            }
                                                                        }
                                                                        if (member.General_Registration && isElegible){
                                                                            if (!member.Districts_Registration){
                                                                                return <option value={member.UUID}>{member.General_Registration.Name} ({member.General_Registration.Grade})</option>
                                                                            } else if (!member.Districts_Registration.Event_One.Team_Members){
                                                                                if (!member.Districts_Registration.Event_Two){
                                                                                    return <option value={member.UUID}>{member.General_Registration.Name} ({member.General_Registration.Grade})</option>
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    )
                                                    
                                                })
                                            }
                                            <div class="team-captain mt-3">
                                                {
                                                    selectedEvent.Can_Have_Team ? 
                                                    <>
                                                        {message.show && <Alert variant="danger">{message.message}</Alert>}
                                                        <label><strong>Select Team Captain</strong> </label><br></br>
                                                        <label class="fst-italic mt-2 mb-2">This position is responsible for submitting PREJUDGED EVENTS through the online portal</label>
                                                        <div>
                                                            <input class="form-check-input" type="radio" name={`team-member`} id={`team-member-original`} />
                                                            <label class="form-check-label ms-2" for={`team-member-original`}>I am the Team Captain</label>
                                                        </div>

                                                        {
                                                            listOfTeamMembers.map((member, index) => {
                                                                
                                                                return (
                                                                    <div>
                                                                        <input class="form-check-input" type="radio" name={`team-member`} id={`team-member-${index}`} />
                                                                        <label class="form-check-label ms-2" for={`team-member-${index}`}>Team Member #{index+1}</label>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </> : <></>
                                                }
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    <button class="btn btn-primary mb-3 mt-3" onClick={handleRegularEventRegister}>Submit</button>
                                    
                                </>
                                :
                                <div class="w-25">
                                    <label class="fst-italic mt-2"> You are only able to make a non live event selection</label><br></br>
                                    <label class="m-2">Select Your Event</label>
                                    <select  class="form-select mb-3" name="event-list" id="event-list-two">
                                        <option>Select Option</option>
                                        {
                                            listOfEvents.map((event) => {
                                                if (event.isTest) {

                                                    return <option value={event.Name}>{event.Name}</option>
                                                }
                                            })
                                        }
                                    </select>
                                    <button class="btn btn-primary mb-3" onClick={handleObjectiveTestRegister}>Submit</button>
                                </div>
                            }
                        </div>
                        :
                        <div class="no-event-select">
                            <button class="btn btn-primary mb-3 mt-3" onClick={handleNoSecondEvent} >Complete</button>
                        </div>
                    }
                </>
                :
                <></>
                
            }

            <button class="btn btn-secondary" onClick={()=> setOpenModal(false)}>Close</button>
        </div>
    </div>
  )
}
