import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { getAllUsers, updateUser, getUser, resetSLCRooming } from '../../../../Firebase/Users-CRUD'

export default function Rooming() {
    
    const [members, setMembers] = useState([])
    const [loadingState, setLoadingState] = useState({show: false})
    const [male, setMale] = useState(0)
    const [female, setFemale] = useState(0)
    const [incomplete, setIncomplete] = useState(0)


    useEffect(() => {
        async function loadUsers() {
            const users = await getAllUsers()

            let temp = []
            let femaleTemp = 0;
            let maleTemp = 0;
            let incom = 0;

            users.map((user) => {
                if (user.State_Registration && (user.State_Registration.Commitment.Choice !== "NOT_COMMIT" && user.State_Registration.Commitment.Choice !== "NOT_BUMPED_UP")){
                    temp.push(user)
                    if (user.State_Registration.Rooming && user.State_Registration.Rooming.Gender.toLowerCase() === "female"){
                        
                        femaleTemp++
                    } else if (user.State_Registration.Rooming && user.State_Registration.Rooming.Gender.toLowerCase() === "male"){
                        maleTemp++
                    } else {
                        incom++
                    }
                }
            })
            temp.sort((a, b) => (a.UUID > b.UUID) ? 1 : -1)
            setFemale(femaleTemp)
            setMale(maleTemp)
            setIncomplete(incom)
            setMembers(temp)
        }
        loadUsers()
    }, [])

    const handleReset = async (uuid) => {

        setLoadingState({show: true, message: "Loading", variant: "warning"})

        let femaleTemp = 0;
            let maleTemp = 0;
            let incom = 0;
        try {     
            await resetSLCRooming(uuid)
            const users = await getAllUsers()
            let temp = []
            users.map((user) => {
                if (user.State_Registration && (user.State_Registration.Commitment.Choice !== "NOT_COMMIT" && user.State_Registration.Commitment.Choice !== "NOT_BUMPED_UP")){
                    temp.push(user)
                    if (user.State_Registration.Rooming && user.State_Registration.Rooming.Gender.toLowerCase() === "female"){
                        
                        femaleTemp++
                    } else if (user.State_Registration.Rooming && user.State_Registration.Rooming.Gender.toLowerCase() === "male"){
                        maleTemp++
                    } else {
                        incom++
                    }
                }
            })
            setMembers(temp)
    
            setLoadingState({show: true, message: "Updated", variant: "success"})
        } catch (error) {
            setLoadingState({show: true, message: `${error}`, variant: "danger"})
        }

    }


  return (
    <div>
      <h3 class="text-center">General Registration Items</h3>
      <h5>Female: {female} / {members.length}</h5>
      <h5>Male: {male} / {members.length}</h5>
      <h5>Incomplete: {incomplete} / {members.length}</h5>
      { loadingState.show && <Alert variant={`${loadingState.variant}`} >{loadingState.message}</Alert>}
      <table class="table">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Gender</th>
                <th scope="col">Requests</th>
                <th scope="col">Reset</th>
            </tr>
        </thead>
        <tbody>
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && !member.State_Registration.Rooming){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>Incomplete</td>
                            <td>N/A</td>
                            <td>N/A</td>
                        </tr>
                    }
                })
            }
            {
                members.map(member => {
                    if (member.General_Registration && member.State_Registration && member.State_Registration.Rooming){
                        return <tr>
                            <td>{member.General_Registration.Name}</td>
                            <td>{member.State_Registration.Rooming.Gender}</td>
                            <td>
                                <ol>
                                    {
                                        member.State_Registration.Rooming.Requests.map((request) => {
                                            return <li>{request}</li>
                                        })
                                    }
                                </ol>
                            </td>
                            <td>
                                <button class="btn btn-danger" onClick={() => handleReset(member.UUID)}>Reset</button>
                            </td>
                        </tr>
                    } 
                })
            }
        </tbody>
      </table>
    </div>
  )
}
