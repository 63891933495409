import React, { useState, useEffect } from 'react'
import { generateDLCReport } from '../../../../Firebase/Firestore-Query'
import { assignDLCRatingSheets, deleteFilesDLCRatingSheets } from '../../../../Firebase/Firestore-Query'
import { getAllUsers } from '../../../../Firebase/Users-CRUD'
import { Alert } from "react-bootstrap"
import { ref, uploadBytes } from "firebase/storage"
import { file } from 'jszip'
import { storage } from '../../../../Firebase/Firebase-Config'



export default function UploadDLCRatingSheets() {
    const [teamEvents, setTeamEvents] = useState([])
    const [individualEvents, setIndividualEvents] = useState([])
    const [listOfEvents, setListOfEvents] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)

    const [files, setFiles] = useState([])

    const [message, setMessage] = useState({ show:false })
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleted, setDeleted] = useState(false)

    const [confirmed, setConfirmed] = useState(false)

    useEffect(() => {
        async function mountPage() {
            const { TeamedEvents, IndividualEvents } = await generateDLCReport()
            

            let temp = []

            TeamedEvents.map((event) => {
                temp.push(event.EventName)
            })
            IndividualEvents.map((event) => {
                temp.push(event.EventName)
            })

            setTeamEvents(TeamedEvents)
            setIndividualEvents(IndividualEvents)
            setListOfEvents(temp)
        }
        mountPage()
    }, [])

    const handleEventSelect = () => {
        const chosenEvent = document.getElementById("upload-dlc-event-select").value
        if (chosenEvent === "none"){ 
            setSelectedEvent(null)
            return
         }

        teamEvents.map((event) => {
            if (event.EventName === chosenEvent){
                setSelectedEvent({
                    isTeamed: true,
                    ...event
                })
            }
        })
        individualEvents.map((event) => {
            if (event.EventName === chosenEvent){
                setSelectedEvent({
                    isTeamed: false,
                    ...event
                })
            }
        })

        setConfirmed(false)
        
    }

    const handleUpload = async () => {
        setLoading(true)
        setMessage({ show:false })
        if (files.length===0){
            const obj = {
                show:true, 
                message: "Must upload at least one file first",
                variant:"danger"
            }
            setLoading(false)
            return setMessage(obj)
        }

        let selectedTeam;

        let valueSelected = ""
        if (selectedEvent.TeamsCompeting) {
            selectedEvent.TeamsCompeting.map((team, i) => {
                if (document.getElementById(`team-${i}`).checked){
                    //Construct file Title
                    valueSelected = `${selectedEvent.EventName} - TC: ${team.Team_Captain}`
                    selectedTeam = team
                }
            })
        } else {
            selectedEvent.Competitors.map((competitor, i) => {
                if (document.getElementById(`competitor-${i}`).checked){
                    //Construct title of file
                    valueSelected = `${selectedEvent.EventName}-${competitor}`
                    selectedTeam = competitor
                }
            })
        }
        
        Object.keys(files).forEach(async(key, i) => {
            const imageRef = ref(storage, `DLC Rating Sheets/${valueSelected}-${i+1}`)
            await uploadBytes(imageRef, files[key])
            
            
        })
        
        /**
         * Now have to update users profile to indicate that they have a rating forms
         * 
         * either in this format of team with team captain
         * 
         * Obj = {
         *      Team_Captain: "",
         *      Team_Members: ["", ""]
         * }
         * 
         * 
         * or its just the string with the name ("")
         */

        const userList = await getAllUsers()

        if (selectedTeam.Team_Captain){
            selectedTeam.Team_Members.forEach((member) => {
                assignDLCRatingSheets(member, userList, selectedEvent.EventName)
            })
        } else {
            assignDLCRatingSheets(selectedTeam, userList, selectedEvent.EventName)
        }




        setConfirmed(true)
        setLoading(false)
        setSelectedEvent(null)
        document.getElementById("upload-dlc-event-select").selectedIndex = "none"



    }

    const resetAllSheets = async () => {
        await deleteFilesDLCRatingSheets()
        setDeleted(true)
    } 

  return (
    <div class="upload-sheets-container mt-3">
      <h3 class="text-center">Upload DLC Rating Sheets to Members</h3>
      <div class="reset-all-sheets mt-3 mb-3">
        { deleted && <Alert variant="success">Files Deleted</Alert> }
        <button class="btn btn-danger" onClick={resetAllSheets}>Reset All Upload Files</button>
        <p class="fst-italic mt-2">Note: This will delete all exisiting rating sheets in system </p>
      </div>
      <div class="event-select w-50">
        { success && <Alert variant="success">Uploaded!</Alert> }
        <p>Select Event:</p>
        <select class="form-select" onClick={handleEventSelect} id="upload-dlc-event-select">
            <option value="none">Select</option>
            {
                listOfEvents.map((event) => {
                    return <option value={event}>{event}</option>
                })
            }
        </select>
      </div>
      <div class="mt-3">
        { confirmed && <Alert variant="success">Uploaded!</Alert> }
      </div>

      <div class="event-seleted">
        {
            selectedEvent ? 
            <>
                {
                    selectedEvent.isTeamed ? 
                    <>
                        <p>{selectedEvent.EventName}</p>
                        {
                            selectedEvent.TeamsCompeting.map((team, i) => {
                                return (
                                    <>
                                        <input class="form-check-input" type="radio" id={`team-${i}`} name={`team`} />
                                        <label class="form-check-label ms-2" for={`team-${i}`}> Team #{i+1} Captain: {team.Team_Captain} </label>

                                        <ul>
                                            {
                                                team.Team_Members.map((member) => {
                                                    return <li>{member}</li>
                                                })
                                            }
                                        </ul>
                                    </>
                                )
                            })
                        }
                    </>
                    : 
                    <>
                        <p>{selectedEvent.EventName}</p>
                        {
                            selectedEvent.Competitors.map((competitor, i) => {
                                return (
                                    <div>
                                        <input class="form-check-input" type="radio" id={`competitor-${i}`} name={`competitor`} />
                                        <label class="form-check-label ms-2" for={`competitor-${i}`}>{ competitor }</label>
                                    </div>
                                )
                            })
                        }
                    </>
                }
            </> : <></>
        }
      </div>

      <div class="upload">
        {
            selectedEvent ?
            <>
                { message.show && <Alert variant={message.variant}>{message.message}</Alert> }
                { loading && <Alert variant="warning">Loading</Alert> }
                <p class="mt-4">Upload File(s)</p>
                <input multiple type="file" id="rating-sheet-upload" onChange={(e) => setFiles(e.target.files)}/>
                <button class="btn btn-primary" onClick={handleUpload}>Upload</button>
            </>
            : <></>
        }
      </div>

    </div>
  )
}
