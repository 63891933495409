import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyCOniwXS1QA2BxkLy-V8CS1cbXFxomRTIo",
    authDomain: "cms-test-6856c.firebaseapp.com",
    projectId: "cms-test-6856c",
    storageBucket: "cms-test-6856c.appspot.com",
    messagingSenderId: "235016099463",
    appId: "1:235016099463:web:277a856f8835615e3aca22",
    measurementId: "G-0YKMVWR2Z2"
};



const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);

export const auth = getAuth(app);

export const storage = getStorage(app);