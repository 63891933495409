import React, { useEffect, useState } from 'react'
import { useAuth, shallowEqual } from "../../AuthContext"
import { Alert, Col, Row } from "react-bootstrap"
import { getAllOfficerProfiles, addOfficerProfile, deleteOfficerProfile } from "../../Firebase/Firestore-Query"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { storage } from '../../Firebase/Firebase-Config'
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";




export default function Officers() {
    const [officers, setOfficers] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ show:false })
    const [name, setName] = useState("")
    const [role, setRole] = useState("")
    const [email, setEmail] = useState("")
    const [about, setAbout] = useState("")
    const [previousEvents, setPreviousEvents] = useState([])
    const [image, setImage] = useState("")
    const [imageURL, setImageURL] = useState("")
    

    useEffect(() => {
        async function loadOfficers() {
            const officers = await getAllOfficerProfiles()
            setOfficers(officers)
        }

        loadOfficers()
    }, [])

    const handleAddOfficer = async (e) => {
        e.preventDefault()
        setLoading(true)
        setMessage({ show:false })
        const storageRef = ref(storage, `officer-profiles/${image.name}`)
        const downloadURL = await getDownloadURL(storageRef)


        await addOfficerProfile(name, email, role, about, previousEvents, downloadURL)
        const officers = await getAllOfficerProfiles()
        setOfficers(officers)
        setLoading(false)
        setMessage({ show:true, text: "Officer added successfully", variant: "success" })
    }

    const handleDeleteOfficer = async (e) => {
        e.preventDefault()
        setLoading(true)
        setMessage({ show:false })
    
        await deleteOfficerProfile(name)
        const officers = await getAllOfficerProfiles()
        setOfficers(officers)
        setLoading(false)
        setMessage({ show:true, text: "Officer deleted successfully", variant: "success" })
    }

    const handleImageUpload = async (e) => {
        e.preventDefault()
        console.log(image.name)
        setImageURL(`gs://cms-test-6856c.appspot.com/officer-profiles/${image.name}`)
    
        
    
        const storageRef = ref(storage, `officer-profiles/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
    
        uploadTask.on("state_changed", {}, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setImageURL(`officer-profiles/${image.name}`)
              
            });
          }
        );
        getDownloadURL(ref(storage, `officer-profiles/${image.name}`)).then((url) => {
            setImageURL(url)
        }
        );
    }
    return (
        <div>
            <h1>Officers</h1>
            <div>
                <h2>Add Officer</h2>
                <form>
                    <div>
                        <label>Name: </label>
                        <input type="text" style={{ marginLeft: "10px" }} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div>
                        <label>Role: </label>
                        <input type="text" style={{ marginLeft: "10px" }} onChange={(e) => setRole(e.target.value)} />
                    </div>
                    <div>
                        <label>Email: </label>
                        <input type="text" style={{ marginLeft: "10px" }} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div>
                        <label>About: </label>
                        <input type="text" style={{ marginLeft: "10px" }} onChange={(e) => setAbout(e.target.value)} />
                    </div>
                    <div>
                        <label>Previous Events: separate with commas and spaces: </label>
                        <input type="text" style={{ marginLeft: "10px" }} onChange={(e) => setPreviousEvents(e.target.value.split(", "))} />
                    </div>
                    <div>
                        <label>Image: </label>
                        <input type="file" style={{ marginLeft: "10px" }} onChange={(e) => setImage(e.target.files[0]) } />
                        <button onClick={(e) => handleImageUpload(e)}>Upload Image</button>
                    </div>
                    <div>
                        <button onClick={(e) => handleAddOfficer(e)}>Add Officer</button>
                        <button onClick={(e) => handleDeleteOfficer(e)}>Delete Officer</button>
                    </div>
                </form>
            </div>
            <div>
                {officers.map((officer) => {
                    if (officer) {
                        console.log(officer)
                        return ( 
                            <div key={officer.Name}>
                                <Row>
                                    <Col>
                                        <h2>{officer.Name ? officer.Name : ""}</h2>
                                        <p>{officer.Role ? officer.Role : ""}</p>
                                        <p>{officer.Email ? officer.Email : ""}</p>
                                        <p>{officer.About ? officer.About : ""}</p>
                                        <p>{officer.Previous_Events ? officer.Previous_Events : ""}</p>
                                    </Col>
                                    <Col>
                                        <img src={officer.Profile_Image_Link ? officer.Profile_Image_Link : ""} className="officer-image" style={{width: "150px", height: "150px", objectFit: "cover"}} />
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
    }

