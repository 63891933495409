import {collection, doc, getDoc, setDoc, updateDoc, getDocs, deleteDoc, deleteField } from "firebase/firestore"
import { firestore } from "./Firebase-Config"
import { useAuth } from "../AuthContext"
import { getAllForms } from "./Firestore-Query"



const collectionRef = collection(firestore, "Users")
/**
 * This file Users-CRUD is meant to only to perform CRUD Operations on the user
 * Create:
 *  --> Create a new user in the Firestore Database under the Users Collection
 * Read:
 *  --> Get the user's profile information
 * Update: 
 *  --> Update the PROFILE information only with the user
 * Delete: 
 *  --> Delete the user from the CMS
 */

/**
 * This function essentially creates a new user in the database that tracks the user's profile over time
 * here is how the data will be structured
 * const userExample = {
 *  name:"Jane Doe",
 *  uuid: "123456789abcdef"
 *  email: "jdoe@example.com",
 *  Grade: 11,
 *  studentID: 11111111,
 *  authLevel: "member" || "admin" || "officer"
 *  MORE INFORMATION YET TO COME
 * }
 * @param {String} uuid 
 * @param {String} name 
 * @param {String} email 
 * @param {String} studentID 
 */
export const createNewUser = async (uuid, email) => {
    const newDocRef = doc(firestore, `users/${uuid}`)
    //let forms = await getAllForms()

    const standard = await getUser("Standard")
    
    const baseData = {
        ...standard,
        UUID: uuid,
        authLevel: "member",
        //Forms: forms, 
        Email: email
    }


    
    setDoc(newDocRef, baseData)
        .then(() =>{
            return true
        })
        .catch(err =>{
            return err
        })

}

export const getUser = async (uuid) => {
    const userDocRef = doc(firestore, `users/${uuid}`)
    const snapshot = await getDoc(userDocRef)
    if(snapshot.exists()) {
        const userData = snapshot.data()
        return userData
    }
    
}

export const getAllUsers = async () => {
    let ret = []
    const collectionRef = collection(firestore, "users")
    const snapshot = await getDocs(collectionRef)
    snapshot.forEach((doc) => {
        ret.push(doc.data())
    })
    return ret;

}

export const updateUser = async (uuid, paramsToUpdate) => {
    
    const docRef = doc(firestore, `users/${uuid}`)
    await updateDoc(docRef, paramsToUpdate)

    const user = await getUser(uuid) 
    
    

    return user  
    
}

export const cleanup = async () => {
    const userList = await getAllUsers()

    userList.forEach(async (user) => {
        await updateUser(user.UUID, { Event_One: deleteField(), Event_Two: deleteField() })
        
    })
}

export const resetState = async (uuid) => {
    await updateUser(uuid, { State_Registration: deleteField() })
}


export const resetSLCGeneral = async (uuid) => {
    const user = await getUser(uuid)
    delete user.State_Registration.General_SLC_Registration
    await updateUser(uuid, { State_Registration: {...user.State_Registration } })
}
export const resetSLCRooming = async (uuid) => {
    const user = await getUser(uuid)
    delete user.State_Registration.Rooming
    await updateUser(uuid, { State_Registration: {...user.State_Registration } })
}

export const postUserGeneralRegistration = async (uuid, generalReg) => {
    console.log(generalReg)
    const docref = doc(firestore, `users/${uuid}`)
    const objToPost = {
        General_Registration : generalReg
    }
    await updateDoc(docref, objToPost)

}

export const assignTaskToUsers = async (task, listOfMembers) => {
    for (let i = 0; i < listOfMembers.length; i++){
        const userData = await getUser(listOfMembers[i].UUID)
        const docRef = doc(firestore, `users/${listOfMembers[i].UUID}`)
        if (userData.Pending_Tasks && userData.Pending_Tasks.includes(task.Title) || 
            userData.Completed_Tasks && userData.Completed_Tasks.includes(task.Title)){
                return
        }else {

            if (userData.Pending_Tasks){
                
                await updateDoc(docRef, {
                    Pending_Tasks: [...userData.Pending_Tasks, task.Title]
                })
            } else {
                await updateDoc(docRef,{
                    Pending_Tasks: [task.Title]
                })
            }
        }
        
    }
}

export const userCompletedTask = async (uuid, taskName) => {
    const userData = await getUser(uuid)
    const index = userData.Pending_Tasks.indexOf(taskName)

    let tempArrPending = userData.Pending_Tasks
    tempArrPending.splice(index, 1)
    const userDocRef = doc(firestore, `users/${uuid}`)
    if (userData.Completed_Tasks){

        await updateDoc(userDocRef, {
            Pending_Tasks: tempArrPending,
            Completed_Tasks: [...userData.Completed_Tasks, taskName]
        })
    } else {
        await updateDoc(userDocRef, {
            Pending_Tasks: tempArrPending,
            Completed_Tasks: [taskName]
        })
        
    }

    
}

export const getAllPendingTasks = async(uuid) => {
    const docRef = doc(firestore, `users/${uuid}`)
    const snapshot = await getDoc(docRef)

    if (snapshot.exists()){
        const userData = snapshot.data()
        return userData.Pending_Tasks
    }
}


export const deleteUser = async (uuids, names, currentUser) => {
    uuids.map( async (uuid, i) => {
        console.log(uuid)
        const docRef = doc(firestore, `users/${uuid}`)
        await deleteDoc(docRef)
    })


    const auditRef = doc(firestore, `Audit Log/DELETED USER- ${Date.now()}`)
    const tmsp = Date();
    const responsibleUser = await getUser(currentUser.uid)

    

    const obj = {
        Type: "Delete Member",
        ActionBy: responsibleUser.General_Registration.Name,
        ActionRecipient: names,
        Timestamp: tmsp
    }   
    await setDoc(auditRef, obj)

}