import React, { useState, useEffect } from 'react'
import { getAllUsers } from '../../../../Firebase/Users-CRUD'
import InputEventPlacement from '../Components/InputEventPlacement'
export default function InputDLCEventPlacement() {
    const [members, setMembers] = useState([])
    useEffect(() => {
        async function loadMembers() {
            const temp = await getAllUsers()
            setMembers(temp)
        }
        loadMembers()
    },[])

  return (
    <div class='page-container mt-3'>
      <h3 class="text-center">Input DLC Event Placement</h3>
      {
        members.map((member) => {
            return <InputEventPlacement data={member} />
        })
      }
    </div>
  )
}
