import React, { useState } from 'react'
import { storage } from '../../Firebase/Firebase-Config'
import { ref, uploadBytes } from "firebase/storage"
import { Alert } from "react-bootstrap"
import PermissionFormUpload from '../../Components/PermissionFormUpload'
import PermissionFormView from '../../Components/Member Record Components/PermissionFormView'
import { getAllUsers, getUser, updateUser } from '../../Firebase/Users-CRUD'
import { useEffect } from 'react'

/* allow admin to upload permission form for a different user */

export default function UserFormUpload() {
    /* dropdown to select user */
    const [user, setUser] = useState({})
    const [users, setUsers] = useState()
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState([])
    const [forms, setForms] = useState()
    const [userData, setUserData] = useState({})
    const [formData, setFormData] = useState({})
    const [reload, setReload] = useState(false)


    /* get all users */
    useEffect(() => {
        console.log("useEffect")
        const loadUsers = async () => {
            const users = await getAllUsers()
            const sortedUsers = users.sort((a, b) => {
                if (a.General_Registration && b.General_Registration){
                    return a.General_Registration.Name.localeCompare(b.General_Registration.Name)
                }
            }
            )

            setUsers(users)
        }
        loadUsers()
    }, [])

    useEffect(() => {
        const reloadData = async () => {   
            setReload(false)
        }
        reloadData()
    }, [reload])

    /* get all forms from user */
    useEffect(() => {
        console.log("Get forms useEffect")
        const loadForms = async () => {
            if (user){
                const userForms = await getUser(user)
                //console.log(userForms)
                setForms(userForms.Forms)
                setUserData(userForms)
            }
        }
        loadForms()
    }, [user, reload])

    /* when form is selected, get new form data */
    useEffect(() => {
        console.log("Get form data useEffect")
        const loadFormData = async () => {
            for (let i = 0; i < forms.length; i++){
                if (forms[i].Name === form){
                    setFormData(forms[i])
                    break
                }
            }
        }
        loadFormData()

    }, [form, user, userData, reload])

    const markCompleted = async () => {
        let forms = userData.Forms
        for (let i = 0; i < forms.length; i++){
            if (form === forms[i].Name){
                forms[i].Completed = true
                break
            }
        }
        await updateUser(user, {Forms: forms})
        setReload(true)
    }

    return (
        <div>
            <h1>Upload Form for User</h1>
            <div>
                <select onChange={(e) => setUser(e.target.value)}>
                    <option value="default">Select User</option>
                    {
                        users && users.map((user, i) => {
                            //console.log(user)
                            return <option key={i} value={user.UUID}>{user.General_Registration && user.General_Registration.Name}</option>
                        })
                    }
                </select>
                {/** dropdown to select form */}
                <select onChange={(e) => setForm(e.target.value)}>
                    <option value="default">Select Form</option>
                    {
                        forms && forms.map((form, i) => {
                            return <option key={i} value={form.Name}>{form.Name}</option>
                        })
                    }
                </select>
                {console.log(formData)}
                {console.log(user)}
                {forms && (
                    <>
                        <PermissionFormView data={forms} />
                        <button onClick={markCompleted}>Mark as Completed</button>
                        <PermissionFormUpload form={formData} user={userData} />
                    </>
                )}
            </div>
            {message && message.isBad && <Alert variant="danger">{message.msg}</Alert>}
            {message && !message.isBad && <Alert variant="success">{message.msg}</Alert>}
        </div>
    )
}

