import React, { useEffect, useState } from 'react'
import { getAllLogs } from '../../Firebase/Firestore-Query'

export default function AuditLog() {
    const [logs, setLog] = useState([])

    useEffect(() => {
        async function mountLog() {
            const list = await getAllLogs()
            setLog(list)
        }
        
        mountLog()
    }, [])



  return (
    <div class="mt-3">
        <div class="delete-members bg-light rounded m-3 p-3">
            <h2 class="text-center">Deleted Member History</h2>
            {
                Array.from(logs).reverse().map((log) => {
                    if (log.Type === "Delete Member"){
                        return <div class="mt-4">
                            <h2>{log.Type}</h2>
                            <p>Deleted: </p>
                            <ul>
                                {
                                    log.ActionRecipient.map((member) => {
                                        return <li>{member}</li>
                                    })
                                }
                            </ul>
        
                            <p>Executed by: {log.ActionBy} @ {log.Timestamp}</p>
                        </div>
                    } 
                })
            }
        </div>
        <div class="event-registration bg-light rounded m-3 p-3">
            <h2 class="text-center">Event Registration History</h2>
            {
                Array.from(logs).reverse().map((log) => {
                    if (log.Type === "Event Registration"){

                        return <div class="mt-4">
                            <h4>{log.Type}</h4>
                            <p>Event: {log.Data.Event}</p>
                            <ul>
                                {
                                    log.Data.TeamMembers.map((member) => {
                                        return <li>{member}</li>
                                    })
                                }
                            </ul>

                            <p>Executed by: {log.ActionBy} @ {log.Timestamp}</p>

                        </div>
                    }
                })
            }
        </div>
        <div class="payment-history bg-light rounded m-3 p-3 overflow-auto">
            <h2 class="text-center">Payment History</h2>
            {
                Array.from(logs).reverse().map((log) => {
                    if (log.Type === "Partial Payment" || log.Type === "Full Payment" || log.Type === "Reset Payment") {
                        return <div class="mt-4">
                            <h4>{log.Type}</h4>
                            <p>Recipient: {log.ActionRecipient}</p>
                            <p>Executed by: {log.ActionBy} @ {log.Timestamp}</p>
                        </div>
                    }
                })
            }
        </div>
    </div>
  )
}
