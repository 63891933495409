import React, { useState, useEffect } from 'react'
import { getAllUsers, updateUser } from '../../Firebase/Users-CRUD'
import { postPermissionForms, deleteForm, deleteAllForms, getAllForms, downloadPermissionForm } from '../../Firebase/Firestore-Query'
import { useAuth } from '../../AuthContext'
import { Alert } from 'react-bootstrap'
export default function ManagePermissionForms() {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({show: false})
    const [error, setError] = useState({show: false})
    const [forms, setForms] = useState([])
    const [formCount, setFormCount] = useState([])
    const [userList, setUserList] = useState([])
    
    const { userProfile } = useAuth()

    useEffect(() => {

        async function loadForms() {

            const tempForms = await getAllForms()
            setForms(tempForms)

            const users = await getAllUsers()
            setUserList(users)
            let temp = []
            for (let i = 0; i < userProfile.Forms.length; i++){
                let formObjToPost = {
                    FormName:userProfile.Forms[i].Name
                }
                let count = 0;
                for (let x = 0; x < users.length; x++){
                    if (users[x].Forms[i].Completed){
                        count++;
                    }
                }
                formObjToPost.Count = count
                temp.push(formObjToPost)
            }
            setFormCount(temp)

        }
        loadForms()
        console.log(formCount)
    }, [])

    const assignForm = async () => {
        setLoading(true)
        const formName = document.getElementById("perm-form-name").value
        const objToAdd = {
            Name: formName,
            Assigned_By: userProfile.General_Registration.Name,
            Link: document.getElementById("perm-form-link").value
        }
        await postPermissionForms(objToAdd)
        setMessage({show:true, message:"Form Issued to All Members"})
        setLoading(false)
    }
    
    const deletePermissionForm = async () => {
        setLoading(true)
        const users = await getAllUsers()
        const formName = document.getElementById("delete-form-name").value
        for (const user of users) {
            let index;
            for (let i = 0; i < user.Forms.length; i++) {
                if (formName === user.Forms[i].Name){
                    index = i
                    break
                }
            }
            
            
            let tempArr = [...user.Forms]
            tempArr.splice(index, 1)
            
            const objToPost = {
                Forms: tempArr
            }
            if (user.UUID !== "NULL"){
                await updateUser(user.UUID, objToPost)
            } 
        }
        await deleteForm(formName)
        
        setMessage({show:true, message:"Form Deleted from All Members"})
        setLoading(false)
    }

    const downloadForms = async () => {
        setLoading(true)
        setError({show:false})
        const name = document.getElementById("download-form-name").value
        if (name === "none"){
            setLoading(false)
            setError({show:true, message:"Must select a form name first"})
        }


        await downloadPermissionForm(name)

        setLoading(false)
    }

    
    return (
    <div class="manage-permission-form-container ">
        <h2 class = "fw-bold text-center mb-5">MANAGE PERMISSION FORMS</h2>
        <div class="existing-permission-forms bg-light rounded mx-3 my-5 p-3 container">
            <h3 class= "text-center mb-5 row justify-content-center">CURRENT PERMISSION FORMS</h3>
            {
                userProfile && userProfile.Forms ? 
                <>
                    {
                        userProfile.Forms.map((form, i) => {
                            if (formCount[i]){
                                return (
                                    <ul>
                                        <li><label class="existing-perm-form-label" key={i}>{form.Name} - {formCount[i].Count} / {userList.length} Completed</label></li>
                                    </ul>
                                    )
                            }
                        })
                    }
                </>:
                <></>
            }
        </div>
        <div class="download-forms bg-light rounded mx-3 my-5 p-3 container">
            <h3 class= "text-center mb-5 row justify-content-center">DOWNLOAD MEMBER PERMISSION FORMS</h3>
            {error.show ? <Alert variant="danger">{error.message}</Alert> : <></>}
            <div class="row justify-content-center mb-2">
                <select class="form-dropdown form-select w-50 my-3 text-center col-6" id="download-form-name">
                    <option value="none">Select</option>
                    {
                        forms ? 
                        <>
                            {
                                forms.map((form, i) => {

                                    return <option value={form.Name} key={i}>{form.Name}</option>
                                })
                            }
                        </>:
                        <></>
                    }
                </select> 
            </div>
            <div class="row justify-content-center my-4">
                <button class="submit btn btn-primary col-6 " onClick={downloadForms} >Download</button>
            </div>
        </div>
        {loading ? <Alert variant="warning">Loading</Alert> : <></>}
        {message.show ? <Alert variant="success">{message.message}</Alert> : <></>}
        <div class="create-new-permission-form bg-light rounded mx-3 my-5 p-3 container">
            <h3 class= "mb-5 row justify-content-center">CREATE NEW PERMISSION FORM</h3>
            <div class="row m-3 p-3 justify-content-center">
                <label class="permission-form-name form-label fw-bold text-center">Name: </label>
                <input class="permission-form-name-input form-control w-50 col-12 text-center" placeholder="Permission Form Name" id="perm-form-name" type="text" /> 
                
                <label class="permission-form-link form-label fw-bold text-center mt-2">Link to Document: </label>
                <input class="permission-form-link-input form-control w-50 col-12 text-center" placeholder="Link to Document" id="perm-form-link" type="text" /> 

                <div class="row justify-content-center mt-4">
                    <button class="btn btn-primary my-3 p-2 col-6" onClick={assignForm} >Assign to All Members</button>
                </div>
            </div>
        </div>
        <div class="delete-permission bg-light rounded mx-3 my-5 p-3 container">
            {
                userProfile && userProfile.authLevel === "admin" ? 
                    <div class="delete-old-permission-forms ">
                        <h3 class= "mb-5 row justify-content-center">DELETE PERMISSION FORM</h3>
                        <label class="warning-note fst-italic">*Note: This operation will not delete any uploaded forms to the specified permission from. It simply deletes the ability to upload forms to this existing permission form. </label>
                        <div class="row justify-content-center mb-2">
                            <select class="form-dropdown form-select w-50 my-3 text-center col-6" id="delete-form-name">
                            {
                                forms ? 
                                <>
                                    {
                                        forms.map((form, i) => {

                                            return <option value={form.Name} key={i}>{form.Name}</option>
                                        })
                                    }
                                </>:
                                <></>
                            }
                            </select> 
                            <div class="row justify-content-center my-4">
                                <button class="submit btn btn-danger col-6 " onClick={deletePermissionForm} >Delete from un-uploaded members</button>
                            </div>
                            <div class="row justify-content-center my-4">
                                <button class="submit btn btn-danger col-6 " onClick={deleteAllForms} >Delete ALL forms from ALL users</button>
                            </div>
                        </div>
                    </div>
                : 
                
                <></>
            }
        </div>
    </div>
  )
}
