import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../AuthContext"
import "./Navbar.css"
export default function MemberNavbar() {
  const { logout } = useAuth()
  const navigate = useNavigate()
  const handleLogout = async () => {
    await logout()
    navigate("/login")
  }
  return (
    <div class="navbar-container" >
      
      <Link class="btn btn-primary" to="memberProfile">Profile</Link>
      <Link class="btn btn-primary" to="conference">Conferences</Link>
      <Link class="btn btn-primary" to="eventView">Events</Link>
      <Link class="btn btn-primary" to="officersView">Officers</Link>

      
      <button class="btn btn-secondary" onClick={handleLogout}>Logout</button>
    </div>
  )
}
