import React, { useRef, useState, useEffect } from 'react'
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from '../AuthContext'
import { getAccessCode, addPassword } from '../Firebase/Firestore-Query'
import { Link, useNavigate } from "react-router-dom"
import { createNewUser, postUserGeneralRegistration } from '../Firebase/Users-CRUD'


export default function Register() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const confPasswordRef = useRef()
    const accessCodeRef = useRef()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const nicknameRef = useRef()
    const gradeRef = useRef()
    const dietRef = useRef()
    const phoneRef = useRef()
    const shirtRef = useRef()
    const genderRef = useRef()
    const studentIDRef = useRef()
    const parentPhoneRef = useRef()

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [accessCode, setAccessCode] = useState("")

    const { signup, currentUser } = useAuth()

    const navigate = useNavigate()
    useEffect(() =>{
        async function getCode() {

            const code = await getAccessCode()
            setAccessCode(code)
        }
        getCode()

    }, [])
    async function handleSubmit(e) {
        e.preventDefault()

        let newUser;

        if (passwordRef.current.value !== confPasswordRef.current.value) {
            return setError("Passwords do not match")
        }

        try {
            if (accessCode.Access_Code !== accessCodeRef.current.value){
                return setError("Invalid Access Code")
            }
            setError("")
            setLoading(true)
            newUser = await signup(emailRef.current.value, passwordRef.current.value)
            await addPassword(emailRef.current.value, passwordRef.current.value)
            
        } catch (error) {
            return setError("Failed to create an account. Ensure your password is at least 8 characters long.")   
        }
        
        try {
            await createNewUser(newUser.user.uid, emailRef.current.value)
        }
        catch (error) {
            return setError("Failed to create a user profile. Please contact an officer.")
        }
        let full_name;
        if (nicknameRef.current.value === ""){
            full_name = firstNameRef.current.value + " " + lastNameRef.current.value
        }
        else{
            full_name = firstNameRef.current.value + " " + lastNameRef.current.value + " (" + nicknameRef.current.value + ")"
        }
        if (dietRef.current.value === ""){
            dietRef.current.value = "None"
        }

        const generalRegObj = {
            "Name": full_name,
            "Grade": gradeRef.current.value,
            "Dietary_Restriction": dietRef.current.value,
            "Phone_Number": phoneRef.current.value,
            "T_Shirt_Size": shirtRef.current.value,
            "Gender": genderRef.current.value,
            "Email": emailRef.current.value,
            "Student_ID": studentIDRef.current.value,
            "Parent_Phone_Number": parentPhoneRef.current.value,
        }


        try {
            await postUserGeneralRegistration(newUser.user.uid, generalRegObj)
        }
        catch (error) {
            //console.log(error)
            return setError("Failed to create a user profile. Please contact an officer.")
        }


        setLoading(false)
        navigate("/home/memberProfile")

        
    }
    //console.log(accessCode)
  return (
    <>
        <Card>
            <Card.Body>
                <div class="container text-center" >
                    <div class="row my-5 bg-light rounded p-5">
                        <div class="col gap-4">
                            <div class="fs-5">Grandview High School</div>
                            <div class="fs-4 fw-bold mb-5">FUTURE BUSINESS LEADERS OF AMERICA</div>
                            <div class="fs-4 fw-bold mb-4">ACCOUNT REGISTRATION</div>
                        </div>
                        
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            
                            <div class="row mb-3 px-4">
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                            </div>
                            <div class="row mb-3 px-4 justify-content-center">
                                <Row>
                                    
                                        <Col>
                                            <div class="fs-6">Password</div>
                                            <Form.Control type="password" ref={passwordRef} required />
                                        </Col>
                                
                                        <Col>
                                            <div class="fs-6">Confirm Password</div>
                                            <Form.Control type="password" ref={confPasswordRef} required />
                                        </Col>
                                </Row>
                            </div>
                        

                            <div class="row mb-3 px-4 justify-content-center">
                                <Row>
                                    <Col>
                                        <div class="fs-6">Access Code</div>
                                        <Form.Control type="text" ref={accessCodeRef} required />
                                    </Col>
                                    <Col>
                                        <div class="fs-6">CCSD Student ID</div>
                                        <Form.Control type="text" ref={studentIDRef} required />
                                    </Col>

                                </Row>
                            
                        </div>

                        <div>
                            <div class="row mb-3 px-4 justify-content-center">
                                <Row>
                                    
                                        <Col>
                                            <div class="fs-6">First Name</div>
                                            <Form.Control type="text" ref={firstNameRef} required />
                                        </Col>
                                
                                        <Col>
                                            <div class="fs-6">Last Name</div>
                                            <Form.Control type="text" ref={lastNameRef} required />
                                        </Col>
                                        <Col>
                                            <div class="fs-6">Nickname: Optional</div>
                                            <Form.Control type="text" ref={nicknameRef}/>
                                        </Col>
                                </Row>
                            </div>
                            <div class="row mb-3 px-4 justify-content-center">
                                <Row>
                                    <Col>
                                        <div class="fs-6">Grade</div>
                                        <Form.Select ref={gradeRef} required>
                                            <option value="9">Freshman</option>
                                            <option value="10">Sophomore</option>
                                            <option value="11">Junior</option>
                                            <option value="12">Senior</option>
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <div class="fs-6">Shirt Size</div>
                                        <Form.Select ref={shirtRef} required>
                                            <option value="1">Small</option>
                                            <option value="2">Medium</option>
                                            <option value="3">Large</option>
                                            <option value="4">X-Large</option>
                                            <option value="5">XX-Large</option>
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <div class="fs-6">Gender</div>
                                        <Form.Select ref={genderRef} required>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </div>
                            <div class="row mb-3 px-4 justify-content-center">
                                <Row>
                                    <Col>
                                        <div class="fs-6">Dietary Restrictions: Optional</div>
                                        <Form.Control type="text" ref={dietRef}/>
                                    </Col>
                                    <Col>
                                        <div class="fs-6">Phone Number</div>
                                        <Form.Control type="text" ref={phoneRef} required/>
                                    </Col>
                                    <Col>
                                        <div class="fs-6">Parent Phone Number</div>
                                        <Form.Control type="text" ref={parentPhoneRef} required/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                            
                            <div class="row my-4 px-4 justify-content-center">
                                <div class="col-6">
                                    <Button disabled={loading} className="w-100 mt-2" type="submit">Register</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                
            </Card.Body>
        </Card>
        <div class="fw-bold text-center my-2">
            Already have an account? <Link to="/login">Log In</Link>
        </div>
    </>
  )
}
