import React, { useEffect } from 'react'
import { useAuth } from "../AuthContext"
import { getUser, createNewUser } from "../Firebase/Users-CRUD"
import { useNavigate, Outlet } from 'react-router-dom'

import OfficerNavbar from "../Components/OfficerNavbar"
import MemberNavbar from "../Components/MemberNavbar"

export default function Dashboard() {

    const { currentUser } = useAuth()
    
    const navigate = useNavigate()

    const { mutateUserProfile, userProfile } = useAuth()
    
    useEffect(() => {
      async function checkUserFirestore() {
        const uuid = currentUser.uid
        let user;
        try {
          user = await getUser(uuid)
          console.log("user")
          if (user){
            mutateUserProfile(user)
          } else {
            createNewUser(uuid, currentUser.email)
          }
        } catch (error) {
          window.alert(error)
        }
      }

      checkUserFirestore()

    }, [])


  return (
    <div>
      {
        userProfile && (userProfile.authLevel === "officer" || userProfile.authLevel === "admin") ?  <OfficerNavbar /> : <MemberNavbar />
      }
      <div id="modal" />
      <Outlet />
    </div>
  )
}
