import React, { useState, useEffect } from 'react'
import { uploadNewEvent, getAllEvents, addResourceSectionToDatabase, resourcesSectionExists, addResourceToEvent, clearResources, getOneEvent, setCELink } from '../../Firebase/Firestore-Query'
import EventDisplay from '../../Components/EventDisplay'
import { Alert } from 'react-bootstrap'
import { generateDLCReport } from '../../Firebase/Firestore-Query'
import { getAllUsers, getUser } from '../../Firebase/Users-CRUD'
import { useAuth } from '../../AuthContext'

export default function ManageEvents() {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)
  const [isTest, setIsTest] = useState(true)
  const [eventType, setEventType] = useState("")
  const [listOfEvents, setListOfEvents] = useState([])
  const [showReport, setShowReport] = useState(false)
  const [showMembership, setShowMembership] = useState(false)
  const [membershipReport, setMembershipReport] = useState([])
  const [eventTableList, setEventTableList] = useState([])
  const [user, setUser] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState({})
  const [selectedEventResources, setSelectedEventResources] = useState({})


  const { currentUser } = useAuth()

  useEffect( () => {
    async function loadEvents() {
      const eventList = await getAllEvents()
      setListOfEvents(eventList)
      const us = await getUser(currentUser.uid)
      setUser(us)
    }

     loadEvents()
  }, [])

  const handleWriteNewEvent = async (e) => {
    e.preventDefault()
    setLoading(true)
    let objToPost = {}

    if (isTest){
      objToPost.isTest = true
      objToPost.Name = document.getElementById("event-name-input").value
      objToPost.Event_Type = "Objective Test"

      /**
       * 
       */
    } else {
      objToPost.isTest = false
      if (document.getElementById("prejudged-input-yes").checked){

        objToPost.isPrejudged = true
      } else {
        objToPost.isPrejudged = false
      }
      objToPost.Event_Type = document.getElementById("event-type-input").value

      objToPost.Name = document.getElementById("event-name-input").value
      if (eventType == "Team"){

        objToPost.Max_Team_Members = document.getElementById("max-team-members").value
        objToPost.Can_Have_Team = true
      }
    }

    try {

      await uploadNewEvent(objToPost, user.General_Registration.Name)
      setListOfEvents(() => [objToPost, ...listOfEvents])
      document.getElementById("create-new-event-form").reset()
      setLoading(false)
      setMessage(true)
           
    } catch (error) {
      window.alert(error)
    }
  }


  const handleEventTypeClick = () => {
    if (document.getElementById("event-type-input-1").checked){
      setIsTest(true)
    } else{
      setIsTest(false)
    }
  }
  
  const handleTeamTypeClick = () => {
    if (document.getElementById("team-type-input-1").checked){
      setEventType("Individual")
    } else if (document.getElementById("team-type-input-2").checked){
      setEventType("Team")
    } else {
      setEventType("Chapter")
    }
  }
  
  const handleEventSelectUpdate = async (eventName) => {
    try {
      const event = await getOneEvent(eventName)
      console.log(event)
      await setSelectedEvent(event)
      console.log(selectedEvent)
      console.log(selectedEvent.Resources)

      const exists = await resourcesSectionExists(eventName)
      if (!exists) {
        await addResourceSectionToDatabase(eventName)
      } 
      selectedEvent.Resources ? setSelectedEventResources(event.Resources) : setSelectedEventResources({})
      
    } catch (error) {
      console.error(error)
      console.log("Error getting event")
    }
    console.log(selectedEvent)
    console.log(selectedEventResources)
  }

  const handelAddResource = async () => {
    const name = document.getElementById("resource-name").value
    const link = document.getElementById("resource-link").value
    await addResourceToEvent(selectedEvent.Name, name, link)
    const tmpEventResources = {...selectedEventResources}
    tmpEventResources[name] = link
    setSelectedEventResources(tmpEventResources)
    console.log(selectedEventResources)
    
    
  }
  
  const handleDeleteResource = () => {
    clearResources(selectedEvent.Name)
    setSelectedEventResources({})
  }

  const handleCELink = () => {
    const link = document.getElementById("ce-link").value
    setCELink(selectedEvent.Name, link)
  }
  




  return (
    <div>
      <h2 class = "fw-bold text-center mb-5">MANAGE EVENTS</h2>
      
      <div class="create-new-event bg-light rounded p-3 mx-3 my-5 container">
        <h3 class="text-center mt-2 mb-5 row justify-content-center">CREATE A NEW EVENT</h3>
        { loading && <Alert variant="warning">Loading</Alert> }
        { message && <Alert variant="success">Event Added to System</Alert> }
        <form onSubmit={handleWriteNewEvent} id="create-new-event-form">
          <label class="form-label fw-bold text-center col-12">Event Type: </label>
          <div class="mb-2 col-12 text-center">
            <input type="radio" name="isTesting" class="form-check-input" id="event-type-input-1" onClick={handleEventTypeClick}/>
            <label class="form-check-label" for="event-type-input-1">Objective Test</label>
          </div>
          <div class="mb-4 col-12 text-center">
            <input type="radio" name="isTesting" class="form-check-input" id="event-type-input-2" onClick={handleEventTypeClick}/>
            <label class="form-check-label" for="event-type-input-2" >Live Event</label>
          </div>

          {
            isTest && isTest === true ? 
            
            <div class="objective-test-input fw-bold text-center row my-5 justify-content-center">
              <label class="form-control-label mb-2">Event Name: </label>
              <input type="text" required class="form-control w-50 text-center col-6" placeholder="Event Name" id="event-name-input" /><br></br>
            </div> 
            :
            <div class="live-event-input text-center row my-5 justify-content-center">
              <label class="new-event-label mb-2 fw-bold">Event Name: </label>
              <input type="text" required class="form-control w-25 text-center col-6 mb-4" id="event-name-input" placeholder="Event Name"/><br></br>
              
              <label class="new-event-label mb-2 fw-bold">Event Type: </label>
              <input type="text" required class="form-control w-25 text-center col-6 mb-4" id="event-type-input" placeholder="Event Type"/><br></br>
              
              <label class="new-event-label mb-2 fw-bold">Is this event prejudged? </label><br></br>
              <div class="mb-2">
                <input type="radio" class="form-check-input" id="prejudged-input-yes" name="prejudged"/>
                <label class="form-check-label" for="prejudged-input-yes">Yes</label><br></br>
              </div>
              <div class="mb-4">
                <input type="radio" class="form-check-input" id="prejudged-input-o" name="prejudged"/>
                <label class="form-check-label" for="prejudged-input-no">No</label><br></br>
              </div>
              
              <label class="new-event-label mb-2 fw-bold">Team Member Type: </label><br></br>
              
              <div class="mb-2">
                <input type="radio" class="form-check-input" id="team-type-input-1" name="team-type" onClick={handleTeamTypeClick}/>
                <label class="form-check-label" for="team-type-input-1">Individual</label><br></br>
              </div>

              <div class="mb-2">
                <input type="radio" class="form-check-input" id="team-type-input-2" name="team-type" onClick={handleTeamTypeClick}/>
                <label class="form-check-label" for="team-type-input-2">Team</label><br></br>
              </div>
              {
                eventType === "Team" ? 
                <>

                  <label class="new-event-label my-2 fw-bold">Max Team Members: </label>
                    <input type="number" class="form-control w-25 row text-center mb-4 " id="max-team-members" />
                </> : <></>
              }
              <div class="mb-4">
                <input type="radio" class="form-check-input" id="team-type-input-3" name="team-type" onClick={handleTeamTypeClick}/>
                <label class="form-check-label" for="team-type-input-3">Chapter</label><br></br>
              </div>
            </div>
          }
          <div class="row justify-content-center mt-4">
            <button type="submit" class="btn btn-primary my-3 p-2 col-4">Create Event</button>
          </div>
        </form>

      </div>
      <div class="event-list bg-light rounded p-3 mx-3 my-5 container">
        <h3 class="text-center mt-3 mb-5 row justify-content-center">CURRENT EVENTS</h3>
        <div class="event-blocks row justify-content-center">
          {
            listOfEvents.map((event, i) => {
              return <EventDisplay class="col" eventData={event} key={i} />
            })
          }
        </div>
      </div>
      <h3 class="text-center mt-3 mb-5 row justify-content-center">Event Edit</h3>
      <p class="text-center">Select an event to edit from the drop down</p>
      <div class="row justify-content-center">
        <select class="form-select w-25" onChange={(e) => handleEventSelectUpdate(e.target.value)}>
          <option selected>Select Event</option>
          {
            listOfEvents.map((event, i) => {
              return <option value={event.Name}>{event.Name}</option>
            })
          }
        </select> 
        {
          selectedEvent ? <div>
          <h2 class="text-center">Details</h2>
          <p class="text-center">Name: {selectedEvent ? selectedEvent.Name : ""}</p>
          <p class="text-center">Event Type: {selectedEvent ? selectedEvent.Event_Type : ""}</p>
          <p class="text-center">Max Team Members: {selectedEvent ? selectedEvent.Max_Team_Members : ""}</p>
          <p class="text-center">Prejudged: {selectedEvent ? selectedEvent.isPrejudged : ""}</p>
          <p class="text-center">Test: {selectedEvent ? selectedEvent.isTest : ""}</p>
          <p class="text-center">Competitive Event Guide Link: {selectedEvent.CE_Link ? selectedEvent.CE_Link : ""}</p>
          <h2 class="text-center">Resources</h2>
          <p class="text-center">You may need to select a different event and reselect the event you want to edit to view the resources</p>
          <ul>
          {
            selectedEvent && selectedEventResources && Object.entries(selectedEventResources).map(([key, value]) => {
              console.log(value, key, value.Name, value.Link)
              return <li key={key}><a href='{value}' >{key}</a></li>
            })
          }
          </ul>

          <p class="text-center">Add Competitive Event Guide Link</p>
          <div class="row justify-content-center">
            <input type="text" class="form-control w-25 text-center" id="ce-link" placeholder="Competitive Event Link" />
            <button class="btn btn-primary w-25" onClick={() => handleCELink()}>Add Link</button>
          </div>

          <p class="text-center">Add Resources</p>
          <p class="text-center">Fill in the link and name and add the resource</p>

          <div class="row justify-content-center">
            <input type="text" class="form-control w-25 text-center" id="resource-name" placeholder="Resource Name" />
            <input type="text" class="form-control w-25 text-center" id="resource-link" placeholder="Resource Link" />
            <button class="btn btn-primary w-25" onClick={() => handelAddResource()}>Add Resource</button>
          </div>
          <p class="text-center">Delete Resources</p>
          <div class="row justify-content-center">
            <button class="btn btn-primary w-25" onClick={handleDeleteResource}>Clear all Resources for Event</button>
          </div>
          </div>
          : ""}


      </div>

      
    </div>
  )
}
