import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from '../AuthContext'
import { Link, useNavigate } from "react-router-dom"


export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const navigate = useNavigate()

    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const { login, forgotPassword } = useAuth()

    async function handleSubmit(e) {
        setSuccess(false)
        e.preventDefault()

        
        setError("")
        setLoading(true)
        forgotPassword(emailRef.current.value).then(() => {
            setSuccess(true)
        }).catch(err => {
            setError(err.code)
        })
        setLoading(false)

    }
  return (
    <>
        <Card>
            <Card.Body>
                <div class="container text-center">
                    <div class="row my-5 bg-light rounded p-5">
                        <div class="col gap-4">
                            <div class="fs-5">Grandview High School</div>
                            <div class="fs-4 fw-bold mb-5">FUTURE BUSINESS LEADERS OF AMERICA</div>
                            <div class="fs-4 fw-bold mb-4">FORGOT PASSWORD</div>
                        </div>
                    
                        {error && <Alert variant="danger">{error}</Alert>}
                        {success && <Alert variant="success">An email containing a PASSWORD RESET link was sent</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <div class="row mb-3 px-4">
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                            </div>
                        
                            <div class="row my-4 px-4 justify-content-center">
                                <div class="col-6">
                                    <Button disabled={loading} className="w-100 mt-2" type="submit">Send Password Reset Email</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
               
            </Card.Body>
        </Card>
        <div class="fw-bold text-center my-2">
            <Link to="/login">Login</Link> <br></br>
            Need an account? <Link to="/register">Register</Link>
        </div>
    </>
  )
}
