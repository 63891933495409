import React from "react"
import { AuthProvider, useAuth } from "./AuthContext"
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom"
import Register from "./Pages/Register"
import Login from "./Pages/Login"
import ForgotPassword from "./Pages/ForgotPassword"
import Dashboard from "./Pages/Dashboard"
import PrivateRoute from "./Route Verification/PrivateRoute"
import AuthLevelRoute from "./Route Verification/AuthLevelRoute"
/**
 * Core Subpages Import
 */
import MemberProfile from "./Pages/Core Subpages/MemberProfile"
import Conference from "./Pages/Core Subpages/Conference"
import EventView from "./Pages/Core Subpages/EventView"
import Contact from "./Pages/Core Subpages/Contact"
import Home from "./Pages/Core Subpages/Home"
import Officers from "./Pages/Core Subpages/Officers"

/**
 * Officer Core Subpages Import
 */
import ManagePayments from "./Pages/Officer Subpages/ManagePayments"
import ManageMembers from "./Pages/Officer Subpages/ManageMembers"
import ManagePermissionForms from "./Pages/Officer Subpages/ManagePermissionForms"
import ManageEvents from "./Pages/Officer Subpages/ManageEvents"
import Admin from "./Pages/Officer Subpages/Admin"
import OfficerSubNavbar from "./Components/OfficerSubNavbar"
import Reports from "./Pages/Officer Subpages/Reports"
import AuditLog from "./Pages/Officer Subpages/AuditLog"
import ScheduledMaintenence from "./Pages/ScheduledMaintenence"
import OfficerProfiles from "./Pages/Officer Subpages/OfficerProfiles"
import UserFormUpload from "./Pages/Officer Subpages/UserFormUpload"


import UploadDLCRatingSheets from "./Pages/Officer Subpages/Admin Sub Pages/DLC Admin Sub Pages/UploadDLCRatingSheets"
import InputDLCEventPlacement from "./Pages/Officer Subpages/Admin Sub Pages/DLC Admin Sub Pages/InputDLCEventPlacement"
import ResetDLCEventPlacement from "./Pages/Officer Subpages/Admin Sub Pages/DLC Admin Sub Pages/ResetDLCRegistration"
import ViewSLCCommittment from "./Pages/Officer Subpages/Admin Sub Pages/SLC Admin Sub Pages/ViewSLCCommittment"
import StatePermissionForms from "./Pages/Officer Subpages/Admin Sub Pages/SLC Admin Sub Pages/StatePermissionForms"
import SLC_Gen_Reg from "./Pages/Officer Subpages/Admin Sub Pages/SLC Admin Sub Pages/SLC_Gen_Reg"
import Rooming from "./Pages/Officer Subpages/Admin Sub Pages/SLC Admin Sub Pages/Rooming"
import MasterList from "./Pages/Officer Subpages/Admin Sub Pages/SLC Admin Sub Pages/MasterList"
import View_Roster from "./Pages/Officer Subpages/Admin Sub Pages/General Chapter Management Sub Pages/View_Roster"
import UserAuth_Level from "./Pages/Officer Subpages/Admin Sub Pages/General Chapter Management Sub Pages/UserAuth_Level"
import PermissionFormsStatus from "./Pages/Officer Subpages/Admin Sub Pages/General Chapter Management Sub Pages/PermissionFormsStatus"
import GeneralChapterFees from "./Pages/Officer Subpages/Admin Sub Pages/General Chapter Management Sub Pages/GeneralChapterFees"
import UUIDTable from "./Pages/Officer Subpages/Admin Sub Pages/General Chapter Management Sub Pages/UUIDTable"




function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <Routes>
            <Route exact path="/" element={ <Navigate to="/home/memberProfile" /> } />
            <Route exact path="/home" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } >


              {/* Core Routes - All Members */}
              <Route path="memberProfile" element={ <MemberProfile /> } />
              <Route path="conference" element={ <Conference /> } />
              <Route path="eventView" element={ <EventView /> } />
              <Route path="officersView" element={ <Officers /> } />
              
              <Route path="home" element= { <Home />} />
              
              {/* Officer Routes */}
              <Route path="officers" element={ <AuthLevelRoute> <OfficerSubNavbar /> </AuthLevelRoute>} >

                <Route path="managePermissionForms" element={ <AuthLevelRoute> <ManagePermissionForms /> </AuthLevelRoute> } />
                <Route path="managePayments" element={ <AuthLevelRoute> <ManagePayments /> </AuthLevelRoute> }  />
                <Route path="manageEvents" element={ <AuthLevelRoute> <ManageEvents /> </AuthLevelRoute> } />
                <Route path="manageMembers" element={ <AuthLevelRoute> <ManageMembers /> </AuthLevelRoute>} />
                <Route path="reporting" element={ <AuthLevelRoute> <Reports /> </AuthLevelRoute>} />
                <Route path="auditLog" element={ <AuthLevelRoute> <AuditLog /> </AuthLevelRoute>} />
                <Route path="officerProfiles" element={ <AuthLevelRoute> <OfficerProfiles /> </AuthLevelRoute>} />
                <Route path="userFormUpload" element={ <AuthLevelRoute> <UserFormUpload /> </AuthLevelRoute>} />
              </Route>

              {/* Admin Route */}
              <Route path="admin" element={ <AuthLevelRoute> <Admin /> </AuthLevelRoute> }>
                <Route path="upload-dlc-rating-sheets" element={<AuthLevelRoute> <UploadDLCRatingSheets /> </AuthLevelRoute>} />
                <Route path="input-dlc-event-placement" element={<AuthLevelRoute> <InputDLCEventPlacement /> </AuthLevelRoute>} />
                <Route path="reset-member-dlc-registration" element={<AuthLevelRoute> <ResetDLCEventPlacement /> </AuthLevelRoute>} />
                <Route path="view-committment-responses" element={<AuthLevelRoute> <ViewSLCCommittment/> </AuthLevelRoute>} />
                <Route path="state-permission-forms" element={<AuthLevelRoute> <StatePermissionForms/> </AuthLevelRoute>} />
                <Route path="slc-gen-reg" element={<AuthLevelRoute> <SLC_Gen_Reg/> </AuthLevelRoute>} />
                <Route path="rooming" element={<AuthLevelRoute> <Rooming/> </AuthLevelRoute>} />
                <Route path="master-list" element={<AuthLevelRoute> <MasterList/> </AuthLevelRoute>} />
                <Route path="view-gen-roster" element={<AuthLevelRoute> <View_Roster/> </AuthLevelRoute>} />
                <Route path="auth-level-change" element={<AuthLevelRoute> <UserAuth_Level/> </AuthLevelRoute>} />
                <Route path="general-permission-forms" element={<AuthLevelRoute> <PermissionFormsStatus/> </AuthLevelRoute>} />
                <Route path="general-dues-status" element={<AuthLevelRoute> <GeneralChapterFees/> </AuthLevelRoute>} />
                <Route path="uuids" element={<AuthLevelRoute> <UUIDTable/> </AuthLevelRoute>} />

              </Route>

            </Route>

            <Route path="/register" element={ <Register /> } />
            <Route path="/login" element={ <Login /> } />
            <Route path="/forgotPassword" element={ <ForgotPassword /> } />
          </Routes>
        </AuthProvider>
      </Router>
      <div class="credits position-relative bg-secondary p-3">
        <p class=" text-center text-light">Grandview FBLA Chapter Management System - Rudra Goel, Mimi Rai, Austin Zhang, Grant Yoder. Pending Rights Reserved. </p>
        <p class=" text-center text-light">Contact gyoder@cherrycreekschools.org for any issues regarding developmental bugs</p>
      </div>
    </div>
  );
}

export default App;


