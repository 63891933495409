import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { getAllUsers, updateUser, getUser, resetSLCGeneral } from '../../../../Firebase/Users-CRUD'

export default function GeneralChapterFees() {

    const [members, setMembers] = useState([])
    const [loadingState, setLoadingState] = useState({show: false})
    const [statePayments, setStatePayments] = useState([])

    useEffect(() => {

        async function loadUsers() {

            const users = await getAllUsers()

            let temp = []

            users.map((user) => {
                temp.push(user)
            })
            setMembers(temp)

        }
        loadUsers()
    }, [])




  return (
    <div>
      <h3 class="text-center">Grandview FBLA Chapter Dues Status</h3>
      { loadingState.show && <Alert variant={`${loadingState.variant}`} >{loadingState.message}</Alert>}
      <table class="table">
        <thead>
            <tr>
                <th class="" scope="col">Name</th>

            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Unimplimented</td>
            </tr>           
        </tbody>
      </table>
    </div>

  )
}
