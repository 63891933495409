import React, { useState, useEffect, useReducer } from 'react'
import { Alert } from 'react-bootstrap'
import { getAllUsers } from '../../Firebase/Users-CRUD'
import { getStateRegistration } from '../../Firebase/Firestore-Query'
import SLC_Commitment from "../../Components/State Registration Components/SLC_Commitment"
import SLC_General_State_Items from '../../Components/State Registration Components/SLC_General_State_Items'
import SLC_Event from '../../Components/State Registration Components/SLC_Event'
import State_Rooming from '../../Components/State Registration Components/State_Rooming'
export default function SLC_Registration({ user }) {

    const [loading, setLoading] = useState({show:false})
    const [active, setActive] = useState(false)

    useEffect(() => {
        const loadVariables = async () => {
            const sr = await getStateRegistration()
            setActive(sr.Enabled)
        }

        loadVariables()
    }, [])

    //user exists, DID NOT complete commitment form
    if(user && !user.State_Registration){
        return <SLC_Commitment user={user} />
    }

    
    if (user && user.State_Registration && (user.State_Registration.Commitment.Choice === "NOT_COMMIT" || user.State_Registration.Commitment.Choice === "NOT_BUMPED_UP")){
        return (
            <div>
                <h4>Not Applicable</h4>
            </div>
        )
    }
    
    //user exists, user completed commitment ONLINE, but they DID NOT complete First State Payment
    if (user && user.State_Registration && !user["State First Payment"].Paid){
        return (
            <div>
                <h4>You have not paid your First State Payment</h4>
                <p class="fst-italic mt-2">You may have paid this already, but has not been processed yet. Please check back periodically.</p>
                <a class="link-primary mt-2 mb-2 " target="_blank" href="https://cherrycreek.revtrak.net/high-schools/ghs/ghs-clubs-and-activities/ghs-fbla-fees-2/#/list">Link to RevTrack</a>
            </div>
        )
    }


    //user exists, user completed commitment ONLINE, but they DID NOT complete forms
    if (user && user.Forms) {
        for (let form in user.Forms) {
            if (!user.Forms[form].Completed){
                return (
                    <div>
                        <h4>You have not completed all your forms</h4>
                        <p class="fst-italic mt-2">Please go to the <b>Profile</b> tab and upload new forms.</p>
                    </div>
                )
            }
        }
    } else {
        return (
            <div>
                <h4>An Error Occured. no user or user.Forms. Please contact gyoder@cherrycreekschools.org</h4>
            </div>
        )
    }
    /*
    if (user && user.State_Registration && !user.State_Registration.CommitmentForm){
        return (
            <div>
                <h4>You have not turned in your State Qualifying Letter</h4>
                <p class="fst-italic mt-2">You may have turned this in already, but has not been processed yet. Please check back periodically.</p>
                <a class="link-primary mt-2 mb-2 " target="_blank" href="https://drive.google.com/file/d/1gPjpvPjxctYr0lrCKpdPtSmaANZihrnv/view?usp=sharing">Link to PDF of Letter</a>
            </div>
        )
    }*/
    
    /**
     * Components we need for Registration
     * 
     * 1. General Items
     *  ---> Guest Passes for Closing Ceremony
     *  ---> Thursday lunch
     *  ---> Thursday Night Entertainment Options
     *  ---> Wednesday Lunch
     * 
     * 2. Rooming
     *  ---> Roommates x 3
     *  ---> Gender Identity
     * 
     * 3. Events
     *  ---> Event name
     *  ---> Partners/Team members
     */

    if (user){
        if (active){
            return (
                <div>
                    <div class="state-payment bg-light rounded p-3 mb-4">
                        <h4 class="text-center">State Payment</h4>
                        {
                            Object.keys(user).map((key) => {
                                if (user[key].Amount && user[key].StateTag){
                                    return <>
                                        <div class="payment ms-3">
                                            <h5>{key}: {user[key].Amount}</h5>
                                            {
                                                user[key].Paid ? <p class="bg-success text-white fw-bold text-center align-middle w-25">PAID IN FULL</p> : <p class="bg-danger w-25 text-center text-white fw-bold align-middle">NOT COMPLETE</p>
                                            }
                                        </div>
                                    </>
                                }
                            })
                        }
                        {
                            user && user.State_Registration.General_SLC_Registration && <h5 class="ms-3 mt-3">Additional Charges: Add ${user.State_Registration.General_SLC_Registration.AddedCharge} to your Second State Payment</h5>
                        }
                        
                        <a class="link-primary mt-5 mb-2 ms-3 " target="_blank" href="https://cherrycreek.revtrak.net/high-schools/ghs/ghs-clubs-and-activities/ghs-fbla-fees-2/#/list">Pay via RevTrack</a>
                        <p class="fst-italic mt-4 ms-3">Please email all RevTrack reciepts to officers for the fastest processing</p>
                        

                    </div>
                    <div class="state-gen">    
                        {
                            user && !user.State_Registration.General_SLC_Registration ? <SLC_General_State_Items user={user} /> : 
                            <div class="show-gen-reg bg-light rounded p-3 mb-4">
                                <h4 class="text-center">General Registration Items</h4>
                                <div class="meals ms-3">
                                    <h5>Meals</h5>
                                    <p>Wednesday Lunch: {user.State_Registration.General_SLC_Registration.WednesdayLunch}</p>
                                    <p>Thursday Lunch: {user.State_Registration.General_SLC_Registration.ThursdayLunch}</p>
                                </div>
                                <div class="Entertainment ms-3">
                                    <h5>Entertainment</h5>
                                    { user.State_Registration.General_SLC_Registration.Hypnotist &&  <p>Hypnotist Thusday Night</p>}
                                    { user.State_Registration.General_SLC_Registration.Trivia &&  <p>Trivia Game Thusday Night</p>}                        
                                </div>
                                
                                <div class="pass ms-3">
                                    <h5>Additonal Charges</h5>
                                    <p class="fst-italic">Additional charges were incurred by either/or hypnotist/comedy night</p>
                                    <p>Total: ${user.State_Registration.General_SLC_Registration.AddedCharge}</p>
                                </div>
                                <div class="timestamp ms-3">
                                    <p class="fst-italic">Submitted At: {user.State_Registration.General_SLC_Registration.TimeStamp}</p>
                                </div>
                            </div>
                        }
                    </div>
                    <div class="state-event">
                        {
                            user && !user.State_Registration.SLC_Event ? <SLC_Event user={user} /> : 
                            <div class="show-gen-reg bg-light rounded p-3 mb-4">
                                <h4 class="text-center">SLC Event Registration</h4>

                                <div class="eventName ms-3">
                                    <h5>{ user.State_Registration.SLC_Event.Event_Name }</h5>
                                </div>

                                <div class="timestamp ms-3">
                                    <p class="fst-italic">Submitted At: {user.State_Registration.SLC_Event.TimeStamp}</p>
                                </div>
                            </div>
                        }
                    </div>
                    
                    <div class="state-event">
                        {
                            user && !user.State_Registration.Rooming ? <State_Rooming user={user} /> : 
                            <div class="show-gen-reg bg-light rounded p-3 mb-4">
                                <h4 class="text-center">Rooming Requests</h4>
                                <div class="mt-4" ms-3>
                                    <Alert variant="warning">Roomming requests are hidden for now</Alert>
                                </div>
                                <div class="timestamp ms-3">
                                    <p class="fst-italic">Submitted At: {user.State_Registration.Rooming.TimeStamp}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div class="bg-light rounded p-3 mb-4">
                    <p class="fst-italic">State General, Event, and Rooming Registration has not opened yet</p>
                </div>
            )
        }
    } else {
        return <div></div>
    }
}
