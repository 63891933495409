import React, { useEffect, useState } from 'react'
import { useAuth, shallowEqual } from "../../AuthContext"
import { getUser } from "../../Firebase/Users-CRUD"
import RegisterEvent from "../../Components/RegisterEvent"
import SLC_Registration from './SLC_Registration'
import RegisterSecondEvent from '../../Components/RegisterSecondEvent'
import { Alert } from 'react-bootstrap'
import {  downloadRatingSheets } from '../../Firebase/Firestore-Query'


export default function Conference() {

  const { currentUser, mutateUserProfile, userProfile  } = useAuth() 
  const [eventOneModal, setEventOneModal] = useState(false)
  const [eventTwoModal, setEventTwoModal] = useState(false)
  const [isElegible, setIsElegible] = useState({eligible: true})
  const [isDistrictsReady, setIsDistrictsReady] = useState(false)

  const [dlcDownloadLoading, setDLCDownloadLoading] = useState(false)

  async function checkUserFirestore() {
    const uuid = currentUser.uid
    let user;
    try {
      user = await getUser(uuid)
      if (userProfile && !shallowEqual(user, userProfile)) {
        mutateUserProfile(user)
      }
      
      
      let needPayment = false
      let needForms = false
      Object.keys(user).forEach(key => {
        if (user[key]?.DistrictsTag && !user[key].Paid){
          console.log(user)
          needPayment = true
        }
      })
      for (let i = 0; i < user["Forms"].length; i++) {
        if (!user["Forms"][i].Completed){
          needForms = true
        }
      }
      
      
      if (needForms && needPayment ){
        setIsElegible({elegible: false, message: "You must submit your pending payments AND permission forms in order to register for events"})
      } else if (needForms){
        setIsElegible({elegible: false, message: "You must submit your permission forms in order to register for events"})
      } else if (needPayment)
        setIsElegible({elegible: false, message: "You must submit your pending payment(s) in order to register for events"})
      console.log(needForms)
      console.log(needPayment)
      if (!needForms && !needPayment && userProfile.Districts_Registration && (userProfile.Districts_Registration.Event_One && userProfile.Districts_Registration.Event_Two)){
        setIsDistrictsReady(true)
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {

    checkUserFirestore()

  }, [])

  const handleDownloadDLCEventOneSheets = async () => {
    setDLCDownloadLoading(true)
    //The team captain name or the name on the file
    let name
    
    if (userProfile.Districts_Registration.Event_One.TeamCaptain){
      name = userProfile.Districts_Registration.Event_One.TeamCaptain
    } else {
      name = userProfile.General_Registration.Name
    }
    
    //get the name of the event
    let eventName
    
    eventName = userProfile.Districts_Registration.Event_One.Name
    
    await downloadRatingSheets(name, eventName)
    setDLCDownloadLoading(false)
  }
  
  const handleDownloadDLCEventTwoSheets = async () => {
    setDLCDownloadLoading(true)
    //The team captain name or the name on the file
    let name
    
    if (userProfile.Districts_Registration.Event_Two.TeamCaptain){
      name = userProfile.Districts_Registration.Event_Two.TeamCaptain
    } else {
      name = userProfile.General_Registration.Name
    }
    
    //get the name of the event
    let eventName
    
    eventName = userProfile.Districts_Registration.Event_Two.Name
    
    await downloadRatingSheets(name, eventName)
    setDLCDownloadLoading(false)
  }
  
  return (
    <div class="competition-container ms-3">
      <div class="dlc row m-5  border border-4 border-secondary rounded p-5">
        <h3 class="dlc-header text-center fw-bold mb-4">District Leadership Conference (DLC)</h3>
        <div class="ready-message">
          {
            isDistrictsReady ? <Alert variant="success">You are ready for Districts!</Alert> : <></>
          }
          {
            dlcDownloadLoading ? <div class="mt-3"><Alert variant="warning">Loading</Alert></div> : <></>
          }
        </div>
        <div  class="comp-guide mb-3">
          <a  href="https://www.fbla.org/divisions/fbla/fbla-competitive-events/" target="_blank">Link to Competitive Events Guide</a>
        </div>

        {
          userProfile && userProfile.General_Registration && isElegible.eligible? 
          <>
            <div class="events-registration bg-light rounded p-3 mb-4"> 
              <h4 class="events-header text-center">DLC EVENTS</h4>
              <div class="event-one my-3">
                <h5 class="event-name ">Event #1</h5>
                {
                  userProfile && !userProfile.Districts_Registration ? 
                  <div class="register-events">
                    <label class="fst-italic mb-2 mt-2" >Click below to register for your competitive event</label><br></br>
                    <button class="btn btn-primary" onClick={() => {setEventOneModal(true)}}>Register Event</button>
                    { eventOneModal && <RegisterEvent setOpenModal={setEventOneModal} checkUserFirestore={checkUserFirestore}/> }
                  </div>
                  :
                  <div class="events-registered">
                    {
                      userProfile.Districts_Registration.Event_One.Place ? <h6 class="m-2">Place: { userProfile.Districts_Registration.Event_One.Place }</h6> : <></>
                    }
                    {
                      userProfile.Districts_Registration.Event_One.HasRatingSheets ? 
                      <div>
                        <button class="btn btn-primary" onClick={handleDownloadDLCEventOneSheets}>Download Rating Sheet(s)</button>
                      </div> : <></>
                    }
                    {
                      userProfile.Districts_Registration.Event_One?.Team_Members ? 
                      
                      <>
                        <label class="m-2">{ userProfile.Districts_Registration.Event_One.Name }</label><br></br>
                        <label class="m-2">Team Members: </label>
                        <ul>
                          {
                            userProfile.Districts_Registration.Event_One.Team_Members.map((member) => {
                              return <li>{member}</li>
                            })
                          }
                        </ul>
                      </> 
                      
                      : 
                      
                      <>
                        {
                          userProfile.Districts_Registration.Event_One?.Name ? <label class="m-2 ">{ userProfile.Districts_Registration.Event_One.Name }</label> : <>None</>
                        }
                        
                      </>
                    }
                    {
                      userProfile.Districts_Registration.Event_One?.TeamCaptain && <div><label>Team Captain: {userProfile.Districts_Registration.Event_One.TeamCaptain}</label></div>
                    }
                  </div>
                }
              </div>
              
              <div class="event-two my-3">
              <h5 class="event-name mt-4">Event #2</h5>
                {
                  userProfile && userProfile.Districts_Registration && userProfile.Districts_Registration.Event_One && !userProfile.Districts_Registration.Event_Two? 
                  <div class="register-events">
                    <label class="fst-italic mb-2 mt-2">Click below to register for your second competitive event</label><br></br>
                    <button class="btn btn-primary" onClick={() => {setEventTwoModal(true)} } >Register Event</button>
                    
                    { eventTwoModal && <RegisterSecondEvent setOpenModal={setEventTwoModal} checkUserFirestore={checkUserFirestore}/> }
                  </div>
                  :
                  <>
                    {
                      userProfile && !userProfile.Districts_Registration ? 
                      <div class="complete-first-registration fst-italic">
                        Complete your first event registration in order to register for a second event
                      </div> :
                      <>
                        {
                          userProfile && userProfile.Districts_Registration && userProfile.Districts_Registration.Event_One && userProfile.Districts_Registration.Event_Two ? 
                          <div class="events-registered">
                            {
                              userProfile.Districts_Registration.Event_Two.Place ? <h6 class="m-2">Place: { userProfile.Districts_Registration.Event_Two.Place }</h6> : <></>
                            }
                            {
                              userProfile.Districts_Registration.Event_Two.HasRatingSheets ? 
                              <div>
                                <button class="btn btn-primary" onClick={handleDownloadDLCEventTwoSheets}>Download Rating Sheet(s)</button>
                              </div> : <></>
                            }
                            {
                              userProfile.Districts_Registration.Event_Two?.Team_Members ? 
                              <div class="event-display m-2">
                                <label class="m-2">{ userProfile.Districts_Registration.Event_Two.Name }</label><br></br>
                                <label class="m-2">Team Members: </label>
                                <ul>
                                  {
                                    userProfile.Districts_Registration.Event_Two.Team_Members.map((member) => {
                                      return <li>{member}</li>
                                    })
                                  }
                                </ul>
                              </div> 
                              
                              : 
                              
                              <>
                                {
                                  userProfile.Districts_Registration.Event_Two?.Name ? <label>{ userProfile.Districts_Registration.Event_Two.Name }</label> : <>None</>
                                }
                                
                              </>
                            }
                            {
                              userProfile.Districts_Registration.Event_Two.TeamCaptain && <div><label>Team Captain: {userProfile.Districts_Registration.Event_Two.TeamCaptain}</label></div>
                            }
                          </div>
                          :
                          <></>
                        }
                      </>
                    }
                  </>
                }
              </div>
            </div>
            <div class="meals-register bg-light rounded p-3 mb-4"> 
              <h4 class="events-header text-center">DLC MEALS</h4>
              <p>No special food requests. Your dietary restrictions will be accomodated for.</p>
              <p><strong>Your dietary restrictions: {userProfile && userProfile.General_Registration ? <>{userProfile.General_Registration.Dietary_Restrictions}</> : <></>}</strong></p>
            </div>
            <div class="payment-display bg-light rounded p-3 mb-4">
              <h4 class="events-header text-center">DLC Payment</h4>
              {
                userProfile.General_Registration && Object.keys(userProfile).map((key, i) => {
                  if(userProfile[key]?.DistrictsTag){
                    if (userProfile[key].Paid){
                      return <label class="text-center">Your payment for districts is completed and processed</label>
                    } else {
                      return <label class="text-center">Your payment for districts is NOT completed and processed</label>
                    }
                  }
                })
              }
            </div>
          </>
          : 
          <div class="no-register">
            {
              !isElegible.show ? <>{isElegible.message}</> : <>You must complete General Registration located in the "Profile" tab to register for events </>
            }
          </div>
        }
      </div>

      <div class="slc row m-5 border border-4 border-secondary rounded p-5">
        <h3 class="slc-header text-center fw-bold mb-4">State Leadership Conference (SLC)</h3>

        <SLC_Registration user={userProfile} />
        
      </div>
      <div class="nlc row m-5 border border-4 border-secondary rounded p-5">
        <h3 class="nlc-header text-center fw-bold mb-4">National Leadership Conference (NLC)</h3>
        <div class="nlc-events">
        {
            userProfile && userProfile.National_Registration ?
            <>
              {
                userProfile && userProfile.National_Registration.Event_Name ? 
                <>
                  {
                    userProfile.National_Registration.Is_Live_Event ? 
                    <>
                      <label class="event-name">Event Name: {userProfile.National_Registration.Event_Name}</label><br></br>
                      <label class="team-members">Team Members: </label>
                      {
                        userProfile.National_Registration.Team_Members.map(member =>{
                          return member
                        })
                      }
                      <br></br>
                      <label class="event-guide">Event Guide: LINK </label>
                    </> :
                    <>
                      <label class="event-name">Event Name: {userProfile.National_Registration.Event_Name}</label>
                    </>
                  }
                </> :
                <>
                  <label class="no-events-registered-label">No Events Currently Registered</label>
                </>
              }
            </> :
            <div>
              <label class="not-live-yet fst-italic text-center">Not Live Yet</label>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
