import React, { useEffect, useState } from 'react'
import { useAuth, shallowEqual } from "../../AuthContext"
import { Alert } from "react-bootstrap"
import { getUser } from "../../Firebase/Users-CRUD"
import { postUserGeneralRegistration } from "../../Firebase/Users-CRUD"
import PermissionFormUpload from "../../Components/PermissionFormUpload"


export default function MemberProfile() {
  const [forms, setForms] = useState([])
  const { currentUser, mutateUserProfile, userProfile, updateUserEmail, updateUserPassword  } = useAuth() 
  const [message, setMessage] = useState("")
  const [isPayment, setIsPayment] = useState(false)

  async function mountPage() {
    const uuid = currentUser.uid
    let user;
    try {
      user = await getUser(uuid)
      if (user.Forms.length === 0){
        setForms({list: user.Forms, hasForm: false})
      } else {
        setForms({list: user.Forms, hasForm: true})
      }
      console.log("user")
      if (userProfile && !shallowEqual(user, userProfile)) {
        mutateUserProfile(user)
      }


    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {

    mountPage()

  }, [])

  const handleEmailReset = async () => {
    try {
      console.log(document.getElementById('new-email').value)
      await updateUserEmail(document.getElementById("new-email").value, document.getElementById("current-password").value)
      setMessage("Email successfuly updated")
    } catch (error) {
      console.log(error)
    }
  }
  
  const handlePasswordReset = async () => {
    try {
      await updateUserPassword(document.getElementById('new-password').value, document.getElementById('old-password').value)
      setMessage("Password successfuly updated")
    } catch (error) {
      console.log(error)
    }
  }

  const handleGeneralRegistrationSubmit = async () => {
    setMessage("")
    if (!checkPhoneNumber()) {
      console.log("Submit")
      return setMessage("Please input phone number in XXX-XXX-XXXX format")
    }

    let name = ``
    if(document.getElementById("general-registration-nickname").value !== ""){
      name = `${document.getElementById("general-registration-first-name").value} (${document.getElementById("general-registration-nickname").value}) ${document.getElementById("general-registration-last-name").value}` 
    } else {
      name = `${document.getElementById("general-registration-first-name").value} ${document.getElementById("general-registration-last-name").value}` 

    }

    let generalRegObj = {
      Name: name,
      Phone_Number: document.getElementById("general-registration-phone-number").value,
    }
    const dr = document.getElementById("general-registration-dietary-restrictions").value
    if (dr) {
      generalRegObj.Dietary_Restrictions = dr
    } else {
      generalRegObj.Dietary_Restrictions = "N/A"
    }

    /**
     * get grade and t-shirt options
     */

    for (let i = 1; i < 5; i++){
      const id = `grade-${i}`
      if (document.getElementById(id).checked){
        generalRegObj.Grade = document.getElementById(id).value
        break
      }
    }
    
    for (let i = 1; i < 6; i++){
      const id = `shirt-size-${i}`
      if (document.getElementById(id).checked){
        generalRegObj.T_Shirt_Size = document.getElementById(id).value
        break
      }
    }

    try {
      await postUserGeneralRegistration( userProfile.UUID, generalRegObj)
      mountPage()
    } catch (error) {
      console.log(error)
    }



  }

  const checkPhoneNumber = () => {
    
    const phoneNumber = document.getElementById("general-registration-phone-number").value
    if (phoneNumber === "") {return true}

    const splitNum = phoneNumber.split("")

    if (splitNum[3] !== "-" || splitNum[7] !== "-") {return false}
    else {return true}
  }

  if (userProfile && !isPayment){
    Object.keys(userProfile).forEach(key => {
      if (userProfile[key]?.Amount){
        setIsPayment(true)
      }
    })
  }

  /**
   * If the user has not completed their general registration
   */
  if (userProfile && !userProfile.General_Registration){
    return (
      <div class="account-page-container container row my-5 bg-light rounded p-5">
        <div class="welcome-to-general-registration text-center fs-5 fw-bold">Welcome to Grandview FBLA! </div>
        <div class="text-center fs-4 fw-bold mb-4">Please Complete General Registration</div>


        <div class="input-general-information">
          <div class="name w-50">
            <label class="form-label mt-3 fw-bold">First Name: </label>
            <input class="form-control" placeholder="First Name" id="general-registration-first-name" type="text" required/>

            <label class="form-label mt-3 fw-bold">Last Name: </label>
            <input class="form-control" placeholder="Last Name" id="general-registration-last-name" type="text" required/>
            
            <label class="form-label mt-3 fw-bold">Nickname (Leave blank if N/A): </label>
            <input class="form-control" placeholder="Nickname" id="general-registration-nickname" type="text" required/>
          </div>
          
          <div class="grade mt-3">
            <label class="form-label fw-bold">Grade: </label>
            <div class="radio-btn-input ms-3">
              <input class="form-check-input" name="grade-input" id="grade-1" type="radio" value="Freshman"/>
              <label class="form-check-label ms-2" for="grade-1">Freshman</label><br></br>

              <input class="form-check-input" name="grade-input" id="grade-2" type="radio" value="Sophomore"/>
              <label class="form-check-label ms-2" for="grade-2">Sophomore</label><br></br>

              <input class="form-check-input" name="grade-input" id="grade-3" type="radio" value="Junior"/>
              <label class="form-check-label ms-2" for="grade-3">Junior</label><br></br>

              <input class="form-check-input" name="grade-input" id="grade-4" type="radio" value="Senior"/>
              <label class="form-check-label ms-2 mb-4" for="grade-4">Senior</label>
            </div>
          </div>
          {message && <Alert variant="danger">{message}</Alert>}
          <div class="number w-50 mt-3">
            <label class="form-label fw-bold">Phone Number: </label>
            <input class="form-control" placeholder="XXX-XXX-XXXX" id="general-registration-phone-number" type="text" required/>
          </div>
          
          <div class="mt-3">
            <label class="general-registration-field mt-3 fw-bold">T-Shirt Size: </label>
            <div class="radio-btn-input ms-3">
              <input class="form-check-input" name="shirt-size-input" id="shirt-size-1" type="radio" value="Small"/>
              <label class="form-check-label ms-2" for="shirt-size-1">Small</label><br></br>

              <input class="form-check-input" name="shirt-size-input" id="shirt-size-2" type="radio" value="Medium"/>
              <label class="form-check-label ms-2" for="shirt-size-2">Medium</label><br></br>

              <input class="form-check-input" name="shirt-size-input" id="shirt-size-3" type="radio" value="Large"/>
              <label class="form-check-label ms-2" for="shirt-size-3">Large</label><br></br>

              <input class="form-check-input" name="shirt-size-input" id="shirt-size-4" type="radio" value="XL"/>
              <label class="form-check-label ms-2" for="shirt-size-4">XL</label><br></br>
              
              <input class="form-check-input" name="shirt-size-input" id="shirt-size-5" type="radio" value="XXL"/>
              <label class="form-check-label ms-2" for="shirt-size-5">XXL</label>
            </div>
          </div>
          <div class="dietary-restrictions mt-3 w-50">
            <label class="form-check-label mt-3 fw-bold">Dietary Restrictions (Leave blank if N/A): </label>
            <input class="form-control" placeholder="Dietary Restrictions" id="general-registration-dietary-restrictions" type="text" required/>
          </div>
          
        </div>
        <button class="btn btn-primary mt-5" onClick={handleGeneralRegistrationSubmit}>Submit</button>
      </div>
    )
  }


  /**
   * If the user has completed their general registration
   */
  return (
    <div class="account-page-container container">
      <div class="general-info-block row my-5 ">
        <div class="general-info bg-light rounded p-5 text-dark">
          <div class="text-center fs-4 fw-bolder ">
            <label class="name">{userProfile && userProfile.General_Registration.Name} </label><br></br>
            {
              userProfile && userProfile.General_Registration.Nickname ? <label class="nickname">{ userProfile.General_Registration.Nickname }</label> : <></>
            }
          </div>
         
          <label class="school">Grandview High School</label><br></br>
          <label class="grade">Grade: {userProfile && userProfile.General_Registration.Grade}</label><br></br>
          <label class="phone">Phone Number: {userProfile && userProfile.General_Registration.Phone_Number}</label><br></br>
          <label class="dietary-restrictions">Dietary_Restrictions: {userProfile && userProfile.General_Registration.Dietary_Restrictions}</label><br></br>
        </div>
      </div>
      {message && <Alert variant="success">{message}</Alert>}
      
      <div class="row my-5 bg-light rounded p-5">
        <h3 class="text-center mb-5">RESET ACCOUNT CREDENTIALS</h3>
        <div class="email-password-reset row">

          <div class="reset-email col-lg-6 col-sm-12 my-4"> 
            <div class="fs-5 fw-bold mb-3 text-center">Email Reset</div> 
            <input type="password" id="current-password" class="form-control mt-3 mb-3" placeholder="Current Password" required />
            <input type="email" id="new-email" class="form-control mb-3" placeholder="New Email" required />
            <div class="text-center">
              <button class="btn btn-primary"  onClick={handleEmailReset}>Reset Email</button>
            </div>
          </div>
          
          <div class="password-reset col-lg-6 col-sm-12 my-4"> 
            <div class="fs-5 fw-bold mb-3 text-center">Password Reset</div> 
            <input type="password" id="old-password" class="form-control mt-3 mb-3" placeholder="Current Password" />
            <input type="password" id="new-password" class="form-control mb-3" placeholder="Updated Password" />
            <div class="text-center">
              <button class="btn btn-primary" onClick={handlePasswordReset}>Reset Password</button>
            </div>
          </div>
        </div>
      </div>

      <div class="payment row my-5 bg-light rounded p-5">
        
        <h3 class="text-center mb-5">PAYMENT STATUS</h3>
        <a class="link-primary mt-2 mb-2 " target="_blank" href="https://cherrycreek.revtrak.net/high-schools/ghs/ghs-clubs-and-activities/ghs-fbla-fee/#/list">Pay via RevTrack</a>
        {
          isPayment ? 
          <>
            {
              userProfile && Object.keys(userProfile).map((key, i) => {
                
                if (typeof userProfile[key].Amount === 'string'){
                  if (userProfile[key].StateTag && userProfile.State_Registration && (userProfile.State_Registration.Commitment.Choice === "NOT_COMMIT" || userProfile.State_Registration.Commitment.Choice === "NOT_BUMPED_UP")){
                    return (<></>)
                  } else if (userProfile[key].StateTag && !userProfile.State_Registration){
                    return (<></>)
                  }
                  
                  console.log(true)
                  return (
                    <>
                      <div class="fs-5 fw-bold">{ key } ({ userProfile[key].Amount }) </div>
                      {
                        userProfile[key].Paid ? <>Payment Completed & Processed</> : 
                        <>
                          <ul>
                            <li> Current Payment Status: ${ userProfile[key].Current_Payment } </li>
                          </ul>
                        </>
                      }
                    </>

                  )
                }
                
              })
            }
          </> 
          
          : 
          
          <div class="fst-italic">No Active Payments </div>
        }
      </div>
      <div class="permission-form-upload row my-5 bg-light rounded p-5">
        <h3 class="text-center mb-5">PERMISSION FORMS</h3>
          {
            forms.hasForm ? 
            <>
              {
                forms.list.map((form, i) => {
                  if (!form.Completed) {
                    return <PermissionFormUpload form={form} key={i} user={userProfile}/>
                  } else {
                    return (
                      <>
                        <div class="fs-5 fw-bold">{ form.Name }</div>
                        Uploaded and Recieved
                      </>
                    )
                  }
                })
              }
            </> 
            : 
            <>
              <div class="fst-italic text-center"> No Active Permission Forms </div>
            </>
          }
      </div>
      <div class="permission-forms-complete">
        
      </div>
    </div>
  )
}
