import React, { useContext, useState, useEffect } from 'react'
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, updateEmail, updatePassword, sendPasswordResetEmail } from "firebase/auth"
import { auth } from "./Firebase/Firebase-Config"



const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [userProfile, setUserProfile] = useState()

    function signup(email, password) {

        return createUserWithEmailAndPassword(auth, email, password)
         
    }

    function login (email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }
    function forgotPassword (email) {
        return sendPasswordResetEmail(auth, email)
    }

    async function updateUserEmail(email, password) {
        const creds = await signInWithEmailAndPassword(auth, currentUser.email, password)
        console.log(creds)
        
        await updateEmail(creds.user, email)

        const newCreds = await signInWithEmailAndPassword(auth, email, password)
        console.log(newCreds)

        setCurrentUser(newCreds.user)
    }

    async function updateUserPassword(newPassword, oldPassword) {
        const creds = await signInWithEmailAndPassword(auth, currentUser.email, oldPassword)
        await updatePassword(creds.user, newPassword)
        setCurrentUser(creds.user)
    }

    function logout() { 
        return signOut(auth)
    }

    function mutateUserProfile(profile) {
        setUserProfile(profile)
    }


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    const value={
        currentUser,
        userProfile,
        mutateUserProfile,
        signup,
        login, 
        logout,
        updateUserEmail,
        updateUserPassword,
        forgotPassword
    }
  return (
    <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
  )
}

export function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }
